import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const useQueryWithPollingWhileVisible: typeof useQuery = (
  query,
  options
) => {
  const useQueryResult = useQuery(query, options);
  const { startPolling, stopPolling } = useQueryResult;

  const pollInterval = options?.pollInterval;

  useEffect(() => {
    if (!pollInterval) {
      return;
    }
    const handler = () => {
      if (document.visibilityState === 'visible') {
        if (pollInterval) {
          startPolling(pollInterval);
        }
      } else {
        stopPolling();
      }
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, [pollInterval, startPolling, stopPolling]);

  return useQueryResult;
};
