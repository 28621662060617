import { FC } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { chartColors, chartGradientDataUri, QUERY_KEYS } from '../shared';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { CustomTooltip } from './CustomTooltip';

type ConsumerSecuredLoanSpreadsChartData = {
  'Consumer Secured Loan Spreads': {
    labels: string[];
    series: {
      [key: string]: number[];
    };
  }
}

type DataPoint =  {
  date: string;
  [key: string]: string | number;
}

type SeriesData = {
  name: string;
  data: number[];
  color: string;
}

type ConsumerSecuredLoanSpreadsChartProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
}

export const ConsumerSecuredLoanSpreadsChart: FC<ConsumerSecuredLoanSpreadsChartProps> = ({ dataUrl: ustDataUrl, width=620, height=382, className='' }) => {
  const { data, isLoading } = useReactQuery<ConsumerSecuredLoanSpreadsChartData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.UST_DATA, ustDataUrl],
    queryFn: async () => {
      if (!ustDataUrl) throw new Error('No URL provided');
      const response = await fetch(ustDataUrl);
      return response.json();
    },
    enabled: !!ustDataUrl,
    select: (data): DataPoint[] => {
      const series: SeriesData[] = Object.keys(data['Consumer Secured Loan Spreads'].series).map(
        (key, index) => ({
          name: key,
          data: data['Consumer Secured Loan Spreads'].series[key],
          color: chartColors[index % chartColors.length],
        })
      );

      return data['Consumer Secured Loan Spreads'].labels.map((label, index) => {
        const dataPoint: DataPoint = { date: label };
        series.forEach((seriesData) => {
          dataPoint[seriesData.name] = seriesData.data[index];
        });
        return dataPoint;
      });
    },
  });


  if (isLoading || !data) {
    return <div className='mt-16'>
      <ChartSkeletonLoader width={width} height={height} />
    </div>;
  }

  const seriesNames = Object.keys(data[0]).filter((key) => key !== 'date');

  const maxDataValue = Math.ceil(Math.max(...data.map(point =>
    Math.max(...seriesNames.map(seriesName => point[seriesName] as number))
  )));

  const minDataValue = Math.floor(Math.min(...data.map(point =>
    Math.min(...seriesNames.map(seriesName => point[seriesName] as number))
  )));

  const tickStep = Math.max(1, Math.ceil((maxDataValue - minDataValue) / 4));
  const yTicks = Array.from({ length: Math.ceil((maxDataValue - minDataValue) / tickStep) + 1 }, (_, i) => minDataValue + i * tickStep);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit ${className}`}>
      <h3 className='text-center text-slate-100 font-heebo text-lg'>Consumer Secured Loan Spreads</h3>
      <div className='relative'>
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url("${chartGradientDataUri}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <LineChart width={width} height={height} data={data} margin={{ bottom: 32 }} >
          <XAxis dataKey="date" stroke="#BBC5D7" tickMargin={8}>
            <Label
              value="Date"
              offset={-10}
              position="insideBottom"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </XAxis>
          <YAxis
            domain={[0, 6]}
            stroke="#BBC5D7"
            padding={{top: 24, bottom: 24}}
            tickMargin={8}
            ticks={yTicks}
          >
            <Label
              value="Value"
              angle={-90}
              position="insideLeft"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' />
          {seriesNames.map((seriesName, index) => (
            <Line
              key={seriesName}
              type="monotone"
              dataKey={seriesName}
              stroke={chartColors[index % chartColors.length]}
              dot={false}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
