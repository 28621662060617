import { gql } from '@apollo/client';
import { CarveDiffTableFragments } from '../CarveDiffTable';

const BidCardFragments = {
  TimelineCard: gql`
    fragment BidCard on TimelineCard {
      ... on BidCard {
        carve_criteria_section
        upb_criteria_section
        can_edit_counteroffer
        counteroffer_details_section
        bid {
          carve {
            field_name
            operator
            operand
            operandList
          }
          carve_summary {
            ...CarveSummary
          }
          stipulations {
            bid_basis_points
            is_servicing_retained
            servicing_rate
            has_diligence_completion_date_seconds
            diligence_completion_date_seconds
            has_settlement_date_seconds
            settlement_date_seconds
            has_percent_collateral_diligence
            percent_collateral_diligence
            has_min_net_yield_to_buyer
            min_net_yield_to_buyer
            custom_stipulations
          }
          terms {
            id
            name
            documentUrl
            documentSizeBytes
          }
          bid_basis_points
          seller_bid_basis_points
          price {
            rate
            price
            unpaid_balance
          }
        }
      }
    }
    ${CarveDiffTableFragments.PerformanceSummary}
  `,
};

export default BidCardFragments;
