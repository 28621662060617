import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Avatar from '../Avatar/Avatar';
import { SvgIcon } from '../Icons/SvgIcon/SvgIcon';
import './dropdown.scss';

type Icon = 'avatar' | 'svg';
type DropdownPosition = 'right' | 'left';

export interface GenericDropdownProps {
  label?: string;
  iconType?: Icon;
  iconName?: string;
  position: DropdownPosition;
  children: React.ReactNode;
  open?: boolean;
  className?: string;
}

export const GenericDropdown = ({
  label,
  position = 'left',
  iconType,
  iconName,
  children,
  open,
  className,
}: GenericDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!open);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonClasses = classnames('dropdown__button', {
    'dropdown__button--icon-only': !label,
  });
  // outside click handling
  useEffect(() => {
    if (isOpen) {
      const handler = (e: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(e.target as HTMLElement)) {
          // Only close the dropdown if the click happens outside of the content
          setIsOpen(false);
        }
      };

      window.addEventListener('click', handler);

      return () => {
        window.removeEventListener('click', handler);
      };
    }
  }, [isOpen]);

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const getIconContent = () => {
    if (iconType === 'svg' && iconName) {
      return (
        <span className={classnames('dropdown__icon', { 'dropdown__icon--circle': !label })}>
          <SvgIcon name={iconName} />
        </span>
      );
    }

    if (iconType === 'avatar' && label) {
      return (
        <span className="dropdown__avatar">
          <Avatar name={label} />
        </span>
      );
    }
  };

  return (
    <div className={`dropdown ${className}`}>
      <button className={buttonClasses} onClick={handleDropdownClick}>
        {getIconContent()}
        {label && <span className="dropdown__content">{label}</span>}
        {label && <SvgIcon name="chevron-down" />}
      </button>
      {isOpen && (
        <div
          ref={containerRef}
          className={classnames('dropdown__container', {
            'dropdown__container--left': position === 'left',
            'dropdown__container--right': position === 'right',
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
