import { FC } from 'react';
import { chartGradientDataUri } from '../shared';

type ChartSkeletonLoaderProps = {
  width?: number;
  height?: number;
};

export const ChartSkeletonLoader: FC<ChartSkeletonLoaderProps> = ({ width = 652, height = 470 }) => {
  const chartHeight = height - 120; // Adjust the height for the title and legend area

  return (
    <div
      className="bg-background-surface rounded-lg px-4 py-3 mx-auto relative overflow-hidden"
      style={{ width, height }}
    >
      {/* Gradient background */}
      <div
        className="absolute inset-0 opacity-65"
        style={{
          backgroundImage: `url("${chartGradientDataUri}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      {/* Animated shimmer effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-slate-700 to-transparent animate-shimmer"></div>

      {/* Chart content */}
      <div className="relative z-10">
        {/* Title */}
        <div className="h-6 bg-slate-700 rounded w-1/3 mx-auto mb-8"></div>

        {/* Chart area */}
        <div className="relative" style={{ height: chartHeight }}>
          {/* Y-axis */}
          <div className="absolute left-0 top-0 bottom-0 w-10 border-r border-slate-600"></div>

          {/* X-axis */}
          <div className="absolute left-10 right-0 bottom-0 h-10 border-t border-slate-600"></div>

          {/* Chart lines */}
          <div className="absolute left-12 right-2 top-2 bottom-12 flex items-end justify-between">
            {[...Array(7)].map((_, i) => (
              <div key={i} className="w-8 bg-slate-600 rounded-t" style={{ height: `${Math.random() * 80 + 20}%` }}></div>
            ))}
          </div>
        </div>

        {/* Legend */}
        <div className="flex justify-center space-x-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-4 bg-slate-700 rounded w-20"></div>
          ))}
        </div>
      </div>
    </div>
  );
};
