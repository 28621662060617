import { createContext, useContext } from 'react';
import { AssetClass } from '__generated__/globalTypes';

const DealRoomContext = createContext<DealRoomContextProps | undefined>(
  undefined
);

export interface DealRoomContextProps {
  dealId: string;
  assetClass: AssetClass;
  currentCard: string;
  setCurrentCard: (cardTitle: string) => void;
  isDocumentSelectedForView: boolean; // Track if a document IN DOCUMENTS PREVIW is selected
  setDocumentSelectedForView: (isSelected: boolean) => void;
}

export const useDealRoomContext = () => {
  const context = useContext(DealRoomContext);

  if (!context) {
    throw new Error(
      'This component must be used within a DealRoomContext.Provider'
    );
  }
  return context;
};

export default DealRoomContext;
