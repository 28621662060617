import { useState, useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { PngIcon, GenericDropdown, UserContextMenu } from 'common-ui';
import { UserModel } from 'features/pages/account/User';
import HeaderMenuItem from '../HeaderMenuItem/HeaderMenuItem';
import { SubHeader } from './SubHeader';

import './header.scss';
import { MAIN_HEADER_CONFIG } from './headers.config';
import ReferralLink from './ReferralLink';
import NotificationsDropdown from 'common-ui/Dropdown/Notifications/NotificationsDropdown';
import { useNotifications } from 'common-ui/hooks/useNotifications';

const Header = () => {
  const { state: authState } = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState<UserModel>();
  const [blinkBell, setBlinkBell] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (authState.user) {
      authState.user.getUserAttributes((err, data) => {
        if (err) {
          if (process.env.NODE_ENV === 'development') {
            console.error(err); // eslint-disable-line no-console
          }
        }
        if (data && isMounted) {
          const givenName = data
            .find((user) => user.getName() === 'given_name')
            ?.getValue();
          const familyName = data
            .find((user) => user.getName() === 'family_name')
            ?.getValue();
          const email = data
            .find((user) => user.getName() === 'email')
            ?.getValue();
          setUserInfo({
            givenName: givenName || '',
            familyName: familyName || '',
            email: email || '',
            phone: '',
            title: '',
            company: authState.company?.name || '',
            address: {
              address1: '',
              suite: '',
              city: '',
              state: '',
              zipcode: ''
            },
            roles: ''
          });
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [authState.user, authState.company]);

  const { notifications, loading } = useNotifications();
  const hasUnreadNotifications = notifications.filter(item => Number(item.notificationMetadata.readAt) <= 0).length > 0;

  useEffect(() => {
    if (hasUnreadNotifications) {
      setBlinkBell(true);
      const timer = setTimeout(() => setBlinkBell(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [hasUnreadNotifications]);

  return (
    <header className="header">
      <nav className="header__nav">
        <div className="header__logo">
          <PngIcon name="logo" />
        </div>
        <div className="header__menu_items">
          <ul className="header__menu_items_list">
            {MAIN_HEADER_CONFIG.map((headerMenuItem) => {
              return (
                <HeaderMenuItem
                  key={headerMenuItem.routeKey}
                  tabDetails={headerMenuItem}
                />
              );
            })}
          </ul>
        </div>
        <div className="header__actions">
          <div className="header__notifications relative">
            <GenericDropdown
              iconType="svg"
              iconName='bell'
              position="right"
            >
              <NotificationsDropdown notifications={notifications} loading={loading} className='w-[400px] max-h-[500px] bg-background-surface'/>
            </GenericDropdown>
            {hasUnreadNotifications && (
              <span className={`absolute top-[10px] right-[10px] block h-2 w-2 rounded-full bg-blue-500 transition-opacity duration-300 ${blinkBell ? 'animate-ping' : ''}`}></span>
            )}
          </div>
          <div className="header__profile">
            <GenericDropdown
              label={userInfo?.givenName ?? 'User'}
              iconType="avatar"
              position="right"
            >
              <UserContextMenu userInfo={userInfo} />
            </GenericDropdown>
          </div>
        </div>
        <Routes>
          <Route path=":mainSegment/*" element={<SubHeader />} />
        </Routes>
        <div style={{ position: 'absolute', right: 20, top: 71 }}>
          <ReferralLink />
        </div>
      </nav>
    </header>
  );
};

export default Header;
