/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import axios from 'axios';

const LOG_FLUSH_INTERVAL = 5000; // Interval in milliseconds to flush logs

const logBuffer: Set<string> = new Set();
let logFlushTimeout: NodeJS.Timeout | null = null;

let apiUrl = '';

export const initializeLogger = (url: string) => {
  apiUrl = url;
  interceptConsoleLogs();
  setupClickstreamLogging();
  logPerformanceStats();
};

export const sendLogs = async (logs: string[] | string, token: string) => {
  if (!Array.isArray(logs)) {
    logs = [logs];
  }
  if (!apiUrl || !token) {
    return;
  }

  logs.forEach(log => logBuffer.add(log));

  if (!logFlushTimeout) {
    logFlushTimeout = setTimeout(async () => {
      const logsToSend = Array.from(logBuffer);
      logBuffer.clear();
      logFlushTimeout = null;

      if (logsToSend.length > 0) {
        await axios.post(
          apiUrl,
          { logs: logsToSend },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }
    }, LOG_FLUSH_INTERVAL);
  }
};

const interceptConsoleLogs = () => {
  // Intercept console logs and errors
  const originalLog = console.log;
  const originalError = console.error;

  const getToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      originalError('Failed to security token', error);
      return '';
    }
  };

  console.log = async (...args) => {
    if (process.env.NODE_ENV === 'development') {
      originalLog(...args);
    } else {
      const token = await getToken();
      args = args.map((arg) => {
        try {
          return JSON.stringify(arg);
        } catch (_error: unknown) {
          return arg;
        }
      });
      sendLogs(args, token);
    }
  };

  console.error = async (...args) => {
    if (process.env.NODE_ENV === 'development') {
      originalError(...args);
    } else {
      const token = await getToken();
      // convert the args to string or array of strings json (deeply nested)
      // to avoid circular reference error
      args = args.map((arg) => {
        try {
          return JSON.stringify(arg);
        } catch (_error: unknown) {
          return arg;
        }
      });

      sendLogs(args, token);
    }
  };
};

// TODO: make a EMF logger - below is nonsense
const logEmfMetric = async (logName: string | undefined, properties: Record<string, any>, metrics?: Record<string, number>) => {
  const emfLog: Record<string, any> = {};
  if (logName) {
    emfLog['LogType'] = logName;
  }

  // Set properties
  Object.entries(properties).forEach(([key, value]) => {
    emfLog[key] = value;
  });

  // Set metrics if provided
  if (metrics) {
    Object.entries(metrics).forEach(([key, value]) => {
      emfLog[key] = value;
    });
  }

  console.log(emfLog);
};

const setupClickstreamLogging = () => {
  document.addEventListener('click', (e: MouseEvent) => {
    if (e.target instanceof Element) {
      logEmfMetric('UserClick', {
        elementType: e.target.tagName || 'unknown',
        elementId: e.target.id || 'none',
        elementClass: e.target.className || 'none',
        x: e.clientX,
        y: e.clientY,
        page: window.location.pathname,
        timestamp: new Date().toISOString()
      }, { count: 1 });
    } else {
      logEmfMetric('UserClick', {
        elementType: 'unknown',
        e: JSON.stringify(e),
        timestamp: new Date().toISOString(),
        page: window.location.pathname,
      }, { count: 1 });
    }
  });
};

interface PerformanceStats {
  timeToFirstByte: number;
  domContentLoaded: number;
  pageLoadTime: number;
  firstPaint: number;
  firstContentfulPaint: number;
  firstMeaningfulPaint: number;
  largestContentfulPaint: number;
  navigationType: string;
}

const logPerformanceStats = () => {
  const logStats = () => {
    setTimeout(() => {
      const [navigationEntry] = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
      const performanceData: PerformanceStats = {
        timeToFirstByte: navigationEntry.responseStart - navigationEntry.requestStart,
        domContentLoaded: navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime,
        pageLoadTime: navigationEntry.loadEventEnd - navigationEntry.startTime,
        firstPaint: performance.getEntriesByName('first-paint')[0]?.startTime || 0,
        firstContentfulPaint: performance.getEntriesByName('first-contentful-paint')[0]?.startTime || 0,
        firstMeaningfulPaint: (performance.getEntriesByType('paint').find((entry) => entry.name === 'first-meaningful-paint') as PerformanceEntry)?.startTime || 0,
        largestContentfulPaint: (performance.getEntriesByType('largest-contentful-paint')[0] as PerformanceEntry)?.startTime || 0,
        navigationType: navigationEntry.type,
      };

      console.log(JSON.stringify(performanceData, null, 2));
      logPageView(window.location.pathname);
    }, 0); // Delay to ensure all metrics are collected
  };

  window.addEventListener('load', logStats);
  window.addEventListener('popstate', logStats);

  const originalPushState = history.pushState;
  history.pushState = function (...args) {
    originalPushState.apply(this, args);
    logStats();
  };

  const originalReplaceState = history.replaceState;
  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    logStats();
  };
};

const logPageView = (path: string) => {
  logEmfMetric('PageView', {
    path,
  }, { count: 1 });
};

export const logLatency = (operation: string, latencyMs: number) => {
  logEmfMetric('Latency', {
    operation,
  }, { latencyMs });
};
