import React from 'react';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumberInputWithUnits } from 'common-ui/inputs/Inputs-styled';
import ErrorMessage from '../../../common-ui/form/ErrorMessage';
import { useField, FieldHookConfig } from 'formik';

const useCustomField = (
  props: FieldHookConfig<string> & { fieldName: string }
) => {
  const [field, meta, helpers] = useField(props);
  const { name, fieldName } = props;

  const customMeta = {
    ...meta,
    error: meta.error?.replace(name, fieldName),
  };

  return [field, customMeta, helpers] as const;
};

interface InputComponentProps {
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  unit?: string;
  error?: boolean;
  className?: string;
}

interface CashFlowModelingInputProps {
  name: string;
  fieldName: string;
  label: string | React.ReactElement;
  unit?: string;
  CustomInputComponent?: React.ComponentType<InputComponentProps>;
  onBlur?: () => void;
}

const CashFlowModelingInput: React.FC<CashFlowModelingInputProps> = ({
  name,
  fieldName,
  label,
  unit = '%',
  CustomInputComponent,
  ...props
}) => {
  const [field, meta] = useCustomField({ name, fieldName, ...props });
  const isValid = !(meta.touched && meta.error);
  const InputComponent = CustomInputComponent || NumberInputWithUnits;

  return (
    <div className="relative">
      <div className="flex items-center justify-between mb-2 h-[27px]">
        <label className="text-left">{label}</label>
        <div>
          <FontAwesomeIcon
            icon={isValid ? faCircleCheck : faCircleXmark}
            color={isValid ? '#9BDB72' : '#EB5757'}
            className="ml-2"
          />
        </div>
      </div>
      <InputComponent
        {...field}
        unit={unit}
        error={!isValid}
        onBlur={props.onBlur}
        className="w-36 h-7 rounded-sm text-sm"
      />
      {!isValid && (
        <ErrorMessage className="mt-1 text-red-500">{meta.error}</ErrorMessage>
      )}
    </div>
  );
};

export default CashFlowModelingInput;

