import { DropdownPillComboboxMultiValue } from 'common-ui';
import { useMemo } from 'react';
import {
  BaseFiltersConfig,
  MultiSelectFilter,
  MultiSelectFilterConfigEntry,
  Option,
} from '../filters.types';
import { keyBy } from 'lodash';
import type { CommonProps } from '../PillFilters';

export function MultiSelectPillFilter<T extends BaseFiltersConfig>({
  filter,
  filterConfig,
  updateFilter,
  removeFilter,
  index,
}: CommonProps<T> & {
  filter: MultiSelectFilter<T>;
  filterConfig: MultiSelectFilterConfigEntry<Option>;
}) {
  const { displayName, ...comboProps } = filterConfig;
  const getOptionValue = comboProps.getOptionValue;

  const allFilterOptions = filterConfig.filterOptions;
  const selectedOptions = useMemo(() => {
    const filterValues = keyBy(filter.value);
    return allFilterOptions.filter(
      (option) => filterValues[getOptionValue(option)]
    );
  }, [getOptionValue, filter.value, allFilterOptions]);

  return (
    <DropdownPillComboboxMultiValue
      {...comboProps}
      options={allFilterOptions}
      description={displayName}
      value={selectedOptions || null}
      onValueChanged={(options) =>
        options
          ? updateFilter(
            filter.name,
            index,
            options.map((op) => comboProps.getOptionValue(op))
          )
          : null
      }
      iconName="pill-close"
      iconOnClick={() => removeFilter(filter.name, index)}
    />
  );
}
