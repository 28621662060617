// useLoans.ts
import { useQuery } from '@apollo/client';
import { GET_DEAL_LOANS_QUERY } from '../../gql/inquiryQueries';
import {
  GetDealLoans,
  GetDealLoansVariables,
} from '../../gql/__generated__/GetDealLoans';
import {
  AssetClass,
  SortDirection,
  SortableField,
  ParentType,
} from '__generated__/globalTypes';
import {
  PortfolioFilterConfig,
  useFilterConfig,
} from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { FilterValue, filtersToQueryFilters } from 'features/common-elements';
import useTableQueryParams from 'functions/useTableQueryParams';

export const useLoans = (dealId: string, assetClass: AssetClass) => {
  const { params: queryParams, updateParams } = useTableQueryParams<
    FilterValue<PortfolioFilterConfig>,
    SortableField
  >();

  const portfolioFiltersConfig = useFilterConfig(assetClass, { parentType: ParentType.DEAL, parentId: dealId })
  const queryFilters = portfolioFiltersConfig
    ? filtersToQueryFilters<PortfolioFilterConfig>(
      queryParams.filters,
      portfolioFiltersConfig
    )
    : [];

  const { loading, error, data } = useQuery<
    GetDealLoans,
    GetDealLoansVariables
  >(GET_DEAL_LOANS_QUERY, {
    variables: {
      id: dealId,
      sort: {
        sort_direction: queryParams.sortings[0]?.desc
          ? SortDirection.desc
          : SortDirection.asc,
        sort_field: queryParams.sortings[0]?.id,
      },
      pagination: {
        offset: (queryParams.pageNumber - 1) * queryParams.pageSize,
        page_size: queryParams.pageSize,
      },
      filters: queryFilters,
    },
    fetchPolicy: 'no-cache',
  });

  return { loading, error, data, queryParams, updateParams };
};
