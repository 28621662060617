import { FC } from 'react';

export const MarketplaceBlotterSkeleton: FC = () => {
  return (
    <div>
      <h2 className='font-heebo font-medium text-lg text-white mb-4'>Marketplace Blotter</h2>
      <div className="flex overflow-x-auto xl:overflow-x-visible">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="flex-1 text-white rounded border-r border-slate-500 p-3 last:border-r-0 flex-shrink-0 cursor-pointer bg-background-canvas hover:bg-slate-800 hover:scale-105 active:scale-100 transition-all duration-150"
          >
            <div className="text-purple-300 font-medium max-w-[130px] break-words bg-purple-300 brightness-50 h-4 w-32 mb-2 rounded animate-pulse"></div>
            <div className="bg-slate-700 h-4 w-16 mb-2 rounded animate-pulse"></div>
            <div className="font-bold flex justify-between max-w-48">
              <div className="bg-slate-700 h-4 w-14 mb-2 rounded animate-pulse"></div>
              <div className="bg-slate-700 h-4 w-14 mb-2 rounded animate-pulse"></div>
            </div>
            <div className="font-bold flex brightness-50 justify-between max-w-48">
              <div className="bg-green-500 h-4 w-12 mb-2 rounded animate-pulse"></div>
              <div className="bg-green-500 h-4 w-24 rounded animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
