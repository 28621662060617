import { gql } from '@apollo/client';
import { CounterpartyBasicInfoFragment } from 'features/deals/dealCards/CounterpartyCard';

const CounterpartyCardBodyFragments = {
  TimelineCard: gql`
    fragment CounterpartyCard on TimelineCard {
      ... on CounterpartyCard {
        is_under_review
        counterparty {
          ...CounterpartyBasicInfo
          type
          regulatory_metrics {
            loans_shares_rank
            delinquency_rank
            charge_off_ratio_rank
            net_worth_ratio_rank
            expense_ratio_rank
            roa_rank
            roe_rank
            allowance_total_loans_rank
            efficiency_ratio_rank
            iea_total_assets_rank
          }
        }
      }
    }
    ${CounterpartyBasicInfoFragment.company}
  `,
};

export default CounterpartyCardBodyFragments;
