export enum FileTypeEnum {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  TIFF = 'image/tiff',
  ZIP = 'application/zip',
}

interface UploadConfig {
  [key: string]: {
    allowedFileTypes: FileTypeEnum[];
    message: string;
  };
}

export const uploadConfig: UploadConfig = {
  general: {
    allowedFileTypes: [],
    message: '',
  },
  dealDocuments: {
    allowedFileTypes: [FileTypeEnum.PDF, FileTypeEnum.JPEG, FileTypeEnum.TIFF],
    message: 'Only PDF, JPEG, and TIFF files are allowed for upload.',
  },
  collateralDocuments: {
    allowedFileTypes: [
      FileTypeEnum.PDF,
      FileTypeEnum.DOC,
      FileTypeEnum.DOCX,
      FileTypeEnum.XLS,
      FileTypeEnum.XLSX,
      FileTypeEnum.CSV,
      FileTypeEnum.JPEG,
      FileTypeEnum.TIFF,
    ],
    message: 'Only PDF, DOC, DOCX, Excel, CSV, JPEG, and TIFF files are allowed for upload.',
  },
  settlementDocuments: {
    allowedFileTypes: [FileTypeEnum.PDF],
    message: 'Only PDF files are allowed for upload.',
  },
  diligenceZip: {
    allowedFileTypes: [FileTypeEnum.ZIP],
    message: 'Only .zip files are allowed for upload.',
  },
};
