import { getStringAsKeyOf } from 'functions/typeUtils';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { useMatch, useSearchParams } from 'react-router-dom';
import { MainRouteKeys, PortfolioRouteKeys, PORTFOLIO_ROUTES_CONFIG, MarketplaceRouteKeys, mainRouteMatch, PAGE_PARAM, FILTERS_PARAM } from './navigation.config';
import { filterValuesToSearchQuery, searchQueryToFilterValues, FilterValue } from 'features/common-elements';
import { PortfolioFilterConfig, abbrToFilterField, filterFieldToAbbr } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { useCallback, useMemo } from 'react';

export function getMainRoute(mainRouteKey: MainRouteKeys) {
  return `${mainRouteKey}/*`;
}

export function getPortfolioRootRoute(portfolioRouteKey: PortfolioRouteKeys) {
  return `${portfolioRouteKey}`;
}

export function getPortfolioRoute(portfolioRouteKey: PortfolioRouteKeys) {
  // const configFor2ndLevel = PORTFOLIO_ROUTES_CONFIG[portfolioRouteKey];
  // if (configFor2ndLevel.usesAssetClass) {
  //   return `${portfolioRouteKey}/:${MAIN_ROUTES_CONFIG.portfolio.assetClassParam}?/*`;
  // }
  return `${portfolioRouteKey}/*`;
}

export function getMarketPlaceRoute(marketplaceRouteKey: MarketplaceRouteKeys) {
  return `${marketplaceRouteKey}/*`;
}

export function useCurrent2ndLevelRuoteConfig(mainRouteKey: MainRouteKeys) {
  const match = useMatch(mainRouteMatch[mainRouteKey]);
  const page = match?.params?.[PAGE_PARAM];
  if (!page) {
    return null;
  }
  const pageRouteKey = getStringAsKeyOf(PORTFOLIO_ROUTES_CONFIG, page.toLowerCase());
  if (!pageRouteKey) {
    return null;
  }
  return PORTFOLIO_ROUTES_CONFIG[pageRouteKey];
}

export function usePortfolioAssetClass() {
  const portfolioMatch = useMatch(mainRouteMatch.portfolio);
  const assetClass = portfolioMatch?.params['*']?.split('/')[0];
  return getStringAsKeyOf(ASSET_CLASS_IDS, assetClass?.toLowerCase());
}

export function usePortfolioFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilters = useCallback(
    (filters: FilterValue<PortfolioFilterConfig>[]) => {
      const query = filterValuesToSearchQuery(filters, filterFieldToAbbr);
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set(FILTERS_PARAM, query);
        return params;
      }, { preventScrollReset: true });
    },
    [setSearchParams]
  );

  const filtersStr = searchParams.get(FILTERS_PARAM);
  const currentFilters = useMemo(() =>
    filtersStr
      ? searchQueryToFilterValues(filtersStr, abbrToFilterField)
      : []
  , [filtersStr]);

  return [currentFilters, setFilters] as const;
}

export function useSortingHelper<Key extends string>(){
  const [searchParams, setSearchParams] = useSearchParams();
  const sortStr = searchParams.get('sort')?.split(',') || [];
  const sort = sortStr.map(str => {
    const [field, desc] = str.split('^');
    return {
      id: field as Key,
      desc: desc === 'true' ? true : false
    };
  });

  function setSort(sortArr: {id: Key, desc: boolean}[]){
    const str = sortArr.map(it => `${it.id}^${it.desc ? 'true': 'false'}`).join(',');
    const params = new URLSearchParams(searchParams);
    if (str) {
      params.set('sort', str);
    } else {
      params.delete('sort');
    }
    setSearchParams(params, { preventScrollReset: true });
  }

  return [sort, setSort] satisfies [{ id: Key, desc: boolean }[], (state: { id: Key, desc: boolean }[]) => void];
}

export function usePoolUrl() {
  const [searchParams, setSearchParams] = useSearchParams();

  const poolFromUrl = searchParams.get('pool');

  const setPool = useCallback(
    (pool: string | null) => {
      setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams);
        if (pool) {
          params.set('pool', pool);
        } else {
          params.delete('pool');
        }
        return params;
      }, { preventScrollReset: true });
    },
    [setSearchParams]
  );

  return {
    poolFromUrl,
    setPool,
  };
}
