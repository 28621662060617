import { useMutation } from '@apollo/client';
import { Button, KIND } from 'baseui/button';
import { CheckboxWithLabel } from 'common-ui';
import { CONFIRM_WIRE_TRANSFER } from 'mutation/confirmWireTransfer';
import { SENT_WIRE_TRANSFER } from 'mutation/sentWireTransfer';
import {
  ConfirmWireTransfer,
  ConfirmWireTransferVariables,
} from 'mutation/__generated__/ConfirmWireTransfer';
import {
  SentWireTransfer,
  SentWireTransferVariables,
} from 'mutation/__generated__/SentWireTransfer';
import { useState } from 'react';
import { GET_DEAL_TIMELINE } from '../../fragments';
import { WireConfirmationCard_WireConfirmationCard } from './__generated__/WireConfirmationCard';

type WireConfirmationCardBodyProps = {
  dealId: string;
} & WireConfirmationCard_WireConfirmationCard;

const WireConfirmationCardBody: React.FC<WireConfirmationCardBodyProps> = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [sendWireConfirmation] = useMutation<SentWireTransfer, SentWireTransferVariables>(SENT_WIRE_TRANSFER);
  const [confirmWireTransfer] = useMutation<ConfirmWireTransfer, ConfirmWireTransferVariables>(CONFIRM_WIRE_TRANSFER);


  const shouldShowCheckbox = props.is_wire_sent;
  const optionalCheckbox = (
    <CheckboxWithLabel
      checked={isSelected}
      onClick={() => setIsSelected((previous) => !previous)}
    >
      I have completed the transaction
    </CheckboxWithLabel>
  );

  const handleSubmit = () => {
    if (isSelected) {
      sendWireConfirmation({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: {
              id: props.dealId,
            },
          },
        ],
      });
    } else if (!shouldShowCheckbox) {
      confirmWireTransfer({
        variables: {
          input: {
            deal_id: props.dealId,
          },
        },
        refetchQueries: [
          {
            query: GET_DEAL_TIMELINE,
            variables: {
              id: props.dealId,
            },
          },
        ],
      });
    }
  };

  const wireDetails = props.wire_details;

  return (
    <div className="flex flex-wrap gap-6">
      <div className="flex-1">
        <ul className="list-none p-0 m-0">
          <li className="flex justify-between border-t border-slate-600 py-2 px-3">
            <span className="text-gray-800">Account Name</span>
            <span className="text-slate-100">{wireDetails.account_name}</span>
          </li>
          <li className="flex justify-between border-t border-slate-600 py-2 px-3">
            <span className="text-gray-800">Account Number</span>
            <span className="text-slate-100">{wireDetails.account_number}</span>
          </li>
        </ul>
      </div>
      <div className="flex-1">
        <ul className="list-none p-0 m-0">
          <li className="flex justify-between border-t border-slate-600 py-2 px-3">
            <span className="text-gray-800">Bank Name</span>
            <span className="text-slate-100">{wireDetails.bank_name}</span>
          </li>
          <li className="flex justify-between border-t border-slate-600 py-2 px-3">
            <span className="text-gray-800">Routing Number</span>
            <span className="text-slate-100">{wireDetails.routing_number}</span>
          </li>
        </ul>
      </div>
      {shouldShowCheckbox && (
        <div className="w-full mt-4">
          {optionalCheckbox}
        </div>
      )}
      {!props.is_wire_sent && (
        <div className="w-full mt-4">
          <Button
            kind={KIND.primary}
            disabled={!isSelected && shouldShowCheckbox}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  );
};

export default WireConfirmationCardBody;
