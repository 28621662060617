import { MayHaveLabel } from '@nivo/pie';
import { BaseButton, ButtonGroup, ModalNotStyled, SvgPillButton } from 'common-ui';
import { PoolSummaryPerformanceSummary } from 'features/loanPool/__generated__/PoolSummaryPerformanceSummary';
import { useState } from 'react';
import { AssetClass } from '__generated__/globalTypes';
import { stratificationsConfig, StratKey, StratRowType } from './startifications.config';
import { StratChart } from './StratChart';
import { StratTable } from './StratTable';
import { StratificationsPerformanceSummary } from './__generated__/StratificationsPerformanceSummary';

export type StratCardContentProps = {
  data: (StratRowType & MayHaveLabel)[];
  config: typeof stratificationsConfig[StratKey];
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
};

export function StratCard({
  strat,
  assetClass,
  summary,
  avgSummary,
  mode = 'card', // Default to 'card'
  displayType = 'chart', // Default to 'chart'
  onMinimizeClick,
}: {
  strat: StratKey;
  assetClass: AssetClass;
  summary: StratificationsPerformanceSummary;
  avgSummary: PoolSummaryPerformanceSummary;
  mode?: 'card' | 'modal';
  displayType?: 'chart' | 'table';
  onMinimizeClick?: () => void;
}) {
  const [isChart, setIsChart] = useState<boolean>(displayType === 'chart');
  const config = stratificationsConfig[strat];
  if (!config) return null;

  const contentProps: StratCardContentProps = {
    data: summary[strat],
    config,
    strat,
    assetClass,
    summary,
    avgSummary,
  };

  return (
    <div
      className={`border rounded-md p-4 bg-background-surface border-accent-disabled hover:border-accent-muted flex flex-col overflow-hidden ${
        mode === 'modal' ? 'w-[calc(100vw-40px)] max-h-[80vh]' : 'w-auto'
      } aspect-[16/9]`}
    >
      <div
        className={`flex items-center justify-between mb-4 ${
          mode === 'modal' ? 'pr-5' : ''
        }`}
      >
        <span className="font-semibold font-heebo text-xl text-gray-400">{config.label}</span>
        <div className="flex gap-4">
          <ButtonGroup type="secondary" size="small" label="select card type">
            <BaseButton
              type="primary"
              size="small"
              isSelected={isChart}
              onClick={() => setIsChart(true)}
              label="Chart"
            >
              Chart
            </BaseButton>
            <BaseButton
              isSelected={!isChart}
              onClick={() => setIsChart(false)}
              label="Table"
            >
              Table
            </BaseButton>
          </ButtonGroup>

          {mode !== 'modal' ? (
            <ModalNotStyled
              trigger={<SvgPillButton iconName="expand" label="expand" />}
            >
              {({ closeModal }) => (
                <StratCard
                  strat={strat}
                  assetClass={assetClass}
                  summary={summary}
                  avgSummary={avgSummary}
                  mode="modal"
                  displayType={isChart ? 'chart' : 'table'}
                  onMinimizeClick={closeModal}
                />
              )}
            </ModalNotStyled>
          ) : (
            <SvgPillButton
              iconName="minimize"
              label="minimize"
              onClick={onMinimizeClick}
            />
          )}
        </div>
      </div>
      <div className="flex-1 h-1">
        {isChart ? <StratChart {...contentProps} /> : <StratTable {...contentProps} />}
      </div>
    </div>
  );
}
