import React, { useEffect, useState, useMemo, useCallback, memo, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Diligence, DiligenceProps } from './Diligence';
import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import {
  CollateralStatus,
  FileType,
} from '__generated__/globalTypes';
import { FileDiligenceStatus } from './FileStatus';
import { SelectLoansProps } from './SelectLoansTab';
import { LoanDiligenceStatus } from './LoanDiligenceStatus';
import { ReviewDiligenceProps } from './ReviewDiligenceTab';
import {
  UPDATE_COLLATERAL_STATUS,
  UPDATE_DILIGENCE_STATUS,
} from 'mutation/diligenceMutations';
import { useLoans } from './queries/useLoans';
import {
  UpdateDiligenceStatus,
  UpdateDiligenceStatusVariables,
} from 'mutation/__generated__/UpdateDiligenceStatus';
import {
  UpdateCollateralStatus,
  UpdateCollateralStatusVariables,
} from 'mutation/__generated__/UpdateCollateralStatus';
import DELETE_S3_FILE_MUTATION from 'mutation/deleteS3File';
import {
  DeleteS3File,
  DeleteS3FileVariables,
} from 'mutation/__generated__/DeleteS3File';
import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';
import { GetDealDiligence_deal_diligence as DiligenceInfo } from 'query/__generated__/GetDealDiligence';
import { useLoansSelectedForDiligence } from './queries/useLoansSelectedForDiligence';
import { useCollateralNotes } from './queries/useCollateralNotes';
import { KPI } from 'features/pages/portfolio/Dashboard/PoolManager/types';
import { generateMetrics } from 'features/pages/portfolio/PortfolioSummary';
import { generateRowId } from 'features/drilldown/LoanDatatable/LoanTable';
import { LoanDiligenceProvider } from './contexts/LoanDiligenceProvider';
import { StyledBody } from 'baseui/card';
import { diff } from 'deep-diff';
import { AuthContext } from 'context/AuthContext';
import { GetUserPermissions, GetUserPermissionsVariables } from 'query/__generated__/GetUserPermissions';
import { useQuery } from '@apollo/client';
import { GET_USER_PERMISSIONS } from 'query/permissionsQueries';

type DiligenceControllerProps = {
  dealId: string;
} & DiligenceCard_DiligenceCard;

export type LoanId = string;

// A RowId is of the form "accountId:loanId"
export type RowId = string;

const DiligenceController: React.FC<DiligenceControllerProps> = memo(({
  dealId,
  assetClass,
  role,
  is_complete,
  deal,
  original_pool_summary,
  accepted_bid_summary,
  post_diligence_summary,
  diligence_reports,
}) => {
  const [rawUserEmail, setRawUserEmail] = useState('');

  const { state: authState } = useContext(AuthContext);
  const user = useMemo(() => authState.user, [authState.user]);

  const fetchUserAttributes = useCallback(() => {
    if (user) {
      user.getUserAttributes((err, data) => {
        if (err) {
          console.error(err);
          return;
        }

        if (data) {
          const userAttributes = data.reduce((acc, attribute) => {
            acc[attribute.Name] = attribute.Value;
            return acc;
          }, {} as Record<string, string>);

          setRawUserEmail(userAttributes.email || '');
        }
      });
    }
  }, [user]);

  useEffect(() => {
    fetchUserAttributes();
  }, [fetchUserAttributes]);

  const userEmail = useMemo(() => rawUserEmail, [rawUserEmail]);

  const { data: userData } = useQuery<GetUserPermissions, GetUserPermissionsVariables>(
    GET_USER_PERMISSIONS, {
      variables: { requestData: { userEmail } },
      skip: !userEmail,
    }
  );

  const userId = useMemo(() => userData?.userManagementGetUser?.user?.userID || '', [userData]);

  const {
    error: loansError,
    data: loansData,
    queryParams: loansQueryParams,
    updateParams: updateLoansParams,
  } = useLoans(dealId, assetClass);

  const loans = useMemo(() => loansData?.deal?.loans || [], [loansData]);
  const totalItems = loansData?.deal?.performance_summary.loanCount;

  const loansPopulationMetrics: KPI[] = generateMetrics(
    loansData?.deal?.performance_summary
  );

  const [selectedLoansSnapshot, setSelectedLoansSnapshot] = useState<RowId[]>(
    []
  );

  const selectLoansMetrics = useMetricsSelection(loansPopulationMetrics, []);

  const selectLoansProps: SelectLoansProps = useMemo(() => ({
    dealId,
    loans,
    loansError,
    totalLoans: totalItems,
    assetClass,
    selectLoansMetrics,
    queryParams: loansQueryParams,
    updateQueryParams: updateLoansParams,
    selectedLoansSnapshot,
    is_complete,
    userId,
  }), [
    dealId,
    loans,
    loansError,
    totalItems,
    assetClass,
    selectLoansMetrics,
    loansQueryParams,
    updateLoansParams,
    selectedLoansSnapshot,
    is_complete,
    userId,
  ]);

  // TODO: Fix re-rendering issue with useLoansSelectedForDiligence. Component is re-rendered to much time
  const {
    data: diligenceLoansData,
    refetch: refetchDiligence,
    queryParams: diligenceQueryParams,
    updateParams: updateDiligenceQueryParams,
  } = useLoansSelectedForDiligence(dealId, assetClass);

  const onJobCompleted = () => {
    console.log('Job completed -- refetching diligence data');
    refetchDiligence();
  };

  // When the diligence data changes, update the selected loans snapshot
  useEffect(() => {
    const rowIds =
      diligenceLoansData?.deal?.diligence?.map((diligence) =>
        generateRowId(diligence.loan.account_id, diligence.loan.id)
      ) || [];

    if (diff(rowIds, selectedLoansSnapshot)) {
      setSelectedLoansSnapshot(rowIds);
    }
  }, [diligenceLoansData, selectedLoansSnapshot]);

  const diligencePopulationMetrics: KPI[] = generateMetrics(
    diligenceLoansData?.deal?.diligence_performance_summary
  );

  const selectReviewMetrics = useMetricsSelection(
    diligencePopulationMetrics,
    []
  );

  const [updateDiligenceStatus] = useMutation<
    UpdateDiligenceStatus,
    UpdateDiligenceStatusVariables
  >(UPDATE_DILIGENCE_STATUS);

  const [updateCollateralStatus] = useMutation<
    UpdateCollateralStatus,
    UpdateCollateralStatusVariables
  >(UPDATE_COLLATERAL_STATUS);

  const [deleteS3File] = useMutation<DeleteS3File, DeleteS3FileVariables>(
    DELETE_S3_FILE_MUTATION
  );

  const { collateralNotesMap, fetchCollateralNotes } = useCollateralNotes();

  // TODO add upload progress to the UI
  const [uploadProgress, _setUploadProgress] = useState<number>();

  const loanDiligenceIdForLoanId = useCallback((loanId: string): string => {
    const loanDiligence = diligenceLoansData?.deal?.diligence.find(
      (diligence: DiligenceInfo) => diligence.loan.id === loanId
    );
    return loanDiligence?.id || '';
  }, [diligenceLoansData]);

  const getFileNameAndDiligenceId = useCallback((
    docId: string
  ): { fileName: string | undefined; diligenceId: string | undefined } => {
    let fileName: string | undefined;
    let diligenceId: string | undefined;

    for (const diligence of diligenceLoansData?.deal?.diligence || []) {
      const collateral = diligence.collateral.find(
        (collateral: Collateral) => collateral.collateralID === docId
      );
      if (collateral) {
        fileName = collateral.fileName;
        diligenceId = diligence.id;
        break;
      }
    }

    return { fileName, diligenceId };
  }, [diligenceLoansData]);

  const reviewDiligenceProps: ReviewDiligenceProps = useMemo(() => ({
    dealId,
    dealName: deal.listing.provided_name || deal.listing.name,
    assetClass,
    role,
    is_complete,
    buyerAcceptedDiligence: deal.buyerAcceptedDiligence,
    sellerAcceptedDiligence: deal.sellerAcceptedDiligence,
    reviewDiligenceMetrics: selectReviewMetrics,
    queryParams: diligenceQueryParams,
    updateQueryParams: updateDiligenceQueryParams,
    totalDiligenceItems: diligenceLoansData?.deal?.diligence?.length || 0,
    diligenceData: diligenceLoansData?.deal?.diligence || [],
    handleLoanDiligenceStatusChanged: async (
      loanId: string,
      status: LoanDiligenceStatus
    ) => {
      const loanDiligenceId = loanDiligenceIdForLoanId(loanId);

      await updateDiligenceStatus({
        variables: {
          id: loanDiligenceId,
          diligenceStatus: status
        },
        optimisticResponse: {
          updateDiligenceStatus: {
            __typename: 'Diligence',
            id: loanDiligenceId,
            status
          },
        },
      });
    },
    documents: {
      collateralNotesMap,
      uploadProgress,
      onFileStatusSelect: async (
        dealId: string,
        loanId: string,
        fileName: string,
        status: FileDiligenceStatus
      ) => {
        await updateCollateralStatus({
          variables: {
            input: {
              dealID: dealId,
              loanID: loanId,
              fileName: fileName,
              newStatus: toCollateralStatus(status),
            }
          },
          optimisticResponse: {
            updateCollateralStatus: true,
          },
        });
      },
      onRemoveFile: (docId: string) => {
        const { fileName, diligenceId } = getFileNameAndDiligenceId(docId);
        if (!fileName || !diligenceId) {
          console.error(
            `No filename or diligence id found for document ID: ${docId}. Skipping deletion.`
          );
          return;
        }
        deleteS3File({
          variables: {
            parentId: diligenceId,
            fileType: FileType.COLLATERAL_FILE,
            fileName: fileName,
          },
        }).then(() => {
          refetchDiligence();
        });
      },
      onSuccessUpload: () => {
        refetchDiligence();
      },
    },
    fetchCollateralNotes,
    userId,
  }), [
    dealId,
    deal.listing.provided_name,
    deal.listing.name,
    assetClass,
    role,
    is_complete,
    deal.buyerAcceptedDiligence,
    deal.sellerAcceptedDiligence,
    selectReviewMetrics,
    diligenceQueryParams,
    updateDiligenceQueryParams,
    diligenceLoansData,
    updateDiligenceStatus,
    collateralNotesMap,
    uploadProgress,
    updateCollateralStatus,
    deleteS3File,
    refetchDiligence,
    fetchCollateralNotes,
    getFileNameAndDiligenceId,
    loanDiligenceIdForLoanId,
    userId,
  ]);

  const diligenceProps: DiligenceProps = useMemo(() => ({
    selectLoansProps,
    reviewDiligenceProps,
    role,
    originalPoolSummary: original_pool_summary,
    acceptedBidSummary: accepted_bid_summary,
    postDiligenceSummary: post_diligence_summary,
    diligenceReports: diligence_reports,
    dealId,
    is_complete,
    buyerAcceptedDiligence: deal.buyerAcceptedDiligence,
    sellerAcceptedDiligence: deal.sellerAcceptedDiligence,
    userId,
  }), [
    selectLoansProps,
    reviewDiligenceProps,
    role,
    original_pool_summary,
    accepted_bid_summary,
    post_diligence_summary,
    diligence_reports,
    dealId,
    is_complete,
    deal.buyerAcceptedDiligence,
    deal.sellerAcceptedDiligence,
    userId,
  ]);

  return (
    <StyledBody>
      <LoanDiligenceProvider dealId={dealId} onJobCompleted={onJobCompleted}>
        <Diligence {...diligenceProps} />
      </LoanDiligenceProvider>
    </StyledBody>
  );
});

// TODO(kentskinner): after RC1 just used the server types directly rather than mapping back and forth.
const toCollateralStatus = (status: FileDiligenceStatus): CollateralStatus => {
  switch (status) {
    case 'In Progress':
      return CollateralStatus.IN_PROGRESS;
    case 'Additional QA Required':
      return CollateralStatus.ADDITIONAL_QA_REQUIRED;
    case 'File Received':
      return CollateralStatus.RECEIVED;
    case 'File Required':
      return CollateralStatus.REQUIRED;
    case 'File Reviewed':
      return CollateralStatus.COMPLETED;
    case 'File Rejected':
      return CollateralStatus.REJECTED;
    default:
      throw new Error(`Invalid status: ${status}`);
  }
};

const useMetricsSelection = (allMetrics: KPI[], initialMetrics: string[]) => {
  const [selectedMetrics, setSelectedMetrics] =
    useState<string[]>(initialMetrics);

  const onAddMetric = (selectedMetric: string) => {
    setSelectedMetrics((metrics) => {
      if (!metrics.includes(selectedMetric)) {
        return [...metrics, selectedMetric];
      } else {
        return metrics;
      }
    });
  };

  const onRemoveMetric = (selectedMetric: string) => {
    setSelectedMetrics((metrics) =>
      metrics.filter((metric) => metric !== selectedMetric)
    );
  };

  return { allMetrics, selectedMetrics, onAddMetric, onRemoveMetric };
};
export default DiligenceController;
