import DocumentPreview from './DocumentPreview';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { DateTime } from 'luxon';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { Action } from './ActionButton';
import { Note } from './DealDocumentTypes';
import { useDialog } from 'common-ui/Dialog';
import DocumentsTable from './DocumentsTable';
import ConfirmDialog from '../../../../common-ui/ConfirmDialog';
import { FileDiligenceStatus } from './FileStatus';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import { DealRole, FileType } from '__generated__/globalTypes';
import CollateralNotesDialog from './CollateralNotesDialog';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { GET_COLLATERAL_FILE_DOWNLOAD_URL } from 'query/collateral';
import { useLazyQuery } from '@apollo/client';
import { uploadConfig } from 'app-level/config/uploadConfig';
import { DocumentPreviewSkeleton } from './DocumentPreviewSkeleton';
export interface DiligenceSelectorProps {
  selectedLoanId: string;
  onSelectLoan?: (loanId: string) => void;
  diligence: GetDealDiligence_deal_diligence[];
  fetchCollateralNotes: (collateralIds: string[]) => void;
}

export interface DocumentsProps {
  collateralNotesMap: Record<string, Note[]>;
  onFileStatusSelect: (dealId: string, loanId: string, fileName: string, status: FileDiligenceStatus) => void;
  onRemoveFile: (docId: string) => void;
  uploadProgress?: number;
  onSuccessUpload: () => void;
}

export interface DiligenceLoanDocumentDrawerProps {
  dealName: string;
  role: DealRole;
  diligenceSelector: DiligenceSelectorProps;
  documents: DocumentsProps;
  userId: string;
  dealId: string
}

const DiligenceLoanDocumentDrawer = ({
  dealName,
  role,
  diligenceSelector: {
    selectedLoanId,
    onSelectLoan,
    diligence,
    fetchCollateralNotes,
  },
  documents: {
    collateralNotesMap,
    uploadProgress,
    onFileStatusSelect,
    onRemoveFile,
    onSuccessUpload,
  },
  userId,
  dealId,
}: DiligenceLoanDocumentDrawerProps) => {
  const [selectedDocId, setSelectedDocId] = useState<string>();
  const [docUrl, setDocUrl] = useState<string | undefined>();
  const { setDocumentSelectedForView } = useDealRoomContext();

  const [fetchDocumentUrl] = useLazyQuery(GET_COLLATERAL_FILE_DOWNLOAD_URL, {
    onCompleted: (data) => {
      setDocUrl(data.getCollateralFileDownloadURL.URL);
    },
    onError: (error) => {
      console.error('Error fetching document URL:', error);
    },
  });

  const selectedDiligence = useMemo(
    () => diligence.find(({ loan: { id } }) => id === selectedLoanId),
    [selectedLoanId, diligence]
  );

  const { upload } = useS3Uploader({
    onSuccess: (_file, versionId) => {
      console.log(`File uploaded successfully. Version ID: ${versionId}`);
      onSuccessUpload();
    },
    onProgress: (progress) => {
      console.log(`File upload progress: ${progress}%`);
    },
    onError: (error: unknown) => {
      console.error('Error uploading file:', error);
    },
    fileType: FileType.COLLATERAL_FILE,
    parentId: dealId,
    isCollateral: true,
    loanId: selectedDiligence?.loan?.id,
    uploadedBy: userId,
  });

  const handleDocumentSelection = useCallback(() => {
    if (selectedDocId) {
      setDocumentSelectedForView(true); // Set to true when a document IN DOCUMENTS PREVIW  is selected
    } else {
      setDocumentSelectedForView(false); // Set to false if no document IN DOCUMENTS PREVIW  is selected
    }

    return () => {
      setDocumentSelectedForView(false); // Reset to false when component is unmounted
    };
  }, [selectedDocId, setDocumentSelectedForView]);

  // Call the handleDocumentSelection callback when `selectedDocId` changes
  useEffect(() => {
    handleDocumentSelection();
  }, [handleDocumentSelection]);

  useEffect(() => {
    if (selectedDiligence) {
      fetchCollateralNotes(selectedDiligence.collateral.map((c) => c?.collateralID));
    }
  }, [selectedLoanId, fetchCollateralNotes, selectedDiligence]);

  // Automatically select the first document when the list of documents changes.
  useEffect(() => {
    if (!selectedDiligence?.collateral.some(doc => doc.collateralID === selectedDocId && doc.loanID)) {
      setSelectedDocId(
        selectedDiligence?.collateral?.find(doc => doc.loanID)?.collateralID || undefined
      );
    }
  }, [selectedLoanId, selectedDocId, selectedDiligence]);

  const [currentNotesDocumentId, setCurrentNotesDocumentId] =
    useState<string>();

  const currentNotesDocument = selectedDiligence?.collateral.find(
    (doc) => doc.collateralID === currentNotesDocumentId
  );

  const noteDialog = useDialog();

  const handleNoteClick = (docId: string) => {
    setCurrentNotesDocumentId(docId);
    noteDialog.openDialog();
  };

  const confirmRemoveFileDialog = useDialog();

  const handleClickRemove = () => {
    confirmRemoveFileDialog.openDialog();
  };

  const selectedDoc = selectedDiligence?.collateral.find(
    (doc) => doc.collateralID === selectedDocId
  );

  // Refetch url after 14min if it is not used
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const fetchUrl = async () => {
      if (selectedDocId && selectedDiligence) {
        const selectedDoc = selectedDiligence.collateral.find(
          (doc) => doc.collateralID === selectedDocId
        );

        if (selectedDoc) {
          try {
            await fetchDocumentUrl({
              variables: {
                input: {
                  dealID: selectedDoc.dealID,
                  loanID: selectedDoc.loanID,
                  fileName: selectedDoc.fileName,
                },
              },
            });
          } catch (error) {
            console.error('Error fetching document URL:', error);
          }
        }
      } else {
        setDocUrl(undefined);
      }
    };

    fetchUrl();

    timer = setInterval(fetchUrl, 14 * 60 * 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [selectedDocId, selectedDiligence, fetchDocumentUrl]);

  const documentPreview = useMemo(() => {
    if (selectedDiligence?.collateral?.length) {
      return docUrl ? <DocumentPreview documentUrl={docUrl} /> : <DocumentPreviewSkeleton />;
    }

    return null;
  }, [docUrl, selectedDiligence?.collateral]);

  const hasNote = (collateralId: string) => {
    return notesForCollateralId(collateralId).length > 0;
  };

  const notesForCollateralId = (collateralId?: string) => {
    if (!collateralId) return [];
    if (!collateralNotesMap) return [];
    return collateralNotesMap[collateralId] || [];
  };

  const onFileDrop = async (file: File) => {
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleLoanSelection = (loanId: string) => {
    const selectedLoan = diligence.find(({ loan: { id } }) => id === loanId);

    if (onSelectLoan) {
      onSelectLoan(loanId);
    }

    if (!selectedLoan?.collateral.length) {
      setSelectedDocId(undefined);
      setDocUrl(undefined);
    }
  };

  return (
    <>
      <div className="bg-slate-900 p-4 text-white flex flex-col gap-2 h-full">
        <div className='flex flex-nowrap gap-x-3 mt-4'>
          <div className="w-64 border border-gray-900 rounded-md p-4">
            <div className="flex justify-between mb-4">
              <span className="text-gray-50 font-medium">{dealName}</span>
              <span className="text-gray-200 font-bold">{diligence.length}</span>
            </div>
            <div>
              {diligence.map(
                ({ loan: { id: loanId, account_id: accountId } }) => (
                  <div
                    key={loanId}
                    aria-selected={loanId === selectedLoanId ? 'true' : 'false'}
                    className={`p-2 cursor-pointer ${
                      loanId === selectedLoanId ? 'bg-purple-500 text-white' : 'bg-transparent'
                    }`}
                    onClick={() => handleLoanSelection(loanId)}
                  >
                    {accountId}
                  </div>
                )
              )}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            {role === DealRole.SELLER && (
              <>
                <FileDropZone
                  onFileDrop={onFileDrop}
                  allowedFileTypes={uploadConfig.collateralDocuments.allowedFileTypes}
                  allowedTypesMessage={uploadConfig.collateralDocuments.message}
                />
                {uploadProgress && (
                  <div>Upload Progress: {uploadProgress}%</div>
                )}
              </>
            )}
            <DocumentsTable
              role={role}
              // check if document is ZIP, we do not show .ZIP in list
              collateral={selectedDiligence?.collateral.filter(doc => !!doc.loanID)}
              selectedDocId={selectedDocId}
              onDocSelected={setSelectedDocId}
              handleNoteClick={handleNoteClick}
              onFileStatusSelect={onFileStatusSelect}
              hasNote={hasNote}
            />
            <div className="flex justify-end gap-5 items-center">
              <div className="flex items-center gap-5">
                {role === DealRole.SELLER && (
                  <Action
                    label="Remove"
                    icon="close-round"
                    onClick={handleClickRemove}
                    disabled={!selectedDocId}
                  />
                )}
                <Action
                  as="a"
                  download
                  href={docUrl}
                  target="_blank"
                  label="Download"
                  icon="download-icon"
                  disabled={!selectedDocId}
                />
                <Action
                  label="Add Note"
                  icon="note-icon"
                  onClick={() => handleNoteClick(selectedDocId || '')}
                  disabled={!selectedDocId}
                />
                <Action
                  label="Mark Complete"
                  icon="complete-icon"
                  disabled={!selectedDocId}
                />
              </div>
            </div>
          </div>
        </div>
        {documentPreview}
      </div>
      { currentNotesDocumentId &&
        <CollateralNotesDialog
          collateralId={currentNotesDocumentId}
          dialog={noteDialog}
          documentName={currentNotesDocument?.fileName || ''}
        />
      }

      <ConfirmDialog
        dialog={confirmRemoveFileDialog}
        message={'Remove file "' + selectedDoc?.fileName + '"?'}
        confirmButtonText="Remove"
        onConfirm={() =>
          onRemoveFile && selectedDocId && onRemoveFile(selectedDocId)
        }
      />
    </>
  );
};

export default DiligenceLoanDocumentDrawer;

export const formatDate = (date: string): string => {
  if (!date) return '-';
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat('yyyy-MM-dd h:mma');
};
