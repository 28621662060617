import { useQuery } from '@apollo/client';
import Dinero from 'dinero.js';
import { isUnsecEnabled } from 'features/flags';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { GET_PORTFOLIO_OVERVIEW_QUERY } from './PortfolioOverview.fragments';
import { GetPortfolioOverview } from './__generated__/GetPortfolioOverview';
import { KPISkeleton } from './KPISkeleton';

const KPIComponent: FC<{ value?: number, label: string }> = ({ label, value }) => {
  return (
    <div className='bg-kip-gradient px-2 flex flex-col gap-1 py-2 rounded text-center min-w-36'>
      <p className='font-heebo font-bold text-sm text-slate-50'>
        {Dinero({ amount: value ?? 0, currency: 'USD' }).toFormat()}
      </p>
      <span className='font-heebo text-sm font-black'>{label}</span>
    </div>
  );
};

export const PortfolioOverview: FC = () => {
  const { data, loading } = useQuery<GetPortfolioOverview>(GET_PORTFOLIO_OVERVIEW_QUERY);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-between items-center'>
        <h2 className='font-heebo font-medium text-lg text-white'>My Portfolio At A Glance</h2>
        <Link
          className='w-fit h-fit rounded border border-pink-500 text-pink-500 px-1.5 hover:scale-105 active:scale-100'
          to='/portfolio/tape-upload'
        >
          Upload Tape
        </Link>
      </div>
      <div className='flex flex-row justify-between gap-4 overflow-x-auto'>
        {loading ? <KPISkeleton /> : <KPIComponent label='ALL' value={data?.user.company.totalPerformance.unpaidBalance} />}
        {loading ? <KPISkeleton /> : <KPIComponent label='AUTO' value={data?.user.company.autoPerformance.unpaidBalance} />}
        {loading ? <KPISkeleton /> : <KPIComponent label='HOME' value={data?.user.company.homePerformance.unpaidBalance} />}
        {loading && isUnsecEnabled() ? <KPISkeleton /> : isUnsecEnabled() && <KPIComponent label='UNSEC PERSONAL' value={data?.user.company.unsecPersonalPerformance.unpaidBalance} />}
      </div>
    </div>
  );
};
