import { useEffect, useState } from 'react';
import { PortfolioLayout } from './Portfolio/PortfolioLayout';
import {
  BaseButton,
  DataDisplayTable,
  DataDisplayTableProps,
  ModalNotStyled,
} from 'common-ui';
import {
  GetListings,
  GetListings_user_company_listings as Listing,
} from 'query/__generated__/GetListings';
import { GET_LISTINGS } from 'query/getListings';
import { gql, useMutation, useQuery } from '@apollo/client';
import { RowSelectionState } from '@tanstack/table-core';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import {
  CreatePublicOffering,
  CreatePublicOfferingVariables,
} from 'mutation/__generated__/CreatePublicOffering';
import { CREATE_PUBLIC_OFFERING } from 'mutation/createPublicOffering';
import CreateOfferingForm, { FormValues } from './CreateOfferingForm';
import { styled } from 'style/ORSNNTheme';
import { Button } from 'baseui/button';
import { Dialog, useDialog } from 'common-ui/Dialog';
import {
  StyledHeading,
  DialogBodyText,
  DialogActions,
} from 'common-ui/Dialog.styles';
import offeringCreatedImgPath from 'assets/pngs/carve-confirmation.png';
import { ListingState } from '__generated__/globalTypes';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { transformSelectedPoolsData, usePortfolioContext } from './PortfolioContext';
import { useUnmountEffect } from '@react-hookz/web';
import { DocumentTemplatesFragments } from './DocumentLibrary/DocumentTemplates.fragments';

interface PoolTableProps {
  data: Listing[];
  selection?: DataDisplayTableProps<Listing, keyof Listing>['selection'];
}

const GET_TEMPLATE_DOCUMENTS = gql`
  query GetTemplateDocuments {
    user {
      id
      company {
        id
        templates {
          id
          ...TemplateDocument
        }
      }
    }
  }
  ${DocumentTemplatesFragments.document}
`;

const MarketStatusBase = styled.div`
  display: inline-block;
  background-color: #6fcf97;
  width: 152px;
  text-align: center;
  color: #031b0d;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 16px;

  font-family: 'PT Sans Narrow';
  font-size: 14px;
  font-weight: 700;
`;

const InMarketStatus = styled(MarketStatusBase)`
  background-color: #6fcf97;
  color: #031b0d;
`;

const OffMarketStatus = styled(MarketStatusBase)`
  background-color: #32363e;
  color: #fff;
`;

const PoolTable = ({ data, selection }: PoolTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const { formatNumber } = useIntl();

  const formatCentsToDollars = (cents: number): string => {
    if (isNaN(cents) || cents == null) return '-';
    return formatNumber(cents / 100, { style: 'currency', currency: 'USD' });
  };

  const toPercentage = (num: number): string => {
    if (isNaN(num) || num == null) return '-';
    return formatNumber(num, { style: 'percent', minimumFractionDigits: 2 });
  };

  const formatDateTime = (date: string): string => {
    if (!date) return '-';
    const luxonDate = DateTime.fromISO(date);
    return luxonDate.toFormat("MM/dd/yy ' @ ' h:mma ZZZZ");
  };

  const getCellValue = (
    listing: Listing,
    activeValue: string | React.ReactNode,
    inactiveValue = ''
  ) => {
    return listing.state !== ListingState.ACTIVE ? inactiveValue : activeValue;
  };

  const handleSortingChange = (newSorting: SortingState) => {
    setSorting(newSorting);
  };

  const columns: ColumnDef<Listing, keyof Listing>[] = [
    {
      accessorKey: 'is_public',
      header: 'Pool Status',
      cell: (props) => {
        return props.getValue() ? (
          <InMarketStatus>In Market</InMarketStatus>
        ) : (
          <OffMarketStatus>Off Market</OffMarketStatus>
        );
      },
    },
    {
      accessorKey: 'provided_name',
      header: 'Loan Pool Name',
    },
    {
      accessorKey: 'created_date',
      header: 'Date Created',
      cell: (props) => <span>{formatDateTime(props.getValue())}</span>,
    },
    {
      accessorKey: 'asset_class',
      header: 'Asset Class',
    },
    {
      accessorKey: 't_current_balance_cents',
      header: 'UPB',
      cell: (props) => {
        const listing = props.row.original;
        return (
          <span>
            {getCellValue(
              listing,
              formatCentsToDollars(Number(props.getValue())),
              'Calculating...'
            )}
          </span>
        );
      },
      meta: {
        numeric: true,
        alignment: 'left',
      },
    },
    {
      accessorKey: 'loan_count',
      header: '# Loans',
      cell: (props) => {
        const listing = props.row.original;
        return <span>{getCellValue(listing, props.getValue())}</span>;
      },
      meta: {
        numeric: true,
        alignment: 'left',
      },
    },
    {
      accessorKey: 'wa_coupon',
      cell: (props) => {
        const listing = props.row.original;
        return (
          <span>
            {getCellValue(listing, toPercentage(Number(props.getValue())))}
          </span>
        );
      },
      header: 'GWAC',
      meta: {
        numeric: true,
        alignment: 'left',
      },
    },
  ];

  return (
    <DataDisplayTable
    // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
      data={data}
      // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
      columns={columns}
      selection={selection}
      noDataMessage="Please go to Loan Level Data to create a pool"
      // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
      sorting={{ state: sorting, onSortingChanged: handleSortingChange }}
    />
  );
};

const PoolManager = () => {
  const { setSelectedPoolsData } = usePortfolioContext();
  const { data: docData, loading } = useQuery(GET_TEMPLATE_DOCUMENTS);
  const documents = docData?.user.company.templates;

  const navigate = useNavigate();
  const navigateToDocumentLibrary = () => {
    navigate('/portfolio/document-library');
  };

  const { data, error: queryError } = useQuery<GetListings>(GET_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });
  const [createPublicOfferingMutation] = useMutation<
    CreatePublicOffering,
    CreatePublicOfferingVariables
  >(CREATE_PUBLIC_OFFERING);

  const [selectedPools, setSelectedPools] = useState<RowSelectionState>({});

  const offeringCreatedDialog = useDialog();

  useEffect(() => {
    if (selectedPools) {
      const listings = data?.user.company.listings;

      const selectedListings =
        listings?.filter((_, index) => selectedPools[index]) || [];

      setSelectedPoolsData(transformSelectedPoolsData(selectedListings));
    }
  }, [data?.user.company.listings, selectedPools, setSelectedPoolsData]);

  useUnmountEffect(() => {
    setSelectedPoolsData([]);
  });

  const createOffering = async (formValues: FormValues) => {
    const listings = data?.user.company.listings;

    const selectedListings =
      listings?.filter((_, index) => selectedPools[index]) || [];

    try {
      for (const listing of selectedListings) {
        await createPublicOfferingMutation({
          variables: {
            ...formValues,
            listingId: listing.id,
          },
        });
      }
    } catch (error) {
      console.error('Error in creating offering:', error);
    }

    offeringCreatedDialog.openDialog();
  };

  const selectedPoolKey = Object.keys(selectedPools)[0];
  const selectedPoolIndex = parseInt(selectedPoolKey, 10);
  const selectedListing = data?.user.company.listings[selectedPoolIndex];
  const canCreateOffering =
    selectedListing &&
    'is_public' in selectedListing &&
    !selectedListing.is_public &&
    Object.keys(selectedPools).length > 0 &&
    documents &&
    documents.length > 0;

  const createOfferingTooltipText = canCreateOffering
    ? ''
    : documents === undefined || documents.length === 0
      ? 'You must have at least one template document to create an offering.'
      : 'You must select a pool to create an offering.';

  const navigateToMarketHome = () => {
    navigate('/marketplace/market-home');
  };

  return (
    <PortfolioLayout
      actions={
        <ModalNotStyled
          trigger={
            <Button
              data-tooltip-id={`create-offering-tooltip`}
              disabled={!canCreateOffering}
            >
              Create Offering
            </Button>
          }
        >
          {({ closeModal }) => (
            <CreateOfferingForm
              closeModal={closeModal}
              createOffering={createOffering}
            />
          )}
        </ModalNotStyled>
      }
    >
      <>
        {!canCreateOffering && (
          <Tooltip id={`create-offering-tooltip`} place="left" variant="info">
            <span>{createOfferingTooltipText}</span>
          </Tooltip>
        )}
        {queryError ? (
          <div>Error retrieving pools</div>
        ) : (
          <>
            {
              loading === false &&
              documents !== undefined &&
              documents.length === 0 && (
                <Container>
                  <Content>
                    You must have at least one template document to create an
                    offering.
                    <LinkButton
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToDocumentLibrary();
                      }}
                    >
                      Go to Document Library
                    </LinkButton>
                  </Content>
                </Container>
              )
            }
            <PoolTable
              data={data?.user.company.listings ?? []}
              selection={{
                selected: selectedPools,
                onSelectionChange: setSelectedPools,
                enableMultiRowSelection: false,
              }}
            />
          </>
        )}
      </>

      <Dialog dialog={offeringCreatedDialog}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={offeringCreatedImgPath} alt="offering created" />{' '}
          <StyledHeading style={{ marginTop: '50px' }}>
            Offering Created
          </StyledHeading>
          <DialogBodyText style={{ width: '300px' }}>
            Offering created successfully.
          </DialogBodyText>
          <DialogActions style={{ width: '150px' }}>
            <BaseButton
              size="medium"
              label="OK"
              onClick={() => {
                offeringCreatedDialog.closeDialog();
                navigateToMarketHome();
              }}
            >
              OK
            </BaseButton>
          </DialogActions>
        </div>
      </Dialog>
    </PortfolioLayout>
  );
};

export default PoolManager;

const Container = styled.div`
  background-color: ${(props) => props.theme.color.infoDefault};
  text-align: center;
`;

const Content = styled.div`
  padding: 10px;
  color: ${(props) => props.theme.color.fgDefault};
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.color.fgDefault};
  cursor: pointer;
  text-decoration: underline;
`;
