import { useMutation } from '@apollo/client';
import {
  UPDATE_FILE_TEMPLATE,
} from 'query/documentLibrary';
import { styled } from 'style/ORSNNTheme';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';
import { IndeterminateCheckbox } from 'common-ui/bool-inputs';
import toast from 'react-hot-toast';
import TemplateToastContent from './TemplateToastContent';
import { Document } from './__generated__/Document';

type InProgressFile = {
  id?: string;
  name: string;
  lastModified: number;
  progress?: {
    percentage: number;
  };
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
`;

const DocumentItem = styled.div`
  color: ${(props) => props.theme.color.slate200};
  padding: 8px;
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: rgba(232, 94, 229, 0.15);
  }
`;

const IconLinkWrapper = styled.a`
  padding: 0 8px;
  display: flex;
  color: ${(props) => props.theme.color.slate200};
  text-decoration: none;
`;

const LinkText = styled.span`
  padding-left: 8px;
  color: ${(props) => props.theme.color.accentDefault};
`;

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
`;

interface PassedProps {
  documents: Document[];
}

function DocumentDetails({
  documents,
}: PassedProps) {

  const [updateFileTemplate] = useMutation(UPDATE_FILE_TEMPLATE,
    {
      refetchQueries: ['GetDocumentLibrary'],
    }
  );

  const toggleTemplate = async (
    document: Document
  ) => {
    const isCreatingTemplate = !document.is_template;

    await updateFileTemplate({
      variables: {
        id: document.id,
        isTemplate: !document.is_template,
      },
    });

    const toastId = toast(
      <TemplateToastContent
        documentName={document.name}
        isCreatingTemplate={isCreatingTemplate}
        onClose={() => toast.dismiss(toastId)}
      />
    );
  };

  // TODO keep a list of documents that are uploading and filter out records coming from docs prop

  return (
    <Container>
      {documents &&
        documents.map((document: Document) => {
          return (
            <div key={document.id}>
              <DocumentItem>
                <span>{document.name}</span>
                <ActionGroup>
                  <IconLinkWrapper
                    target="blank"
                    href={document.documentUrl}
                    download
                  >
                    <SvgIcon name="pdf" />
                    <LinkText>View</LinkText>
                  </IconLinkWrapper>
                  <IndeterminateCheckbox
                    checked={document.is_template}
                    onChange={() => toggleTemplate(document)}
                  />
                </ActionGroup>
              </DocumentItem>
            </div>
          );
        })}
    </Container>
  );
}

export default DocumentDetails;
export type {
  InProgressFile,
};
