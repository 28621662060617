import classNames from 'classnames';
import './DropdownMenuItem.scss';

export interface DropdownMenuItemProps {
  label: string;
  icon?: JSX.Element;
  link?: string;
  active?: boolean;
  onClick?: () => void;
  newTab?: boolean;
}

export const DropdownMenuItem = ({
  label,
  icon,
  link,
  active,
  onClick,
  newTab,
}: DropdownMenuItemProps) => {
  const EntryTag = link ? 'a' : 'button';

  return (
    <div className={classNames('dropdown-menu-item', active ? 'active' : '')}>
      <EntryTag
        {...(link && { href: link })}
        {...(link && newTab && { target: '_blank', rel: 'noopener noreferrer' })}
        onClick={(_ev) => onClick?.()}
      >
        {icon && (
          <span className="dropdown-menu-item__icon">
            {icon}
          </span>
        )}
        <span>{label}</span>
      </EntryTag>
    </div>
  );
};
