import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { BaseButton } from 'common-ui/Buttons/BaseButton/BaseButton';
import DownloadLink from 'ui-kit/DownloadLink/DownloadLink';
import { IconInfoCircle } from '@tabler/icons-react';
import { ModalNotStyled } from 'ui-kit';
import ESignIcon from 'assets/pngs/ESignModal.png';

const SignSettingsSchema = Yup.object().shape({
  sellerReference: Yup.string().required('Required'),
  buyerReference: Yup.string().required('Required'),
});

interface ContractReferencesModalProps {
  initialValues: { sellerReference: string; buyerReference: string };
  onSubmit: (values: { sellerReference: string; buyerReference: string }) => void;
  trigger: React.ReactElement;
}

const ContractReferencesModal: React.FC<ContractReferencesModalProps> = ({ initialValues, onSubmit, trigger }) => {
  let formSubmitHandler: (() => void) | null = null; // This will hold the submit function reference

  return (
    <ModalNotStyled trigger={trigger}>
      {({ closeModal }) => (
        <div className="p-6 bg-background-canvas rounded-lg border border-pink-500 shadow-lg text-white max-w-[400px] w-full font-heebo">
          <div className="flex justify-end">
            <button className="text-grey-500 hover:text-grey-300" onClick={closeModal}>
              ✕
            </button>
          </div>
          <div className="flex flex-col justify-between items-center">
            <img src={ESignIcon} alt="ESign Icon" className="w-80 h-80" />
          </div>
          <h2 className="text-xl font-semibold mb-4 text-center">Contract Signatures Text References</h2>
          <p className="mb-4 text-sm text-center">
            Enter each text reference you’ve identified in your contract document for both the seller’s and buyer’s signature.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={SignSettingsSchema}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
              closeModal();
            }}
          >
            {({ errors, touched, submitForm }) => {
              formSubmitHandler = submitForm;

              return (
                <Form>
                  <div className="mb-4">
                    <label className="block text-sm mb-1">Seller’s signature reference text</label>
                    <Field
                      name="sellerReference"
                      placeholder="<seller-reference-text>"
                      className="w-full p-2 bg-slate-800 text-white border border-slate-700 rounded"
                    />
                    {errors.sellerReference && touched.sellerReference ? (
                      <div className="text-red-500 text-sm">{errors.sellerReference}</div>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm mb-1">Counterparty’s signature reference text</label>
                    <Field
                      name="buyerReference"
                      placeholder="<buyer-reference-text>"
                      className="w-full p-2 bg-slate-800 text-white border border-slate-700 rounded"
                    />
                    {errors.buyerReference && touched.buyerReference ? (
                      <div className="text-red-500 text-sm">{errors.buyerReference}</div>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <BaseButton
                      type="primary"
                      className="bg-purple-600 text-white"
                      label="save"
                      onClick={() => {
                        if (formSubmitHandler) {
                          formSubmitHandler();
                        }
                      }}
                    >
                      Save
                    </BaseButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <DownloadLink text="See example document" url="" icon={<IconInfoCircle stroke={2} />} iconPosition="before" />
        </div>
      )}
    </ModalNotStyled>
  );
};

export default ContractReferencesModal;
