import { PillFilters } from 'features/common-elements';
import DiligencePopulationMetrics, {
  DiligencePopulationMetricsProps,
} from './DiligencePopulationMetrics';
import { DiligenceStatus } from './DiligenceStatus';
import { ReviewDiligenceTable } from './ReviewDiligenceTable';
import { Row, SmallRowLabel, SpaceBetween, TabContent } from './commonStyles';
import { AssetClass, DealRole, ParentType, FileType } from '__generated__/globalTypes';
import { useFilterConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { LoanDiligenceStatus } from './LoanDiligenceStatus';
import { useDialog } from 'common-ui/Dialog';
import { QuickStatusDialog } from './QuickStatusDialog';
import { FileDiligenceStatus } from './FileStatus';
import DiligenceLoanDocumentDrawer, {
  DocumentsProps,
} from './DiligenceLoanDocumentDrawer';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { useLoansSelectedForDiligence } from './queries/useLoansSelectedForDiligence';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { uploadConfig } from 'app-level/config/uploadConfig';
import toast from 'react-hot-toast';
import { IconFileTypeZip, IconInfoCircle } from '@tabler/icons-react';
import FileUploadProgressBar from 'ui-kit/FileUploadProgressBar/FileUploadProgressBar';
import { BaseButton, ModalNotStyled } from 'common-ui';
import { GET_DILIGENCE_AI } from 'query/diligence';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Tooltip } from 'react-tooltip';

// Reprt will be added in RC2
// const formatDisplayDate = (date: Date) => {
//   const now = DateTime.now();
//   const dateTime = DateTime.fromJSDate(date);

//   if (dateTime.hasSame(now, 'day')) {
//     return `Today @ ${dateTime.toFormat('h:mm a')} PT`;
//   } else if (dateTime.hasSame(now.minus({ days: 1 }), 'day')) {
//     return `Yesterday @ ${dateTime.toFormat('h:mm a')} PT`;
//   } else {
//     return `${dateTime.toFormat('M.d.yyyy')} @ ${dateTime.toFormat('h:mm a')} PT`;
//   }
// };

const aiPoolInterval = 10000;

export interface ReviewDiligenceProps {
  dealId: string;
  dealName: string;
  assetClass: AssetClass;
  role: DealRole;
  is_complete: boolean;
  reviewDiligenceMetrics: DiligencePopulationMetricsProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: TableQueryParameters<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateQueryParams: (params: Partial<TableQueryParameters<any, any>>) => void;
  totalDiligenceItems?: number;
  diligenceData: GetDealDiligence_deal_diligence[];
  handleLoanDiligenceStatusChanged: (
    loanId: string,
    status: LoanDiligenceStatus
  ) => void;
  fetchCollateralNotes: (diligenceIds: string[]) => void;
  documents: DocumentsProps; // Collateral documents
  userId: string;
}

export const ReviewDiligenceTab: React.FC<ReviewDiligenceProps> = ({
  dealId,
  dealName,
  reviewDiligenceMetrics,
  role,
  queryParams,
  updateQueryParams,
  totalDiligenceItems,
  assetClass,
  diligenceData,
  handleLoanDiligenceStatusChanged,
  fetchCollateralNotes,
  documents,
  userId,
}) => {
  const { setDocumentSelectedForView } = useDealRoomContext();
  const { deal, setActionTargetLoanId, selectedDiligenceForDialog } =
    useReviewDiligenceTab(dealId, assetClass);

  const portfolioFiltersConfig = useFilterConfig(assetClass, { parentType: ParentType.DEAL, parentId: dealId });
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerLoanId, setDrawerLoanId] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);

  const { data: aiDiligenceData } = useQuery(GET_DILIGENCE_AI, {
    variables: { input: { dealId } },
    skip: role !== DealRole.SELLER,
    pollInterval: aiPoolInterval,
  });

  useEffect(() => {
    if (aiDiligenceData?.getDiligenceAI?.processingStatus) {
      const { activeZipFileName, customerPercentage } = aiDiligenceData.getDiligenceAI.processingStatus;

      if (activeZipFileName) {
        setFileName(activeZipFileName);
      }

      setProgress(customerPercentage || 0);
    } else {
      setFileName('');
      setProgress(0);
    }
  }, [aiDiligenceData]);

  const quickStatusDialog = useDialog();

  const handleQuickStatusChange = (
    dealId: string,
    loanId: string,
    fileName: string,
    status: FileDiligenceStatus,
  ) => {
    documents.onFileStatusSelect(dealId, loanId, fileName, status);
  };

  const handleQuickStatusClick = (loanId: string) => {
    setActionTargetLoanId(loanId);
    quickStatusDialog.openDialog();
  };

  const handleViewClick = (loanId: string) => {
    setDrawerLoanId(loanId);
    setIsDrawerOpen(true);
  };

  const handleLoanSelectedInDrawer = (loanId: string) => {
    setDrawerLoanId(loanId);
  };

  const handleCloseDrawer = () => {
    setDocumentSelectedForView(false);
    setIsDrawerOpen(false);
  };

  const handleFileSuccess = (file?: File, closeModal?: () => void) => {
    toast.success(
      <div className="flex flex-col">
        <p>Upload was successful</p>
        <p>Your .zip '{file?.name}' was successfully uploaded.</p>
      </div>,
      {
        duration: 4000,
        position: 'bottom-right',
      }
    );

    if (closeModal) {
      closeModal();
    }
  };

  const renderZipUploadModal = (trigger: React.ReactElement) => {
    return (
      <ModalNotStyled trigger={trigger}>
        {({ closeModal }) => (
          <div className="p-6 bg-background-canvas rounded-lg border border-pink-500 shadow-lg text-white max-w-[647px] w-full font-heebo">
            <div className="flex justify-end items-center">
              <button className="text-gray-500 hover:text-gray-300" onClick={closeModal}>
                ✕
              </button>
            </div>
            <h2 className="flex text-xl font-semibold justify-center mt-2">Collateral Batch Upload</h2>
            <p className="flex justify-center mt-8 text-center mb-8">Each collateral document will be processed and mapped to each respective Loan ID.</p>

            <FileUpload
              parentId={dealId}
              fileType={FileType.OTHER}
              allowedFileTypes={uploadConfig.diligenceZip.allowedFileTypes}
              onFileSuccess={(file) => handleFileSuccess(file, closeModal)}
              width="auto"
              showButtons={false}
              timeToHideProgress={2}
              isCollateral={true}
              vertical={true}
              allowedTypesMessage={uploadConfig.diligenceZip.message}
              onProgressCallback={setProgress}
              showProgress={false}
              uploadedBy={userId}
              disabled={!!fileName}
            />

            {fileName &&
              <div className="flex justify-between items-center border-t border-slate-500 p-2 mt-4">
                <p className="text-white">{fileName}</p>
                {aiDiligenceData?.getDiligenceAI?.processingStatus.diligenceInProgress >= 0 ? (
                  <p className="text-yellow-500">{progress}% uploaded</p>
                ) : progress === 100 ? (
                  <p className="text-green-500">File uploaded successfully</p>
                ) : (
                  <p className="text-yellow-500">You will be notified once the upload is complete.</p>
                )}
                <IconFileTypeZip stroke={2} />
              </div>
            }
          </div>
        )}
      </ModalNotStyled>
    );
  };

  const ProgressMessage = memo(
    ({
      isDiligenceInProgress,
      error,
    }: {
      isDiligenceInProgress: boolean;
      error?: string | null;
    }) => {
      if (error) {
        return (
          <div className="flex items-center gap-2 text-red-400">
            <IconInfoCircle stroke={2} size={24} className="text-red-400" data-tooltip-id="error-tooltip" />
            <span>There was an error with your data.</span>
            <Tooltip id="error-tooltip" className="max-w-[300px]" place="left">
              <p>There was an error with your data. Our Engineering team was notified and is actively fixing the issue now.</p>
            </Tooltip>
          </div>
        );
      }

      const iconColorClass = isDiligenceInProgress ? 'text-blue-400' : 'text-yellow-400';
      const tooltipId = isDiligenceInProgress ? 'ai-diligence' : 'processing-zip';
      const tooltipText = isDiligenceInProgress
        ? 'The AI diligence checks are currently being performed on your uploaded documents.'
        : 'Documents are being processed and matched to loans. This may take a few minutes.';

      return (
        <div className="flex items-center">
          <IconInfoCircle stroke={2} size={24} className={iconColorClass} data-tooltip-id={tooltipId} />
          <Tooltip id={tooltipId} className="max-w-[300px]" place="left">
            <p>{tooltipText}</p>
          </Tooltip>
        </div>
      );
    }
  );

  return (
    <>
      <TabContent>
        <Row>
          <SmallRowLabel>Diligence Population</SmallRowLabel>
          <DiligencePopulationMetrics metrics={reviewDiligenceMetrics} />
        </Row>
        <Row>
          <SmallRowLabel>Collateral File Status</SmallRowLabel>
          <DiligenceStatus diligenceData={diligenceData} />
        </Row>
        <Row>
          <SpaceBetween>
            <div>
              {portfolioFiltersConfig && (
                <PillFilters
                  filtersConfig={portfolioFiltersConfig}
                  currentFilters={queryParams.filters}
                  setCurrentFilters={(filters) =>
                    updateQueryParams({ ...queryParams, filters })
                  }
                />
              )}
            </div>
          </SpaceBetween>
        </Row>
        {
          role === DealRole.SELLER && (
            <Row className="justify-end">
              <div className="flex gap-4 items-center">
                {fileName && (
                  <>
                    <ProgressMessage
                      isDiligenceInProgress={!!aiDiligenceData?.getDiligenceAI?.processingStatus?.diligenceInProgress}
                      error={aiDiligenceData?.getDiligenceAI?.processingStatus?.error}
                    />
                    {
                      !aiDiligenceData?.getDiligenceAI?.processingStatus?.error &&
                      <FileUploadProgressBar fileName={fileName} progress={progress} />
                    }
                  </>
                )}
                {!fileName &&
                  renderZipUploadModal(
                    <BaseButton
                      type="secondary"
                      size="compact"
                      label="Batch Upload"
                      className="py-[2px] px-[6px]"
                    >
                      Batch Upload: Collateral Files (.zip)
                    </BaseButton>
                  )}
              </div>
            </Row>
          )
        }

        {/* Reprt will be added in RC2 */}
        {/* <Row>
          <GenericDropdown
            position="left"
            label="View Reports"
          >
            <div className="p-3 bg-background-canvas rounded-lg border border-pink-500 shadow-lg w-[384px]">
              <p className="flex justify-start font-heebo">Generated reports for Deal-ID</p>
              <span className="text-xs text-slate-400 mt-3 mb-[10px]">Total: 3 Reports</span>
              <div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date('02.03.2023'))}</span>
                  <DownloadLink text="Download (.CSV)" url="#"/>
                </div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date(Date.now() - 86400000))}</span>
                  <DownloadLink text="Download (.CSV)" url="#"/>
                </div>
                <div className="flex justify-between items-center mb-3 text-xs">
                  <span className="text-gray-50">Deal-1</span>
                  <span className="text-gray-600">{formatDisplayDate(new Date())}</span>
                  <DownloadLink text="Download (.CSV)" url="#"/>
                </div>
              </div>
            </div>
          </GenericDropdown>
        </Row> */}

        <ReviewDiligenceTable
          role={role}
          onLoanDiligenceStatusChanged={handleLoanDiligenceStatusChanged}
          deal={deal}
          assetClass={assetClass}
          sorting={{
            state: queryParams.sortings,
            onSortingChanged: (newSortings) => {
              updateQueryParams({ sortings: newSortings });
            },
          }}
          onViewClick={handleViewClick}
          onQuickStatusClick={handleQuickStatusClick}
          pagination={{
            updateParams: updateQueryParams,
            queryParams,
            totalItems: totalDiligenceItems,
          }}
        />
      </TabContent>

      <QuickStatusDialog
        loanName={
          selectedDiligenceForDialog?.loan.account_id || 'Unknown Loan'
        }
        collateral={selectedDiligenceForDialog?.collateral || []}
        dialog={quickStatusDialog}
        onStatusChange={handleQuickStatusChange}
      />
      {isDrawerOpen && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={handleCloseDrawer}
          />
          <div
            className={`fixed top-0 right-0 w-[1070px] h-full bg-background-surface shadow-lg transform transition-transform duration-300 ease-in-out z-20 ${
              isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <DiligenceLoanDocumentDrawer
              role={role}
              dealName={dealName}
              diligenceSelector={{
                diligence: diligenceData,
                selectedLoanId: drawerLoanId,
                onSelectLoan: handleLoanSelectedInDrawer,
                fetchCollateralNotes,
              }}
              documents={documents}
              userId={userId}
              dealId={dealId}
            />
          </div>
        </>
      )}
    </>
  );
};

const useReviewDiligenceTab = (dealId: string, assetClass: AssetClass) => {
  const { data } = useLoansSelectedForDiligence(dealId, assetClass);

  const [actionTargetLoanId, setActionTargetLoanId] = useState<string | null>(
    null
  );

  const diligenceLoans = useMemo(
    () =>
      data?.deal?.diligence.map(
        (diligence) => diligence.loan as LoanDatatableLoan
      ) || [],
    [data?.deal?.diligence]
  );

  const selectedDiligenceForDialog = useMemo(
    () =>
      data?.deal?.diligence.find(
        (diligence) => diligence.loan.id === actionTargetLoanId
      ),
    [data?.deal?.diligence, actionTargetLoanId]
  );

  const setActionTargetLoanIdCallback = useCallback((id: string | null) => {
    setActionTargetLoanId(id);
  }, []);

  return useMemo(
    () => ({
      deal: data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanId: setActionTargetLoanIdCallback,
    }),
    [
      data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanIdCallback,
    ]
  );
};

