import { CHARTS, CommonChartProps } from 'features/visualizations';
import {curry} from 'lodash';
import type { StratCardContentProps } from './StratCard';
import { StratRowType } from './startifications.config';
import { useIntl } from 'react-intl';


export function StratChart(props: StratCardContentProps) {
  const intl = useIntl();
  if (props.data.length === 0) return <div>NO DATA</div>;

  const formatter = curry(props.config.formatKey)(intl, props.assetClass);

  const chartProps: CommonChartProps<StratRowType> = {
    data: props.data,
    keyField: 'key',
    valueField: 't_current_balance_cents',
    formatKey: formatter,
    valueFormatOptions: {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      prepareNumber: (num) => num / 100,
    },
  };

  const avgField = 'avgField' in props.config ? props.config.avgField : null;
  const avgFormat = 'avgFormat' in props.config ? props.config.avgFormat : null;
  const avgValue = avgField ? props.avgSummary[avgField] : null;
  const avgFormatted = (avgValue && avgFormat) ? avgFormat(intl, avgValue): avgValue?.toString() || '-';
  const centralLabel = 'avgLabel' in props.config ? props.config.avgLabel : 'AVERAGE';

  switch(props.config.chart) {
    case 'Bar':
      return (
        <CHARTS.Bar {...chartProps} yAxisLabel="UPB"/>
      );
    case 'Pie':
      return (
        <CHARTS.Pie
          {...chartProps}
          centralValue={avgFormatted}
          centralLabel={centralLabel}
        />
      );
    case 'States':
      return (
        <CHARTS.States {...chartProps}/>
      );
  }
}
