import { gql } from '@apollo/client';
import BidStipulationFragments from './BidStipulations.fragments';
import CarveTableFragments from './CarveTable.fragments';

const BiddingAndPricingControllerFragments = {
  TimelineCard: gql`
    fragment BiddingAndPricingControllerCard on TimelineCard {
      ... on PlaceBidCard {
        bid {
          bid_basis_points
        }
        assetClass: asset_class
        ...CarveTablePlaceBidCard
        ...BidStipulationsPlaceBidCard
      }
    }
    ${CarveTableFragments.PlaceBidCard}
    ${BidStipulationFragments.PlaceBidCard}
  `,
};

export default BiddingAndPricingControllerFragments;
