import React, { FC } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { QUERY_KEYS } from '../shared';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { CustomTooltip } from './CustomTooltip';

type YieldData = {
  labels: string[];
  series: {
    [key: string]: number[];
  }
}

type DataPoint = {
  term: string;
  [key: string]: string | number;
}

type YieldCurveProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
}

const colors = ['#e75ee4', '#3ccece', '#8490ec'];

export const USTYieldCurve: FC<YieldCurveProps> = ({ dataUrl, width=620, height=382, className='' }) => {
  const { data, isLoading } = useReactQuery<YieldData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.UST_YIELD_CURVE, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      return data.labels.map((label, index) => {
        const dataPoint: DataPoint = { term: label };
        Object.keys(data.series).forEach((key) => {
          dataPoint[key] = data.series[key][index];
        });
        return dataPoint;
      });
    },
  });

  if (isLoading || !data) {
    return <div className='mt-16'>
      <ChartSkeletonLoader width={width} height={height} />
    </div>;
  }

  const seriesNames = Object.keys(data[0]).filter((key) => key !== 'term');

  // Calculate the max and min data values
  const maxDataValue = Math.max(...data.flatMap(point => seriesNames.map(name => point[name] as number)));
  const minDataValue = Math.min(...data.flatMap(point => seriesNames.map(name => point[name] as number)));
  
  // Calculate the Y-axis ticks and domain
  const axisTop = Math.ceil(maxDataValue);
  const axisBottom = Math.floor(minDataValue);
  const yAxisTicks = Array.from({ length: axisTop - axisBottom + 1 }, (_, i) => axisBottom + i);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit relative ${className}`}>
      <h3 className='text-center text-slate-100 font-heebo text-lg'>Yield Curve</h3>
      <div className="relative" style={{ width, height: height + 20 }}>
        <div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(ellipse at center, rgba(60,206,206,0.2) 0%, rgba(255,0,210,0) 70%)',
            opacity: 0.4,
          }}
        />
        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{ bottom: 32 }}
        >
          <XAxis
            dataKey="term"
            stroke="#BBC5D7"
            tickMargin={16}
          >
            <Label
              value="Term"
              offset={10}
              position="bottom"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </XAxis>
          <YAxis
            domain={[axisBottom, axisTop]} // Use calculated domain
            stroke="#BBC5D7"
            tickMargin={12}
            ticks={yAxisTicks} // Use calculated ticks
          >
            <Label
              value="Yield %"
              angle={-90}
              position="insideLeft"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
              offset={10}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' />
          {seriesNames.map((seriesName, index) => (
            <Line
              key={seriesName}
              type="linear"
              dataKey={seriesName}
              stroke={colors[index % colors.length]}
              dot={true}
              strokeWidth={2}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
