import { useMemo } from 'react';
import { CounterpartyDetails } from 'features/deals/dealCards/CounterpartyCard';
import { CounterpartyCard_CounterpartyCard } from './__generated__/CounterpartyCard';
import { useMutation } from '@apollo/client';
import { AdvanceDeal, AdvanceDealVariables } from 'mutation/__generated__/AdvanceDeal';
import { GET_DEAL_TIMELINE } from '../../fragments';
import { ADVANCE_DEAL } from 'mutation/advanceDeal';
import { Button, KIND } from 'baseui/button';
import ProgressBar from 'ui-kit/ProgressBar/ProgressBar';
import { GetTimelineVariables } from '../../__generated__/GetTimeline';
import { DealActionOptionType } from '__generated__/globalTypes';

type Props = { dealId: string } & CounterpartyCard_CounterpartyCard;

const CounterpartyCardBody = (props: Props): JSX.Element => {
  const { dealId, counterparty, is_under_review } = props;

  const [advanceDeal] = useMutation<AdvanceDeal, AdvanceDealVariables>(ADVANCE_DEAL);

  const getTimelineVariables: GetTimelineVariables = useMemo(() => ({
    id: dealId,
  }), [dealId]);

  const buttons = is_under_review && (
    <>
      <Button
        kind={KIND.primary}
        onClick={() =>
          advanceDeal({
            variables: { id: dealId, action: DealActionOptionType.APPROVE_BUYER },
            refetchQueries: [
              {
                query: GET_DEAL_TIMELINE,
                variables: getTimelineVariables,
              },
            ],
          })
        }
      >
        Approve Buyer Access
      </Button>
      <Button
        kind={KIND.secondary}
        onClick={() =>
          advanceDeal({
            variables: { id: dealId, action: DealActionOptionType.REJECT_BUYER },
            refetchQueries: [
              {
                query: GET_DEAL_TIMELINE,
                variables: getTimelineVariables,
              },
            ],
          })
        }
      >
        Deny Access Request
      </Button>
    </>
  );

  const metrics = useMemo(() => ({
    'Loans/Shares': counterparty?.regulatory_metrics?.loans_shares_rank,
    'Delinquency': counterparty?.regulatory_metrics?.delinquency_rank,
    'Charge Offs': counterparty?.regulatory_metrics?.charge_off_ratio_rank,
    'Net Worth Ratio': counterparty?.regulatory_metrics?.net_worth_ratio_rank,
    'Expense Ratio': counterparty?.regulatory_metrics?.expense_ratio_rank,
    'ROA': counterparty?.regulatory_metrics?.roa_rank,
    'ROE': counterparty?.regulatory_metrics?.roe_rank,
    'Allowance/Total Loans': counterparty?.regulatory_metrics?.allowance_total_loans_rank,
    'Efficiency Ratio': counterparty?.regulatory_metrics?.efficiency_ratio_rank,
    'IEA/Total Assets': counterparty?.regulatory_metrics?.iea_total_assets_rank
  }), [counterparty]);

  const averageIndex = useMemo(() => {
    const ranks = Object.values(metrics).filter((rank): rank is number => rank != null);
    return ranks.length > 0
      ? ranks.reduce((acc, rank) => acc + rank, 0) / ranks.length
      : 0;
  }, [metrics]);

  return (
    <>
      <div className='flex gap-4'>
        {metrics && (
          <div className='w-[400px]'>
            <div>
              <div className="border-t border-b border-slate-500 pt-[7px] pb-[9px] flex justify-between mb-2">
                <div className='flex'>
                  <p className='text-slate-200 text-sm uppercase'>Regulatory Index</p>
                </div>
                <p className='text-slate-200 text-sm'>{averageIndex.toFixed(2)}th Index</p>
              </div>
              {Object.entries(metrics).map(([label, rank]) => (
                <ProgressBar key={label} label={label} rank={rank} />
              ))}
            </div>
          </div>
        )}
        <CounterpartyDetails {...props} />
      </div>
      <div className='flex flex-row gap-2.5 mt-2.5'>
        {buttons}
      </div>
    </>
  );
};

export default CounterpartyCardBody;
