import {
  GetUploadedFilesWithHeaders_user_company_loan_tapes,
} from 'query/__generated__/GetUploadedFilesWithHeaders';
import { createContext, useContext } from 'react';
import { GetUploadedFilesWithHeaders_user_company_loan_tapes as UploadedFile } from 'query/__generated__/GetUploadedFilesWithHeaders';

const TapeUploadContext = createContext<TapeUploadContextProps | undefined>(
  undefined
);

export interface TapeUploadContextProps {
  uploadedTapes: GetUploadedFilesWithHeaders_user_company_loan_tapes[] | undefined;

  // TODO this should become a search param so that users can share the selected file
  selectedFileId: string | undefined;
  setSelectedFileId: (id: string | undefined) => void;

  selectedFile: UploadedFile | undefined;
}

export const useTapeUploadContext = () => {
  const context = useContext(TapeUploadContext);
  if (!context) {
    throw new Error(
      'This component must be used within a TapeUploadContext.Provider'
    );
  }
  return context;
};

export default TapeUploadContext;
