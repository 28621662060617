import { gql } from '@apollo/client';
import LoanDatatableFragments from 'features/drilldown/LoanDatatable/fragments';

const GET_DILIGENCE_NOTES = gql`
  query GetDiligenceNotes($diligence_id: ID!) {
    diligenceNotes(diligence_id: $diligence_id) {
      entities {
        id
        diligence_id
        created_time
        note
        author {
          given_name
          family_name
        }
      }
      last_evaluated_key {
        id
        diligence_id
        created_time
      }
    }
  }
`;

const GET_DEAL_DILIGENCE = gql`
  query GetDealDiligence(
    $deal_id: ID!
    $sort: Sort!
    $pagination: Pagination!
    $filters: [Filter!]
  ) {
    deal(id: $deal_id) {
      id
      diligence(sort: $sort, pagination: $pagination, filters: $filters) {
        id
        collateral {
          collateralID
          loanID
          dealID
          fileName
          s3Key
          ZipFileName
          collateral_status
          note_status
        }
        loan {
          id
          ...LoanDatatableLoan
        }
        note_status
        status
      }
      diligence_performance_summary(filters: $filters) {
        unpaidBalance: current_balance_cents
        originalBalance: original_balance_cents
        loanCount: loan_count
        averageBalance: wa_current_balance_cents
        averageOriginalBalance: wa_original_balance_cents
        netCoupon: wa_coupon
        averageAge: wa_age_months
        averageMaturity: wa_remaining_loan_terms_months
        fico: wa_borrower_credit_score
        dti: wa_dti
        ltv: wa_ltv
        states: strat_state {
          stateCode: key
          loanCount: t_count
        }
      }
    }
  }
  ${LoanDatatableFragments.loan}
`;

const GET_DILIGENCE_AI = gql`
  query GetDiligenceAI($input: DiligenceInput!) {
    getDiligenceAI(input: $input) {
      dealID
      dealDiligence {
        loanId
        diligenceSummary {
          finalDiligence {
            passFail
            reason
          }
          consensusDiligence {
            passFail
            reason
          }
          diligenceByFile {
            fileName
            result {
              passFail
              reason
            }
          }
        }
      }
      processingStatus {
        activeZipFileName
        nFilesCompleted
        expectedFileCount
        diligenceInProgress
        customerPercentage
        error
        zipProcessingStartTime
      }
    }
  }
`;

export { GET_DILIGENCE_NOTES, GET_DEAL_DILIGENCE, GET_DILIGENCE_AI };
