import _ from 'lodash';
import * as navigation from 'app-level/navigation';
import { useFilterConfig } from './portfolioFilters.config';
import { ParentType } from '__generated__/globalTypes';
import { ASSET_CLASS_IDS } from 'models/AssetClass';

export function usePortfolioFilters() {
  const assetClass = navigation.usePortfolioAssetClass();
  const filtersConfig = useFilterConfig(assetClass && ASSET_CLASS_IDS[assetClass], { parentType: ParentType.COMPANY });

  const [currentFilters, setCurrentFilters] = navigation.usePortfolioFilters();
  const { poolFromUrl, setPool } = navigation.usePoolUrl();

  return {
    filtersConfig,
    currentFilters,
    setCurrentFilters,
    poolFromUrl,
    setPool,
  };
}
