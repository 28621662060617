import { Amplify, Auth } from 'aws-amplify';
import { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from 'style/GlobalStyle';
import { AuthContextProvider } from './context/AuthContext';

import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  isReference,
  Reference,
  StoreObject,
} from '@apollo/client';

import introspectionResult from './introspection-result';
import config from 'config';

import {
  ListDealMessages_dealMessages,
  ListDealMessages_dealMessages_entities,
} from 'features/deals/Messaging/__generated__/ListDealMessages';
import { initializeLogger } from 'logger';

const awsconfig = {
  aws_project_region: config.apiGateway.REGION,
  aws_cognito_identity_pool_id: config.cognito.IDENTITY_POOL_ID,
  aws_cognito_region: config.cognito.REGION,
  oauth: {},
};

Amplify.configure({
  ...awsconfig,
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'orsnn',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        graphql_endpoint_iam_region: config.apiGateway.REGION,
      },
      {
        name: 'orsnn-file',
        endpoint: config.fileGateway.URL,
        region: config.fileGateway.REGION,
      },
      {
        name: 'orsnn-smart_header',
        endpoint: config.smartHeaderGateway.URL,
        region: config.smartHeaderGateway.REGION,
      },
    ],
    aws_appsync_graphqlEndpoint: config.apiGateway.URL,
    aws_appsync_region: config.apiGateway.REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
});

const LOGS_API_URL = config.logs.URL;
if (process.env.NODE_ENV !== 'development') {
  initializeLogger(LOGS_API_URL);
}

const authOptions: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const ApolloLinkConfig = {
  url: config.apiGateway.URL,
  region: config.apiGateway.REGION,
  auth: authOptions,
  disableOffline: true,
};

const link = ApolloLink.from([
  createAuthLink(ApolloLinkConfig),
  createSubscriptionHandshakeLink(ApolloLinkConfig),
]);

interface PaginationArgs {
  filters?: unknown;
  sort?: unknown;
  pagination?: {
    offset: number;
    page_size: number;
  };
}

function itemPaginationCachePolicy<T extends StoreObject | Reference>() {
  return {
    keyArgs: ['filters', 'sort'],
    merge(
      existing: T[] = [],
      incoming: T[] = [],
      { args }: FieldFunctionOptions<PaginationArgs>
    ): T[] {
      const merged = existing.slice(0);
      if (!args?.pagination) {
        return incoming;
      }
      const { offset, page_size } = args.pagination;
      let i = 0;
      for (; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }
      return page_size > incoming.length ? merged.slice(0, offset + i) : merged;
    },
    read(
      existing: T[] = [],
      { args }: FieldFunctionOptions<PaginationArgs>
    ) {
      if (!args?.pagination || existing.length === 0) {
        return existing;
      }
      const { offset, page_size } = args.pagination;
      return existing.slice(offset, offset + page_size);
    },
  };
}

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      UserCompany: {
        fields: {
          loans: itemPaginationCachePolicy<Reference>(),
          templates: {
            merge(_existing = [], incoming) {
              return incoming;
            },
          },
        },
      },
      Deal: {
        fields: {
          loans: itemPaginationCachePolicy<Reference>(),
        },
      },
      File: {
        fields: {
          processing_report: {
            merge(existing = {}, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Query: {
        fields: {
          listings: itemPaginationCachePolicy<Reference>(),
          dealMessages: {
            keyArgs: ['deal_id'],
            merge(
              existing: ListDealMessages_dealMessages | undefined,
              incoming: ListDealMessages_dealMessages,
              { readField }
            ): ListDealMessages_dealMessages | undefined {
              const ids = new Set<string>();
              const entities: ListDealMessages_dealMessages_entities[] = [];
              const rawEntities = [
                ...(existing?.entities || []),
                ...(incoming.entities || []),
              ];

              rawEntities.forEach((e) => {
                if (e === null || !isReference(e)) {
                  return;
                }
                const id = readField<string>('id', e);
                if (id && !ids.has(id)) {
                  entities.push(e);
                  ids.add(id);
                }
              });

              return {
                entities: entities.sort((a, b) => {
                  if (!isReference(a) || !isReference(b)) {
                    return 0;
                  }
                  const aTime = readField<number>('created_time', a) || 0;
                  const bTime = readField<number>('created_time', b) || 0;
                  return aTime - bTime;
                }),
                last_evaluated_key: incoming?.last_evaluated_key,
              };
            },
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <GlobalStyle />
      <IntlProvider locale='en-US'>
        <BrowserRouter>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </BrowserRouter>
      </IntlProvider>
    </ApolloProvider>
  </>,
  document.getElementById('root')
);

serviceWorker.unregister();

export { client as apolloClient };

