import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_REGULATORY_METRICS } from 'query/companyRegulatoryMetrics';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency, formatPercent } from 'configs/columns';
import { GetCompanyRegulatoryMetrics, GetCompanyRegulatoryMetricsVariables } from 'query/__generated__/GetCompanyRegulatoryMetrics';
import { Spinner } from 'baseui/spinner';
import { BaseButton } from 'common-ui';
import { CREATE_DEAL_MUTATION } from 'features/loanPool/PoolHeader';
import { RequestAccessPublicListing } from './__generated__/RequestAccessPublicListing';
import ProgressBar from 'ui-kit/ProgressBar/ProgressBar';

type RequestAccessProps = {
  listingId: string;
  listingDetails: RequestAccessPublicListing;
  dealId?: string | null;
};

const RequestAccess: React.FC<RequestAccessProps> = ({ listingDetails, listingId, dealId }) => {
  const navigate = useNavigate();
  const [createDeal, { loading: creatingDeal }] = useMutation(CREATE_DEAL_MUTATION);

  const { loading, data } = useQuery<GetCompanyRegulatoryMetrics, GetCompanyRegulatoryMetricsVariables>(GET_COMPANY_REGULATORY_METRICS, {
    variables: { id: listingId },
  });

  const company = data?.listing?.company;

  const navigateToDeal = (id: string) => navigate(`/marketplace/deal/${id}`);

  const handleAccept = () => {
    createDeal({ variables: { listingId } }).then((response) => {
      const id = response.data?.createDeal.id;

      if (id) {
        navigateToDeal(id);
      }
    });
  };

  const scaledServicingRate = listingDetails.servicing_fee_rate ? listingDetails.servicing_fee_rate / 100 : 0;

  const stats = useMemo(() => [
    { label: 'UPB', value: formatCurrency(listingDetails.t_current_balance_cents) },
    { label: 'GWAC', value: formatPercent(listingDetails.wa_coupon ?? 0, 3) },
    { label: 'NWAC', value: formatPercent((listingDetails.wa_coupon ?? 0) - scaledServicingRate, 3) },
    { label: 'WAM', value: listingDetails.wa_remaining_loan_terms_months ? `${listingDetails.wa_remaining_loan_terms_months} Mo` : 'NO DATA' },
    { label: 'WALA', value: listingDetails.wa_loan_age_months ? `${listingDetails.wa_loan_age_months} Mo` : 'NO DATA' },
  ], [listingDetails, scaledServicingRate]);

  const metrics = useMemo(() => {
    const regulatoryMetrics = company?.regulatory_metrics;

    return {
      'Loans/Shares': regulatoryMetrics?.loans_shares_rank ?? 0,
      'Delinquency': regulatoryMetrics?.delinquency_rank ?? 0,
      'Charge Offs': regulatoryMetrics?.charge_off_ratio_rank ?? 0,
      'Net Worth Ratio': regulatoryMetrics?.net_worth_ratio_rank ?? 0,
      'Expense Ratio': regulatoryMetrics?.expense_ratio_rank ?? 0,
      'ROA': regulatoryMetrics?.roa_rank ?? 0,
      'ROE': regulatoryMetrics?.roe_rank ?? 0,
      'Allowance/Total Loans': regulatoryMetrics?.allowance_total_loans_rank ?? 0,
      'Efficiency Ratio': regulatoryMetrics?.efficiency_ratio_rank ?? 0,
      'IEA/Total Assets': regulatoryMetrics?.iea_total_assets_rank ?? 0,
    };
  }, [company]);

  const renderOfferDetails = () => {
    return (
      <div className="mt-3 max-w-[290px] w-full">
        <h3 className="text-slate-200 text-sm uppercase mb-3">Offer Details</h3>
        {stats.map(({label, value}) => (
          <div key={label} className='flex justify-between border-t border-slate-600 py-2 px-3'>
            <span className='text-slate-200 text-sm'>{label}</span>
            <span className='text-slate-200 text-sm'>{value}</span>
          </div>
        ))}
      </div>
    );
  };

  const averageIndex = company ?
    Object.values(metrics).reduce((acc: number, rank) => acc + (rank ?? 0), 0) / Object.values(metrics).length : 0;

  const handleRequestBtnClick = () => {
    if ( dealId ) {
      navigateToDeal(dealId);
    } else {
      handleAccept();
    }
  }

  return (
    <div className="relative">
      <div className="grid grid-cols-2 gap-6">
        <div>
          {
            loading ?
                <SkeletonLoader /> :
                <>
                  <div className="border-t border-b border-slate-500 pt-[7px] pb-[9px] flex justify-between mb-2">
                    <div className='flex'>
                      <p className='text-slate-200 text-sm uppercase'>Regulatory Index</p>
                    </div>
                    <p className='text-slate-200 text-sm'>{averageIndex}th Index</p>
                  </div>

                  {Object.entries(metrics).map(([label, rank]) => (
                    <ProgressBar key={label} label={label} rank={rank} />
                  ))}
                </>
          }
        </div>
        <div>
          <div>
            <p className='text-slate-200 text-sm uppercase mb-3'>Pool Publish Settings</p>
            <p className="text-slate-200 text-sm mb-2">Once the seller approves your request for data, you will be able to access the loan tape, generate strats and run cashflows on this pool of loans. You can place a bid at anytime.</p>
            <p className="text-slate-200 text-sm">Track your deals in the <Link to="/marketplace/active_trades" className="text-purple-500 uppercase underline underline-offset-2">Active Trades</Link> section.</p>
          </div>
          <div className="mt-4 flex justify-start gap-2">
            <BaseButton
              className='text-white bg-green-600 border-green-600 hover:bg-green-700 hover:border-green-700 hover:text-white'
              type="primary"
              size='small'
              label="Accept"
              onClick={() => handleRequestBtnClick()}
            >
              {dealId ? 'Go to Deal Room' : 'Request Access'}
            </BaseButton>
            <BaseButton
              className='text-white border-green-600 hover:bg-green-700 hover:border-green-700 hover:text-white'
              label="View"
              size='small'
              type="secondary"
              onClick={() => navigate(`/marketplace/listing/${listingId}`)}
            >
              View Stratifications
            </BaseButton>
          </div>
          <div className="mt-3 max-w-[290px] w-full">
            {renderOfferDetails()}
          </div>
        </div>
      </div>
      {creatingDeal && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <Spinner size={70} />
        </div>
      )}
    </div>
  );
};

export const SkeletonLoader: React.FC = () => (
  <div className="animate-pulse">
    <div className="border-t border-b border-slate-500 pt-[7px] pb-[9px] flex justify-between mb-2">
      <div className='h-6 w-24 bg-slate-700 rounded'></div>
      <div className='h-6 w-16 bg-slate-700 rounded'></div>
    </div>

    {Array.from({ length: 8 }).map((_, index) => (
      <div key={index} className="h-6 bg-slate-700 rounded mb-4"></div>
    ))}
  </div>
);

export default RequestAccess;
