export const PORTFOLIO_ROUTES_CONFIG = {
  dashboard: { usesAssetClass: false, usesFilters: false },
  stratifications: { usesAssetClass: true, usesFilters: false },
  'loan-level-data': { usesAssetClass: true, usesFilters: true },
  'pool-manager': { usesAssetClass: false, usesFilters: false },
  'cashflow-modeling': { usesAssetClass: true, usesFilters: true, usesPool: true },
  'document-library': { usesAssetClass: false, usesFilters: false },
  'tape-upload': { usesAssetClass: false, usesFilters: false },
};

export type PortfolioRouteKeys = keyof typeof PORTFOLIO_ROUTES_CONFIG;

export const MARKETPLACE_ROUTES_CONFIG = {
  'market-home': {  },
  'active_trades': {  },
  'executed_trades': {  },
  'deal': { dealIdParam: 'deal_id' as const },
  'listing': { listingIdParam: 'listing_id' as const },
  'account': {  },
};

export const ALL_PAGES = {...PORTFOLIO_ROUTES_CONFIG, ...MARKETPLACE_ROUTES_CONFIG};

export type MarketplaceRouteKeys = keyof typeof MARKETPLACE_ROUTES_CONFIG;

export const PAGE_PARAM = 'page' as const;
export const FILTERS_PARAM = 'filters' as const;
export const POOL_PARAM = 'pool' as const;
export const MAIN_ROUTES_CONFIG = {
  home: { },
  portfolio: { assetClassParam: 'asset_class' as const },
  marketplace: { },
  'market-data': { },
  account: { },
};

//there is an issue with optional params in useMatch - https://github.com/remix-run/react-router/discussions/9862
//change this to use mainRouteMatch[mainRouteKey] when resolved
export const mainRouteMatch: Record<MainRouteKeys, string> = {
  home: 'no match',
  portfolio: `/portfolio/:${PAGE_PARAM}/*`,
  marketplace: `/marketplace/:${PAGE_PARAM}`,
  'market-data': 'no match',
  account: 'no match',
};

export type MainRouteKeys = keyof typeof MAIN_ROUTES_CONFIG;
export class MainKeyTo2ndLevelKeys implements Record<MainRouteKeys, string> {
  home = '';
  portfolio: PortfolioRouteKeys = 'dashboard';
  marketplace: MarketplaceRouteKeys = 'market-home';
  'market-data' = '';
  account = '';
}
