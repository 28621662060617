import { Card, CardHeading, CardInstructions, StyledFooter } from '../styles';
import { styled } from 'style/ORSNNTheme';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FileHeadersList from './FileHeadersList';
import FieldsList from './FieldsList';
import { AssetClass, FileState, LoanField, ProductType } from '__generated__/globalTypes';
import { FIELD_CONFIG } from '../loanFields';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { MappingCardFile } from './__generated__/MappingCardFile';
import { MapFieldsOperationWrapper } from '../MapFieldsOperation';

import { Button } from 'baseui/button';
import { Tooltip } from 'react-tooltip';
import { useMapHeadersToFields } from '../hooks/useMapHeadersToFields';
import LLMAutoMapComponent from './LLMAutoMap';

const MappingCardFragments = {
  loanTape: gql`
    fragment MappingCardFile on File {
      id
      createdDate: created_date
      name
      assetClass: asset_class
      headers
      field_mapping {
        file_column
        field
      }
      productType: product_type
      state
    }
  `,
};

const SelectedFile = styled.div`
  display: grid;
  align-items: center;
  background: rgba(224, 40, 221, 0.28);
  color: rgba(240, 147, 238, 1);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  padding: 4px;
  gap: 6px;
  width: 45%;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  .name {
    grid-column: 1;
  }
  .product-type {
    grid-column: 5;
  }
`;

export interface FieldIdAndLabel {
  fieldId: LoanField;
  label: string;
  fallback?: LoanField;
}

const convertToFieldIdAndLabel = (fc: {
  field: LoanField;
  label: string;
  fallback?: LoanField;
}): FieldIdAndLabel => {
  return { fieldId: fc.field, label: fc.label, fallback: fc.fallback };
};

export type FieldsMap = {
  [fieldId in LoanField]: string;
};

const mappingArrayToMap = (
  mapping: { field: LoanField; file_column: string }[]
) => {
  const map: FieldsMap = {} as FieldsMap;
  mapping.forEach((m) => {
    map[m.field] = m.file_column;
  });
  return map;
};

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const getFileFieldReqs = (selectedFile: MappingCardFile) => {
  const assetClass = selectedFile.assetClass;

  const armedAssetClass =
    assetClass === AssetClass.HOME && selectedFile.productType === 'ARM'
      ? 'HOME_ARM'
      : assetClass;

  const requiredFields = FIELD_CONFIG[armedAssetClass].requiredFields.map(
    convertToFieldIdAndLabel
  );
  const optionalFields = FIELD_CONFIG[armedAssetClass].optionalLoanFields.map(
    convertToFieldIdAndLabel
  );
  return {
    requiredFields,
    optionalFields,
  };
};

type Props = {
  selectedFile: MappingCardFile;
};

const MapRequiredFieldsCard = ({ selectedFile }: Props): JSX.Element => {
  const { requiredFields, optionalFields } = getFileFieldReqs(selectedFile);

  const [fieldsMap, setFieldsMap] = useState<FieldsMap>(
    mappingArrayToMap(selectedFile.field_mapping)
  );

  const { mapHeadersToFields } = useMapHeadersToFields();
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const startMapFields = async (fileId: string, map: FieldsMap) => {
    setIsStarted(true);
    await mapHeadersToFields(fileId, map);
  };

  const unmappedHeaders =
    selectedFile.headers?.filter(
      (header) => !_.includes(_.values(fieldsMap), header)
    ) || [];

  const disabled = selectedFile.state !== FileState.NEEDS_MAPPING;

  const unmappedRequiredFieldCount = requiredFields.filter(
    (field) => !(field.fieldId in fieldsMap) && !(field.fallback != null && field.fallback in fieldsMap)
  ).length;
  const allRequiredMapped = unmappedRequiredFieldCount === 0;

  const onAutoMap = (fieldsMap: FieldsMap) => {
    setFieldsMap(fieldsMap);
  };

  const onMap = (fieldId: string, header: string) => {
    setFieldsMap((prevMappings) => ({
      ...prevMappings,
      [fieldId]: header,
    }));
  };

  const onUnmap = (fieldId: LoanField) => {
    const updatedMap: FieldsMap = { ...fieldsMap };
    delete updatedMap[fieldId];
    setFieldsMap(updatedMap);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Card>
        {disabled && <DisabledOverlay />}
        <CardHeading>Map File Columns to Fields</CardHeading>
        <CardInstructions>
          We've recognized some fields that need to be manually mapped to our
          system. Drag fields from your loan tape over to ORSNN defined fields.
        </CardInstructions>
        <SelectedFile>
          <div className="name">{selectedFile.name} is selected</div>
          {selectedFile.productType === ProductType.ARM && (
            <div className="product-type">{selectedFile.productType}</div>
          )}
        </SelectedFile>
        <div className='flex gap-8 text-sm h-[640px] justify-center mt-2'>
          <FieldsList
            requiredFields={requiredFields}
            optionalFields={optionalFields}
            map={fieldsMap}
            onMap={onMap}
            onUnmap={onUnmap}
          />
          <FileHeadersList unmappedHeaders={unmappedHeaders} />
        </div>
        <StyledFooter>
          <LLMAutoMapComponent
            requiredFields={requiredFields}
            optionalFields={optionalFields}
            columns={selectedFile.headers || []}
            fileId={selectedFile.id}
            onAutoMapComplete={onAutoMap}
          />
          <Button
            data-tooltip-id={`${selectedFile.id}-button-tip`}
            disabled={!allRequiredMapped}
            onClick={() =>
              selectedFile.id && startMapFields(selectedFile.id, fieldsMap)
            }
          >
            Map Fields
          </Button>
          {unmappedRequiredFieldCount > 0 && (
            <Tooltip id={`${selectedFile.id}-button-tip`}>
              {unmappedRequiredFieldCount} fields remaining
            </Tooltip>
          )}
        </StyledFooter>
      </Card>
      <MapFieldsOperationWrapper
        fileState={selectedFile.state}
        isStarted={isStarted}
        setIsStarted={setIsStarted}
      />
    </DndProvider>
  );
};

export default MapRequiredFieldsCard;
export { MappingCardFragments };
