import { FC } from 'react';

export const DocumentPreviewSkeleton: FC = () => {
  return (
    <div className="w-full h-full bg-slate-800 rounded animate-pulse flex flex-col justify-center items-center">
      <div className="w-3/4 h-6 bg-slate-700 mb-4 rounded animate-pulse"></div>
      <div className="w-2/3 h-6 bg-slate-700 mb-4 rounded animate-pulse"></div>
      <div className="w-1/2 h-6 bg-slate-700 rounded animate-pulse"></div>
    </div>
  );
};
