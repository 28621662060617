import React from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

import { ApolloError, gql } from '@apollo/client';
import { TapesUploadedTableFile } from './__generated__/TapesUploadedTableFile';
import { FileState, ProductType } from '__generated__/globalTypes';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ProgressIndicator } from 'common-ui/ProgressIndicator/ProgressIndicator';

type InProgressFile = {
  id?: string;
  name: string;
  lastModified: number;
  progress?: {
    percentage: number;
  };
};


const TapesUploadedTableFragments = {
  loanTape: gql`
    fragment TapesUploadedTableFile on File {
      id
      name
      state
      assetClass: asset_class
      productType: product_type
      createdDate: created_date
      original_id
      uploaded_by {
        id
        firstName: given_name
        lastName: family_name
      }
      processingReport: processing_report {
        new_count
        updated_count
        flagged_count
        duplicate_count
      }
    }
  `,
};

const StyledTableWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
  margin-bottom: 15px;
  font-size: 14px;
  color: ${(props) => props.theme.color.slate50};
`;

const TableRowContainer = styled.div<{
  isSelected: boolean;
  isSelectable: boolean;
  isSaved: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.isSelectable ? 'pointer' : 'not-allowed')};
  border-top: 1px solid ${(props) => props.theme.color.slate400};
  padding: 7px 16px 7px 10px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.color.accentDefault : 'transparent'};
  line-height: 20px;

  color: ${(props) =>
    props.isSelected
      ? '#4d0b4c'
      : props.isSaved
        ? props.theme.color.slate200
        : props.theme.color.slate50};

  border: 1px solid transparent;
  &:hover {
    background-color: ${(props) => props.isSelectable && !props.isSelected && props.theme.color.slate300};
  }
`;

const FilenameCell = styled.div`
  flex: 2;
  margin-right: 16px;
`;

const StatusCell = styled.div`
  flex: 1;
  text-align: left;
  text-wrap: nowrap;
`;

const ProgressCell = styled.div`
  display: flex;
  gap: 18px;
  width: 206px;
  margin-right: 16px;
`;

const RowsCell = styled.div`
  flex: 1;
  text-align: right;
  text-wrap: nowrap;
`;

const TaskBase = styled.span`
  padding: 2px 6px;
  border-radius: 4px;
  color: ${(props) => props.theme.color.slate50};
  background-color: ${(props) => props.theme.color.slate400};
  white-space: nowrap;
`;

const TaskComplete = styled(TaskBase)`
  background-color: ${(props) => props.theme.color.slate400};
`;

const TaskInProgress = styled(TaskBase)`
  background-color: ${(props) => props.theme.color.blue400};
`;

const TaskNotStarted = styled(TaskBase)`
  background-color: ${(props) => props.theme.color.pink500};
`;

const TaskError = styled(TaskBase)`
  background-color: ${(props) => props.theme.color.purple500};
  };
`;

type UploadingCellProps = {
  progress?: number;
}
const UploadingCell = (props: UploadingCellProps) => {
  const { progress } = props;
  if (progress === undefined) {
    return <ProgressCell></ProgressCell>;
  }
  return progress === 100
    ? <StatusCell><TaskNotStarted>Reading.</TaskNotStarted></StatusCell>
    : <ProgressCell><ProgressIndicator percent={progress} /></ProgressCell>;
};

const TAPE_STATE_STRINGS: Record<FileState, { label: string, component: StyledComponent<'span', DefaultTheme, {}, never> }> = {
  [FileState.NEW]: { label: 'Reading..', component: TaskNotStarted },
  [FileState.READING_FILE_HEADERS]: { label: 'Reading...', component: TaskNotStarted },
  [FileState.NEEDS_MAPPING]: { label: 'Needs mapping', component: TaskInProgress },
  [FileState.READY_TO_PROCESS]: { label: 'Processing..', component: TaskNotStarted },
  [FileState.PROCESSING]: { label: 'Processing...', component: TaskNotStarted },
  [FileState.PROCESSED]: { label: 'Ready to save', component: TaskNotStarted },
  [FileState.READY_TO_PERSIST]: { label: 'Saving..', component: TaskNotStarted },
  [FileState.PERSISTING]: { label: 'Saving...', component: TaskNotStarted },
  [FileState.PERSISTED]: { label: 'Saved', component: TaskComplete },
  [FileState.ERROR_HEADER]: { label: 'Error: Invalid headers', component: TaskError },
  [FileState.ERROR_PASSWORD]: { label: 'Error: Password protected', component: TaskError },
  [FileState.ERROR]: { label: 'Error', component: TaskError },
  [FileState.TO_DELETE]: { label: 'Deleting..', component: TaskNotStarted },
  [FileState.DELETING]: { label: 'Deleting...', component: TaskNotStarted },
  [FileState.DELETED]: { label: 'Deleted', component: TaskNotStarted },
  [FileState.DELETE_ERROR]: { label: 'Error', component: TaskNotStarted },
};

export type Props = {
  tableRows: TapesUploadedTableFile[] | undefined;
  inProgressFiles?: InProgressFile[];
  error?: ApolloError;
};

const TapesUploadedTable: React.FC<Props> = ({
  tableRows,
  inProgressFiles,
  error,
}) => {
  const { tapeId } = useParams<{ tapeId: string }>();

  if (error) {
    console.error('Error fetching loan uploads', error);
    return <div>Error fetching data</div>;
  } else if (!tableRows) {
    return <div>Loading...</div>;
  }

  return (
    <StyledTableWrapper>
      {inProgressFiles?.map((row) => (
        <TableRowContainer
          key={row.id ?? row.name}
          isSelected={false}
          isSelectable={false}
          isSaved={false}
          as="div"
        >
          <FilenameCell>{row.name}</FilenameCell>
          <RowsCell></RowsCell>
          <UploadingCell progress={row.progress?.percentage} />
          <RowsCell></RowsCell>
          <RowsCell></RowsCell>
          <RowsCell></RowsCell>
        </TableRowContainer>
      ))}
      {tableRows.map((row) => {
        const processedRows = (row.processingReport.new_count ?? 0)
          + (row.processingReport.updated_count ?? 0);
        const isSelectable =
          row.state === FileState.NEEDS_MAPPING ||
          row.state === FileState.PROCESSED ||
          row.state === FileState.PROCESSING;
        const StateElement = TAPE_STATE_STRINGS[row.state].component;
        return (
          <Link to={`/portfolio/tape-upload/${row.id}`} style={{ 'textDecoration': 'none' }} key={row.id}>
            <TableRowContainer
              key={row.id}
              isSelected={tapeId === row.id}
              isSelectable={isSelectable}
              isSaved={row.state === FileState.PERSISTED}
              as="div"
            >
              <FilenameCell>{row.name}</FilenameCell>
              <StatusCell>
                {row.assetClass}{row.productType === ProductType.ARM ? ' - ' + row.productType : ''}
              </StatusCell>
              <StatusCell>
                <StateElement>{TAPE_STATE_STRINGS[row.state].label}</StateElement>
              </StatusCell>
              <RowsCell>
                {row.uploaded_by.firstName + ' ' + row.uploaded_by.lastName}
              </RowsCell>
              <RowsCell>
                {DateTime.fromISO(row.createdDate).toLocal().toFormat('ff')}
              </RowsCell>
              <RowsCell>
                {row.processingReport.new_count != null ? processedRows + ' rows' : ''}
              </RowsCell>
            </TableRowContainer>
          </Link>
        );
      })}
    </StyledTableWrapper>
  );
};

export type {
  InProgressFile,
};
export default TapesUploadedTable;
export { TapesUploadedTableFragments };
