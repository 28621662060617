// TODO generate this config at build time by reading values from from env variables or buildspec
// calling out to the orsnn-app-api-prod stack to get the values
const config = {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://upbv4kmwv9.execute-api.us-west-2.amazonaws.com/prod/graphql',
  },
  logs: {
    REGION: 'us-west-2',
    URL: 'https://upbv4kmwv9.execute-api.us-west-2.amazonaws.com/prod/log',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_FxCZnbS1k',
    APP_CLIENT_ID: '1couu8up2h3o5sfqtc1luij67g',
    IDENTITY_POOL_ID: 'us-west-2:d25996ad-0aaa-4e8f-a415-7ec3d188b9b0',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://upbv4kmwv9.execute-api.us-west-2.amazonaws.com/prod/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://upbv4kmwv9.execute-api.us-west-2.amazonaws.com/prod/smart_header_mapping',
  },
  website: {
    URL: 'https://app.orsnn.com',
  },
  pinpoint: {
    REGION: 'us-west-2',
    APP_ID: '96c0700c2c6043a79532a557bceb3fa3',
  },
  features: {
  },
} as const;

export default config;
