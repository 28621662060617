import React from 'react';
import { IconTrash, IconFileTypePdf, IconInfoCircle } from '@tabler/icons-react';
import { BaseButton, IndeterminateCheckbox } from 'common-ui';
import DownloadLink from 'ui-kit/DownloadLink/DownloadLink';
import { Tooltip } from 'react-tooltip';
import {
  GetContractsForDeal_getContractsForDeal as Contract,
} from 'query/__generated__/GetContractsForDeal';
import { SettlementCard_SettlementCard } from './__generated__/SettlementCard';
import { DocumentStatus, getDocumentStatus } from './settlement_utils';

interface DocumentRowProps {
  document: Contract;
  role: SettlementCard_SettlementCard['role'];
  selectedDocumentId: string | null;
  onRowClick: (contractId: string) => void;
  onDownload: (contractId: string, contractVersion: string, error?: string | null) => void;
  onDelete: (contractId: string, contractVersion: string) => void;
  onCheckboxChange: (id: string, contractVersion: string, isReady: boolean) => void;
}

const DocumentRow: React.FC<DocumentRowProps> = ({
  document,
  role,
  selectedDocumentId,
  onRowClick,
  onDownload,
  onDelete,
  onCheckboxChange
}) => {
  const { buyerSigned, sellerSigned, readyToSignBuyer, readyToSignSeller, sellerReferenceText, buyerReferenceText } = document.data;

  const { status, canDeleteOrSelect, showCheckbox } = getDocumentStatus({
    buyerSigned,
    sellerSigned,
    readyToSignBuyer,
    readyToSignSeller,
    role,
    sellerReferenceText,
    buyerReferenceText,
    error: document.contractSigningError,
  });

  return (
    <div
      className={`flex justify-between items-center py-2 border-t cursor-pointer hover:bg-slate-500 ${selectedDocumentId === document.contractId ? 'bg-slate-700' : ''}`}
      onClick={() => onRowClick(document.contractId)}
      style={{ transition: 'background-color 0.3s' }}
    >
      <div className="w-[35%] text-slate-200 pl-2">{document.originalFileName}</div>
      <div className="w-[30%] text-left text-slate-200 flex items-center">
        <span className={`${status === DocumentStatus.FAILED_TO_SIGN ? 'text-red-400' : 'text-yellow-500'} text-xxs`}>{status}</span>
        {status === DocumentStatus.FAILED_TO_SIGN && (
          <>
            <IconInfoCircle stroke={2} size={18} className="ml-2 text-red-400" data-tooltip-id="error" />
            <Tooltip id="error" className="max-w-[300px]" place="left">
              <p>Contract signing error - our engineers were notified and will fix it or reach out shortly.</p>
            </Tooltip>
          </>
        )}
      </div>
      <div className="w-[10%] flex justify-end gap-3">
        <DownloadLink
          icon={<IconFileTypePdf size={16} />}
          text="Download"
          onClick={() => onDownload(document.contractId, document.contractVersion, document.contractSigningError)}
          className="text-slate-200"
        />
      </div>
      <div className="w-[10%] flex justify-end items-center">
        <IconInfoCircle stroke={2} size={18} className="mr-2" data-tooltip-id="info"/>
        <IndeterminateCheckbox
          checked={role === 'BUYER' ? !!readyToSignBuyer : !!readyToSignSeller}
          onChange={() => onCheckboxChange(document.contractId, document.contractVersion, role === 'BUYER' ? !!readyToSignBuyer : !!readyToSignSeller)}
          disabled={!showCheckbox}
        />
        <Tooltip id="info" className="max-w-[300px]" place="left">
          <p>Checking this box confirms that the document is ready to be signed.</p>
        </Tooltip>
      </div>
      <div className="w-[15%] flex justify-end items-center pr-2">
        {
          role === 'SELLER' &&
          <BaseButton
            label="Delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(document.contractId, document.contractVersion);
            }}
            disabled={!canDeleteOrSelect}
            className="flex items-center px-2"
          >
            <IconTrash
              size={20}
              className='text-slate-200 hover:fill-none'
            />
            <span className="pl-2 text-slate-200">Delete</span>
          </BaseButton>
        }
      </div>
    </div>
  );
};

export default DocumentRow;
