import { useState } from 'react';
import classNames from 'classnames';

interface ToggleButtonProps {
  initialState?: boolean;
  disabled?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  onChange?: (checked: boolean) => void;
}

export function ToggleButton({ initialState, disabled, checkedLabel, uncheckedLabel, onChange }: ToggleButtonProps) {
  const [checked, setChecked] = useState(initialState || false);

  return (
    <div className="flex items-center gap-2">
      <label
        className={classNames(
          'relative cursor-pointer w-10 h-5 rounded-full transition-all duration-300 border',
          checked ? 'bg-accent-default border-accent-default' : 'bg-accent-subtle border-pink-50',
          disabled ? 'cursor-not-allowed bg-accent-muted' : 'cursor-pointer'
        )}
      >
        <input
          type="checkbox"
          disabled={!!disabled}
          defaultChecked={!!checked}
          onChange={() => {
            setChecked(!checked);
            onChange && onChange(!checked);
          }}
          className="hidden"
        />
        <span
          className={classNames(
            'absolute top-[3px] w-3 h-3 rounded-full transition-all duration-300',
            checked ? 'left-[calc(100%-4px)] transform -translate-x-full bg-accent-subtle' : 'left-[4px] bg-accent-default',
          )}
        ></span>
      </label>
      {checked && checkedLabel && <span className="text-foreground-default text-xs font-medium">{checkedLabel}</span>}
      {!checked && uncheckedLabel && <span className="text-foreground-default text-xs font-medium">{uncheckedLabel}</span>}
    </div>
  );
}
