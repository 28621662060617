import React, { useEffect, useState, useRef } from 'react';
import BidSummary, { BidSummaryInfo } from './BidSummary';
import YieldToPriceChart from './YieldToPriceChart';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { CashFlowModelingInputs } from 'features/drilldown/cashflows/CashFlowModelingInputs';
import { URLMapDeprecated } from 'features/pages/market-data/shared';
import { useQuery } from '@apollo/client';
import { GetMarketData } from 'query/__generated__/GetMarketData';
import { GET_MARKET_DATA } from 'query/getMarketData';
import { basisPointsToPercent } from 'features/deals/DealStages/EventActionCards/utils';
import { RateType } from '__generated__/globalTypes';
import { ChartSkeletonLoader } from 'features/pages/market-data/components/ChartSkeleton';

const selectCurveOptions = [
  {
    label: 'Treasury',
    value: RateType.RiskFree,
  },
  {
    label: 'SOFR',
    value: RateType.Sofr,
  },
];

interface YieldCurveTableProps {
  data: {
    labels: string[];
    series: {
      [key: string]: number[];
    };
  };
}

interface MaturityDataProps {
  ycChartData: YieldCurveTableProps['data'] | null;
  setYcChartDataUrl: React.Dispatch<React.SetStateAction<string>>;
}

const MaturityData: React.FC<MaturityDataProps> = ({ ycChartData, setYcChartDataUrl }) => {
  const { cashFlowsDataDisplayProps: { formValues, onSubmit } } = useBiddingPricingContext();
  return (
    <div className='flex items-center justify-between mb-3'>
      {ycChartData && <YieldCurveTable data={ycChartData} />}
      <div className='flex items-center gap-2'>
        Select Curve&nbsp;
        <Dropdown
          onChange={(option) => {
            if (option != null) {
              if (option.value === RateType.Sofr) {
                setYcChartDataUrl('sofr_yc_chart_data.json');
              } else {
                setYcChartDataUrl('yc_chart_data.json');
              }

              onSubmit({ ...formValues, rateType: option.value });
            }
          }}
          defaultValue={selectCurveOptions.find(option => option.value === (formValues.rateType || RateType.Sofr))}
          isMulti={false}
          options={selectCurveOptions}
        />
      </div>
    </div>
  );
};

const YieldCurveTable: React.FC<YieldCurveTableProps> = ({ data }) => {
  const latestDataLabels = ['3 Mo', '6 Mo', '1 Yr', '2 Yr', '5 Yr', '10 Yr', '30 Yr'];
  const latestDataSeries = latestDataLabels.map(label => {
    const index = data.labels.indexOf(label);
    return {
      label,
      value: data.series.Last[index]
    };
  });

  return (
    <div className='flex flex-wrap gap-2 p-2' style={{ background: 'rgba(38, 6, 38, 1)', borderRadius: '2px' }}>
      {latestDataSeries.map((dataPoint, index) => (
        <div key={index} className='flex items-center gap-2 px-4 py-1' style={{ background: 'rgba(38, 6, 38, 1)', borderRadius: '2px' }}>
          <span className='text-xs' style={{ color: 'rgba(158, 158, 158, 1)' }}>{dataPoint.label}</span>
          <span className='text-xs font-bold' style={{ color: 'rgba(242, 242, 242, 1)' }}>{dataPoint.value ? dataPoint.value.toFixed(2) : ''}%</span>
        </div>
      ))}
    </div>
  );
};

const YieldMatrix = () => {
  const { selectedCarve, bidDetails, updateBidDetails, cashFlowsDataDisplayProps: { formValues, useSummaryData, onSubmit } } = useBiddingPricingContext();
  const [ycChartData, setYcChartData] = useState(null);
  const { data } = useSummaryData(formValues);

  const chartParentRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(800);

  const [ycChartDataMap, setYcChartDataMap] = useState<URLMapDeprecated>({} as URLMapDeprecated);
  const [ycChartDataUrl, setYcChartDataUrl] = useState('sofr_yc_chart_data.json');
  const { data: marketData } = useQuery<GetMarketData>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (marketData && marketData.marketData) {
      const urlMap: URLMapDeprecated = {};
      Object.entries(marketData.marketData.urlMap).forEach(([key, value]) => {
        urlMap[key] = value;
      });
      setYcChartDataMap(urlMap);
    }
  }, [marketData])

  useEffect(() => {
    if (ycChartDataUrl) {
      fetch(ycChartDataMap[ycChartDataUrl]).then((response) => {
        response.json().then((data) => {
          if (ycChartDataUrl === 'sofr_yc_chart_data.json') {
            const transformedData = {
              ...data,
              labels: [
                '1 Mo', '2 Mo', '3 Mo', '6 Mo', '1 Yr', '2 Yr', '3 Yr', '5 Yr', '10 Yr', '20 Yr', '30 Yr'
              ]
            };
            setYcChartData(transformedData);
          } else {
            setYcChartData(data);
          }
        });
      });
    }
  }, [ycChartDataUrl, ycChartDataMap]);

  const carveSummary = selectedCarve?.carve_summary;

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartParentRef.current) {
        const parentWidth = chartParentRef.current.offsetWidth;
        setChartWidth(parentWidth - 310);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => {
      window.removeEventListener('resize', updateChartWidth);
    };
  }, [chartParentRef]);

  if (!bidDetails) return null;
  if (!carveSummary) return null;

  const bidSummary: BidSummaryInfo = {
    purchaseDollarPrice: 0,
  };

  const bidBasisPoints = bidDetails.carve.stipulations?.bid_basis_points || 0;

  if (data != null && 'price_yield_matrix' in data.cashFlows && data.cashFlows.price_yield_matrix != null) {
    const priceYieldMatrix = data.cashFlows.price_yield_matrix;
    const bidPrice = parseFloat(basisPointsToPercent(bidBasisPoints)) / 100;
    const purchasePriceCents = bidPrice * carveSummary.unpaidBalance;
    bidSummary.purchaseDollarPrice = purchasePriceCents;
    if (isWithinOrderedMatrix(purchasePriceCents, priceYieldMatrix.spread_matrix)) {
      const spreadMatrix = priceYieldMatrix.spread_matrix;
      const spreadData = binarySearchMatrix(spreadMatrix, purchasePriceCents);
      bidSummary.spread = spreadData.rate * 10_000;
      bidSummary.spreadDuration = spreadData.duration;
    }
    if (isWithinOrderedMatrix(purchasePriceCents, priceYieldMatrix.yield_matrix)) {
      const yieldMatrix = priceYieldMatrix.yield_matrix;
      const yieldData = binarySearchMatrix(yieldMatrix, purchasePriceCents);
      bidSummary.netYield = yieldData.rate * 100;
      bidSummary.yieldDuration = yieldData.duration;
    }
  }

  return (
    <>
      <div
        style={{
          paddingBottom: 15,
          gap: 40,
          display: 'flex',
        }}
      >
        <CashFlowModelingInputs formValues={formValues} onSubmit={onSubmit} withServicingRate />
      </div>

      <MaturityData ycChartData={ycChartData} setYcChartDataUrl={setYcChartDataUrl} />

      <div className='flex gap-8 flex-col md:flex-row' ref={chartParentRef}>
        <div style={{ width: `${chartWidth}px` }} className='w-full'>
          {data != null && 'price_yield_matrix' in data.cashFlows ? (
            <YieldToPriceChart
              chartData={data?.cashFlows.price_yield_matrix}
              bidDetails={bidDetails}
              updateBidDetails={updateBidDetails}
              upb={carveSummary.unpaidBalance}
              width={chartWidth - 25}
            />
          ) : (
            <ChartSkeletonLoader height={500} width={chartWidth - 25} />
          )}
        </div>

        <div className='min-w-[300px] pr-4'>
          <BidSummary carve={carveSummary} bidSummary={bidSummary} />
        </div>
      </div>
    </>
  );
};

// This matrix is ordered by the purchase price in the 2nd column and is in decreasing order
function isWithinOrderedMatrix(value: number, matrix: number[][] | null): matrix is number[][] {
  if (matrix == null) {
    return false;
  }
  return value <= matrix[0][1] && value >= matrix[matrix.length - 1][1];
}

type MatrixResult = {
  rate: number;
  duration: number;
};

function getValue(matrixPoint: number[]): number {
  return matrixPoint[1];
}

function getRate(matrixPoint: number[]): number {
  return matrixPoint[0];
}

function getDuration(matrixPoint: number[]): number {
  return matrixPoint[2];
}

// Finds the closest value in the matrix to the value
// The matrix is ordered by the purchase price in the 2nd column and is in decreasing order
// The value returned is the first column of the matrix
function binarySearchMatrix(matrix: number[][], value: number): MatrixResult {
  let left = 0;
  let right = matrix.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midValue = getValue(matrix[mid]);

    if (midValue === value) {
      return {
        rate: getRate(matrix[mid]),
        duration: getDuration(matrix[mid]),
      };
    } else if (midValue < value) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  // When the loop exits, left is the index of the closest value less than the target
  // and right is the index of the closest value greater than the target
  if (left >= matrix.length) {
    return {
      rate: getRate(matrix[right]),
      duration: getDuration(matrix[right]),
    };
  }
  if (right < 0) {
    return {
      rate: getRate(matrix[left]),
      duration: getDuration(matrix[left]),
    };
  }

  const leftValue = getValue(matrix[left]);
  const leftDuration = getDuration(matrix[left]);
  const rightValue = getValue(matrix[right]);
  const rightDuration = getDuration(matrix[right]);
  const leftData = getRate(matrix[left]);
  const rightData = getRate(matrix[right]);

  if (leftValue === rightValue) {
    return {
      rate: leftData,
      duration: leftDuration,
    };
  }

  // Perform linear interpolation
  const weightLeft = (rightValue - value) / (rightValue - leftValue);
  const weightRight = (value - leftValue) / (rightValue - leftValue);

  return {
    rate: leftData * weightLeft + rightData * weightRight,
    duration: leftDuration * weightLeft + rightDuration * weightRight,
  };
}

export default YieldMatrix;
