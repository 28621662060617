import { useState, useCallback, useEffect, useMemo } from 'react';
import { AssetClass, FileType, ProductType } from '__generated__/globalTypes';
import { Card, CardHeading, CardInstructions } from '../styles';
import { Button } from 'baseui/button';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import {
  AssetClassOptionType,
} from './AssetClassOptionType';
import { useParams } from 'react-router-dom';
import { FileDropZone } from 'features/common-elements/FileDropZone';
import { useS3Uploader } from 'features/common-elements/useS3Uploader';
import UploadHistory, { InProgressFile } from '../components/UploadHistory';
import { ChevronDoubleDown } from '@styled-icons/bootstrap/ChevronDoubleDown';
import { isUnsecEnabled } from 'features/flags';


const ENABLED_ASSET_CLASSES = [
  AssetClass.HOME,
  AssetClass.AUTO,
  ...(isUnsecEnabled() ? [AssetClass.UNSECPERSONAL] : []),
];

export const assetClassOptions: AssetClassOptionType[] = Object.values(
  AssetClass
).map((assetClass) => ({
  value: assetClass,
  label: assetClass.toString(),
}));


function useAssetClassAndProductType() {
  const [selectedAssetClass, setSelectedAssetClass] = useState<AssetClass>();
  const [productType, setProductType] = useState<ProductType>(ProductType.FIXED);

  const handleAssetClassSelect = useCallback((selectedOption) => {
    setSelectedAssetClass(selectedOption?.value);
  }, []);

  const handleProductTypeSelect = useCallback((selectedOption) => {
    setProductType(selectedOption?.value);
  }, []);

  useEffect(() => {
    setProductType(ProductType.FIXED);
  }, [selectedAssetClass]);

  return {
    selectedAssetClass, handleAssetClassSelect,
    productType, handleProductTypeSelect
  };
}

export interface LoanTapeUploaderProps {
  onClickNext?: () => void;
}

const ALLOWED_MIME_TYPES = [
  'application/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-csv',
  'text/comma-separated-values',
  'text/csv',
  'text/tab-separated-values',
  'text/x-comma-separated-values',
  'text/x-csv',
];

export default function LoanTapeUploader(props: LoanTapeUploaderProps) {
  const [uploadsInProgress, setUploadsInProgress] = useState<InProgressFile[]>([]);

  const { tapeId } = useParams<{ tapeId: string }>();

  const {
    selectedAssetClass,
    handleAssetClassSelect,
    productType,
    handleProductTypeSelect,
  } = useAssetClassAndProductType();

  const onSuccess = useCallback((file: File, _versionId?: string, uuid?: string) => {
    setUploadsInProgress((prev) => {
      const existingFile = prev.find((f) => f.name === file.name && f.lastModified === file.lastModified);
      if (existingFile) {
        existingFile.id = uuid;
      }
      return [...prev];
    });
  }, [setUploadsInProgress]);

  const onProgress = useCallback((progress: number, file?: File) => {
    if (!file) {
      return;
    }
    setUploadsInProgress((prev) => {
      const existingFile = prev.find((f) => f.name === file.name && f.lastModified === file.lastModified);
      if (existingFile) {
        existingFile.progress = {
          percentage: progress,
        };
      } else {
        prev.push({
          name: file.name,
          lastModified: file.lastModified,
          progress: {
            percentage: progress,
          },
        });
      }
      return [...prev];
    });
  }, [setUploadsInProgress]);

  const { upload } = useS3Uploader({
    fileType: FileType.LOAN_TAPE,
    onSuccess,
    onProgress,
    assetClass: selectedAssetClass,
    productType,
    allowedMimeTypes: ALLOWED_MIME_TYPES,
  });

  const onFileDrop = async (file: File) => {
    try {
      await upload(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const {
    onClickNext = Function.prototype,
  } = props;

  const onClickNextButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClickNext();
    },
    [onClickNext]
  );

  const productTypeSelector = selectedAssetClass === AssetClass.HOME && (
    <Dropdown
      isMulti={false}
      options={Object.values(ProductType).map((productType) => ({
        value: productType,
        label: productType.toString(),
      }))}
      onChange={handleProductTypeSelect}
      value={{ value: productType, label: productType.toString() }}
    />
  );

  const shouldShowDropSection = selectedAssetClass && (selectedAssetClass === AssetClass.HOME ? productType : true);

  const filteredAssetClassOptions = useMemo(() => {
    return assetClassOptions.map((option) => ({
      ...option,
      isDisabled: !ENABLED_ASSET_CLASSES.includes(option.value),
    })).sort((a, b) => {
      if (a.isDisabled && !b.isDisabled) {
        return 1;
      }
      if (!a.isDisabled && b.isDisabled) {
        return -1;
      }
      return 0;
    });
  }, []);

  const selectedOption = filteredAssetClassOptions.find(
    (option) => option.value === selectedAssetClass
  );

  return (
    <Card>
      <CardHeading>Upload Your Tapes</CardHeading>
      <CardInstructions>
        Upload one or all of your loan tapes for evaluation. Supported file types include .csv, .xls, and .xlsx.
      </CardInstructions>
      <div className="flex-col">
        <div className='flex-1'>
          <Dropdown
            placeholder='Select asset class for this upload...'
            isMulti={false}
            options={filteredAssetClassOptions}
            onChange={handleAssetClassSelect}
            value={selectedOption || null}
          />
          {productTypeSelector}
          <div className="h-[165px] my-2 z-0 block text-[14px] text-white overflow-hidden">
            {shouldShowDropSection && (<FileDropZone onFileDrop={onFileDrop} />)}
          </div>
        </div>
      </div>
      <UploadHistory uploadsInProgress={uploadsInProgress} />
      <div className='flex justify-center'>
        <Button
          size="compact"
          disabled={!tapeId}
          onClick={onClickNextButton}
        >
          <ChevronDoubleDown size={20} className='mr-1' />
          Map File Columns to Fields
        </Button>
      </div>
    </Card>
  );
}
