import React, { ElementType, ReactElement, ReactText } from 'react';
import { DropdownMenuItem } from 'common-ui';
import {
  IconUser,
  IconLock,
  IconShieldCheck,
  IconCheck,
  IconMail,
  IconHelpCircle,
  IconChevronDown,
} from '@tabler/icons-react';
import User from './User';
import Security from './Security';
import Permissions from './Permissions';
import EmailOptInPrefs from './EmailOptInPrefs';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import AppPrefs from './AppPrefs';
import './Account.scss';

type IconName = 'user' | 'lock' | 'shield-check' | 'check-mark' | 'email-opt-in' | 'question-mark';

const iconMapping: Record<IconName, React.ElementType> = {
  user: IconUser,
  lock: IconLock,
  'shield-check': IconShieldCheck,
  'check-mark': IconCheck,
  'email-opt-in': IconMail,
  'question-mark': IconHelpCircle,
};

const menuItems = [
  {
    label: 'User',
    iconName: 'user' as IconName,
    id: 'user',
    content: <User />,
  },
  {
    label: 'Security',
    iconName: 'lock' as IconName,
    id: 'security',
    content: <Security />,
  },
  {
    label: 'User Role & Permissions',
    id: 'permissions',
    iconName: 'shield-check' as IconName,
    content: <Permissions />,
    adminOnly: true,
  },
  {
    label: 'Application Preferences',
    id: 'preferences',
    iconName: 'check-mark' as IconName,
    content: <AppPrefs />,
  },
  {
    label: 'Email Preferences',
    id: 'email-preferences',
    iconName: 'email-opt-in' as IconName,
    content: <EmailOptInPrefs />,
  },
  {
    label: 'Support',
    id: 'support',
    iconName: 'question-mark' as IconName,
  },
];

export interface AccordionItemProps {
  title: string;
  iconName: IconName;
  children?: ReactElement | ReactText;
  expanded?: boolean;
  onChange: () => void;
  icon?: ReactElement;
}

const AccordionItem = ({
  iconName,
  title,
  children,
  expanded = true,
  onChange,
  icon,
}: AccordionItemProps) => {
  const IconComponent: ElementType | ReactElement = icon || iconMapping[iconName] || IconHelpCircle;

  return (
    <button className={classNames('account__accordion__accordion-item', expanded ? 'expanded' : '')}>
      <header className="account__accordion__header" onClick={onChange}>
        <div className="account__accordion__icon">
          {React.isValidElement(IconComponent) ? (
            IconComponent
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <IconComponent size={20} />
          )}
        </div>
        <span>{title}</span>
        <div className="account__accordion__spacer" />
        <div className={classNames('account__accordion__arrow', expanded ? '' : 'collapsed')}>
          <IconChevronDown size={20} />
        </div>
      </header>
      {expanded && <div>{children}</div>}
    </button>
  );
};

const Account = () => {
  let selected = 0;
  const [search, setSearch] = useSearchParams();
  const sectionFocus = search.get('section');

  if (sectionFocus) {
    const idx = menuItems.findIndex((m) => m.id === sectionFocus);
    if (idx >= 0 && selected !== idx) {
      selected = idx;
    }
  }

  return (
    <div className="account">
      <header className="account__header">User Profile</header>
      <div className="account__container">
        <section className="menu">
          {menuItems.map((m, index) => {
            const IconComponent = iconMapping[m.iconName] || IconHelpCircle;
            return (
              <DropdownMenuItem
                key={m.label}
                label={m.label}
                icon={<IconComponent size={20} />}
                active={index === selected}
                onClick={() => {
                  setSearch(new URLSearchParams([['section', m.id || '']]));
                }}
              />
            );
          })}
        </section>
        <section className="main">
          {menuItems.map((m, index) => (
            <AccordionItem
              key={m.label}
              title={m.label}
              iconName={m.iconName}
              expanded={index === selected}
              onChange={() => {
                setSearch(new URLSearchParams([['section', m.id || '']]));
              }}
            >
              {m.content}
            </AccordionItem>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Account;
