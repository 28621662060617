import { FC } from 'react';

export const KPISkeleton: FC = () => {
  return (
    <div className='bg-kip-gradient px-2 flex flex-col gap-1 py-2 rounded text-center min-w-36 animate-pulse'>
      <div className="bg-slate-700 h-4 w-16 mb-2 rounded"></div>
      <div className="bg-slate-700 h-4 w-20 rounded"></div>
    </div>
  );
};
