import {
  FileDiligenceStatus,
  FileStatusDropdown,
  fromCollateralStatus,
} from './FileStatus';
import { RadioButton } from 'common-ui/form/RadioButtonField';
import AddNoteIcon from './AddNoteIcon';
import { GetDealDiligence_deal_diligence_collateral as Collateral } from 'query/__generated__/GetDealDiligence';
import { DealRole } from '__generated__/globalTypes';

interface DocumentsTableProps {
  role: DealRole;
  collateral?: Collateral[];
  selectedDocId?: string;
  onDocSelected: (docId: string) => void;
  handleNoteClick: (docId: string) => void;
  onFileStatusSelect?: (dealId: string, loanId: string, fileName: string, status: FileDiligenceStatus) => void;
  hasNote: (docId: string) => boolean;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  role,
  collateral,
  selectedDocId,
  onDocSelected,
  handleNoteClick,
  onFileStatusSelect,
  hasNote,
}) => {
  return (
    <div className="h-60 overflow-y-auto bg-slate-900 border border-gray-900 rounded-md">
      {collateral && collateral.length === 0 && <div>No documents found</div>}
      {collateral && collateral.length > 0 && (
        <table className="w-full text-slate-400 border-collapse">
          <thead className="text-left font-bold">
            <tr>
              <th></th>
              <th></th>
              <th>Index</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {collateral.map((doc, idx) => {
              const isSelected = selectedDocId === doc.collateralID;
              return (
                <tr
                  key={doc.collateralID}
                  aria-selected={isSelected ? 'true' : 'false'}
                  className={`${
                    isSelected ? 'bg-purple-500 text-white' : 'bg-transparent'
                  } hover:bg-purple-700 even:bg-slate-800 cursor-pointer`}
                  onClick={() => onDocSelected(doc.collateralID)}
                >
                  <td className="w-8 pl-2">
                    <RadioButton
                      label=""
                      name=""
                      value=""
                      checked={isSelected}
                      onChange={() => onDocSelected(doc.collateralID)}
                    />
                  </td>
                  <td>
                    {hasNote(doc.collateralID) && (
                      <AddNoteIcon
                        onNoteClick={() => handleNoteClick(doc.collateralID)}
                        noteStatus={doc.note_status}
                      />
                    )}
                  </td>
                  <td>{idx + 1}</td>
                  <td>
                    <span className="text-slate-50">{doc.fileName}</span>
                  </td>
                  <td className="w-56 relative">
                    {role === DealRole.BUYER && (
                      <FileStatusDropdown
                        value={doc.collateral_status}
                        onChange={(option) =>
                          onFileStatusSelect &&
                          onFileStatusSelect(doc.dealID, doc.loanID, doc.fileName, option)
                        }
                        isInSelectedRow={isSelected}
                      />
                    )}
                    {role === DealRole.SELLER && (
                      <div>{fromCollateralStatus(doc.collateral_status)}</div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DocumentsTable;
