import React, { useState } from 'react';
import {
  CarveCardContainer,
  CarveDetailsContainer,
  CarveDetailsRow,
  CarveDetailsLabel,
  CarveDetailsValue,
  CarveSubheader,
  CarveName,
} from 'ui-kit';
import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import { formatCentsToDollars } from 'features/pages/marketplace/BiddingAndPricing/formatting';
import { IconCircleCheck, IconDeviceIpadDown } from '@tabler/icons-react';
import { ToggleButton } from 'common-ui/bool-inputs/ToggleButton';
import { BaseButton } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { MarkDiligenceComplete, MarkDiligenceCompleteVariables } from 'mutation/__generated__/MarkDiligenceComplete';
import { useMutation } from '@apollo/client';
import { MARK_DILIGENCE_COMPLETE } from 'mutation/markDiligenceComplete';
import { DealRole } from '__generated__/globalTypes';

type DiligenceData = {
  label: string;
  pre: string | number;
  post: string | number;
  base?: string | number;
};

const CONFIG: Record<string, string> = {
  loan_count: '# LOANS',
  current_balance_cents: 'C BAL',
  original_balance_cents: 'O BAL',
  wa_age_months: 'WALA',
  wa_remaining_loan_terms_months: 'WAM',
  wa_coupon: 'GWAC',
  wa_borrower_credit_score: 'FICO',
  wa_ltv: 'LTV',
  wa_dti: 'DTI',
};

type DiligencePreVsPostProps = {
  originalPoolSummary: DiligenceCard_DiligenceCard['original_pool_summary'];
  acceptedBidSummary: DiligenceCard_DiligenceCard['accepted_bid_summary'];
  postDiligenceSummary: DiligenceCard_DiligenceCard['post_diligence_summary'];
  diligenceReports: DiligenceCard_DiligenceCard['diligence_reports'];
  is_complete?: boolean;
  dealId: string;
  buyerAcceptedDiligence: boolean;
  sellerAcceptedDiligence: boolean;
  role: DealRole;
};

const DiligencePreVsPost: React.FC<DiligencePreVsPostProps> = ({
  originalPoolSummary,
  acceptedBidSummary,
  postDiligenceSummary,
  diligenceReports,
  is_complete,
  dealId,
  buyerAcceptedDiligence,
  sellerAcceptedDiligence,
  role,
}) => {
  const isSeller = role === DealRole.SELLER;
  const initialToggleState = isSeller ? sellerAcceptedDiligence : buyerAcceptedDiligence;

  const [isChecked, setIsChecked] = useState(is_complete || initialToggleState);
  const [isMarkingComplete, setIsMarkingComplete] = useState<boolean>(is_complete || false);

  const acceptanceStatus = (() => {
    if (isSeller) {
      return buyerAcceptedDiligence
        ? 'Other party has accepted'
        : 'Other party has yet to accept';
    } else {
      return sellerAcceptedDiligence
        ? 'Other party has accepted'
        : 'Other party has yet to accept';
    }
  })();
  const statusColor = acceptanceStatus === 'Other party has accepted' ? 'green-400' : 'yellow-400';

  const markCompleteDialog = useDialog();
  const [markDiligenceComplete] = useMutation<
    MarkDiligenceComplete,
    MarkDiligenceCompleteVariables
  >(MARK_DILIGENCE_COMPLETE, {
    onCompleted: () => {
      markCompleteDialog.closeDialog();
      setIsMarkingComplete(false);
    },
  });

  const handleToggleChange = (state: boolean) => {
    setIsChecked(state);
  };

  const handleMarkCompleteClick = () => {
    setIsMarkingComplete(true);
    const input = {
      variables: {
        input: {
          deal_id: dealId,
        },
      },
    };
    markDiligenceComplete(input);
  };

  const data: DiligenceData[] = [
    {
      label: CONFIG.loan_count,
      base: originalPoolSummary?.loan_count.toLocaleString(),
      pre: acceptedBidSummary?.loan_count.toLocaleString() || '',
      post: postDiligenceSummary?.loan_count.toLocaleString() || '',
    },
    {
      label: CONFIG.current_balance_cents,
      base: formatCentsToDollars(Number(originalPoolSummary?.current_balance_cents)),
      pre: formatCentsToDollars(Number(acceptedBidSummary?.current_balance_cents)),
      post: formatCentsToDollars(Number(postDiligenceSummary?.current_balance_cents)),
    },
    {
      label: CONFIG.original_balance_cents,
      base: formatCentsToDollars(Number(originalPoolSummary?.original_balance_cents)),
      pre: formatCentsToDollars(Number(acceptedBidSummary?.original_balance_cents)),
      post: formatCentsToDollars(Number(postDiligenceSummary?.original_balance_cents)),
    },
    {
      label: CONFIG.wa_age_months,
      base: originalPoolSummary?.wa_age_months.toLocaleString(),
      pre: acceptedBidSummary?.wa_age_months.toLocaleString() || '',
      post: postDiligenceSummary?.wa_age_months.toLocaleString() || '',
    },
    {
      label: CONFIG.wa_remaining_loan_terms_months,
      base: originalPoolSummary?.wa_remaining_loan_terms_months.toLocaleString(),
      pre: acceptedBidSummary?.wa_remaining_loan_terms_months.toLocaleString() || '',
      post: postDiligenceSummary?.wa_remaining_loan_terms_months.toLocaleString() || '',
    },
    {
      label: CONFIG.wa_coupon,
      base: `${((originalPoolSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%`,
      pre: `${((acceptedBidSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%` || '',
      post: `${((postDiligenceSummary?.wa_coupon ?? 0) * 100).toFixed(3)}%` || '',
    },
    {
      label: CONFIG.wa_borrower_credit_score,
      base: originalPoolSummary?.wa_borrower_credit_score.toLocaleString(),
      pre: acceptedBidSummary?.wa_borrower_credit_score.toLocaleString() || '',
      post: postDiligenceSummary?.wa_borrower_credit_score.toLocaleString() || '',
    },
    {
      label: CONFIG.wa_ltv,
      base: `${((originalPoolSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%` || '',
      post: `${((postDiligenceSummary?.wa_ltv ?? 0) * 100).toFixed(2)}%` || '',
    },
    {
      label: CONFIG.wa_dti,
      base: `${((originalPoolSummary?.wa_dti ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary?.wa_dti ?? 0) * 100).toFixed(2)}%` || '',
      post: `${((postDiligenceSummary?.wa_dti ?? 0) * 100).toFixed(2)}%` || '',
    },
  ];

  const renderTable = (title: string, stage: keyof DiligenceData, showLabels: boolean) => (
    <CarveCardContainer highlight={false}>
      <CarveName>{title}</CarveName>
      <CarveSubheader>{showLabels ? 'Pre-Carving and Pre-Diligence' : ''}</CarveSubheader>
      <CarveDetailsContainer>
        {data.map((item, index) => (
          <CarveDetailsRow key={index}>
            {showLabels && <CarveDetailsLabel>{item.label}</CarveDetailsLabel>}
            <CarveDetailsValue fullWidth={!showLabels}>{item[stage]}</CarveDetailsValue>
          </CarveDetailsRow>
        ))}
      </CarveDetailsContainer>
    </CarveCardContainer>
  );

  return (
    <div>
      {
        !!is_complete &&
        <div className="flex justify-between bg-slate-600 rounded-md border border-slate-400 px-4 py-2">
          <div>
            <p className="text-white text-sm">NEXT: Click that you agree and accept the following parameters, below.</p>
            <div className="flex gap-4 mt-2 items-center">
              <div className='flex gap-2'>
                <ToggleButton
                  initialState={isChecked}
                  onChange={handleToggleChange}
                  disabled={is_complete}
                />
                <span className={`text-${isChecked ? 'green-400' : 'accent-default'}`}>
                  I Agree and Accept
                </span>
                {isChecked && <IconCircleCheck className="green-yellow-400" />}
              </div>
              <p className={`border-l-2 pl-2 text-${statusColor} border-${statusColor} text-xs flex gap-2 items-center`}>
                <span>{acceptanceStatus}</span>
                {acceptanceStatus === 'Other party has accepted' && (
                  <IconCircleCheck className="text-green-400 ml-1" />
                )}
              </p>
            </div>
          </div>
          {
            !isSeller &&
            <div className="flex items-center">
              <BaseButton
                size="medium"
                label="Mark Diligence Review Complete"
                icon="icon-complete"
                isIconRight={true}
                onClick={() => markCompleteDialog.openDialog()}
                disabled={!isChecked}
              >
                Mark Diligence Review Complete
              </BaseButton>
            </div>
          }
        </div>
      }
      <div className="flex gap-4 mt-4">
        {renderTable('Entire Offering', 'base', true)}
        {renderTable('Pre-Diligence', 'pre', false)}
        {renderTable('Post-Diligence', 'post', false)}
      </div>
      <div className="flex gap-4 mt-4">
        {diligenceReports?.rejected_loans_csv_url && (
          <a
            href={diligenceReports.rejected_loans_csv_url}
            download
            className="flex items-center underline px-2 text-accent-default"
          >
            <IconDeviceIpadDown size={20} className='text-slate-200'/>
            <span className="pl-1">Export List of Loans Removed (.CSV)</span>
          </a>
        )}
        {diligenceReports?.post_diligence_loans_csv_url && (
          <a
            href={diligenceReports.post_diligence_loans_csv_url}
            download
            className="flex items-center underline px-2 text-accent-default"
          >
            <IconDeviceIpadDown size={20} className='text-slate-200'/>
            <span className="pl-1">Export Comp (.CSV)</span>
          </a>
        )}
      </div>
      <Dialog dialog={markCompleteDialog}>
        <div>
          <div>
            <div className="modal__header_text">Mark Diligence Complete</div>
            <div className="modal__header_caption">
              By marking this diligence complete, you are confirming that you
              no longer need to review any additional diligence or collateral
              material to transact on this deal.
            </div>
          </div>
          <div className="modal__body">
            <div className="flex justify-between mt-5">
              <BaseButton
                type="primary"
                label="Accept"
                onClick={handleMarkCompleteClick}
                disabled={isMarkingComplete}
              >
                Accept
              </BaseButton>
              <BaseButton
                type="secondary"
                label="Decline"
                onClick={() => markCompleteDialog.closeDialog()}
              >
                Decline
              </BaseButton>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DiligencePreVsPost;
