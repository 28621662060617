import React, { useCallback, useMemo, useRef } from 'react';
import {
  Cell as CellDef,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Header,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { BaseButton, PillButton } from 'common-ui/Buttons';
import { Empty, EmptyAction } from './Empty';
import {
  createCheckBoxSelectionColumn,
  radioButtonSelectionColumn,
} from './selectionColumn';
import {
  Container,
  THead,
  TH,
  TBody,
  EmptyCell,
  TR,
  TD,
  Alignment,
  Table,
  Cell,
  ColumnMeta,
  TableContainer,
  isColumnMeta,
} from './tableStyles';
import PaginationControls from 'common-ui/PaginationControls';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import isEqual from 'react-fast-compare';
import { CSVLink } from 'react-csv';

export interface FlattenedRow {
  id: string;
  loan_count: number;
  wa_borrower_credit_score: number;
  wa_coupon: number;
  wa_loan_age_months: number;
  wa_ltv: number;
  wa_remaining_loan_terms_months: number;
  name: string;
  assetClass: string;
  upb: number;
  wac: number;
  createdDate: string;
  providedName: string;
  is_public?: boolean;
  deal_id: string;
  deal_counterparty_name: string;
  deal_createdDate: string;
  deal_state_status: string;
  deal_state_label: string;
}

function flattenData<T>(rows: Row<WithSubData<T>>[]) {
  return rows.map(row => {
    // Determine if the row itself is a listing or deals need to be accessed
    const listing = row.original.__typename === 'PublicListing' ? row.original : row.original.deals?.[0]?.listing;

    // Extract deal information if available directly or from the deals array
    const deal = row.original.__typename === 'Deal' ? row.original : row.original.deals?.[0];

    return {
      'Loan Pool Name': listing?.providedName || '',
      'Last Viewed': listing?.lastViewed || '',
      'Offered': listing?.offered || '',
      'Pool Status': deal?.state?.label || '',
      'Date Created': deal?.createdDate || listing?.createdDate || '',
      'Counterparty': deal?.counterparty?.name || '',
      'Asset Class': listing?.assetClass || '',
      'UPB': listing?.upb || '',
      '# Loans': listing?.loan_count || '',
      'GWAC': listing?.wa_coupon || '',
      'WALA': listing?.wa_loan_age_months || '',
      'WAM': listing?.wa_remaining_loan_terms_months || '',
      'FICO': listing?.wa_borrower_credit_score || '',
      'LTV': listing?.wa_ltv || ''
    };
  });
}


export type WithSubData<T> = T & {
  last_updated_date: string;
  __typename: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deals?: any;
  subData?: WithSubData<T>[];
};

export type DataDisplayTableStylingOptions = {
  rowColor?: string;
  alternateRowColor?: string;
  subRowColor?: string;
  alternateLightRows?: boolean;
};

export type DataDisplayTableProps<T, V> = {
  data: WithSubData<T>[];
  columns: ColumnDef<WithSubData<T>, V>[];
  noDataMessage: string;
  emptyAction?: EmptyAction;
  stylingOptions?: DataDisplayTableStylingOptions;
  selection?: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
    onHeaderCheckboxChange?: (checked: boolean) => void;
    enableMultiRowSelection?: boolean;
  };
  sorting?: {
    state: SortingState;
    onSortingChanged: OnChangeFn<SortingState>;
    isManual?: boolean;
  };
  getRowId?: (row: WithSubData<T>) => string;
  pagination?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
  downloadCSV?: boolean;
};

function useDeepCompareMemoObject<T>(value: T): T {
  const ref = useRef<T>();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current as T;
}

function _DataDisplayTable<T, V>({
  data,
  columns: propColumns,
  noDataMessage,
  emptyAction,
  stylingOptions: propStylingOptions,
  selection,
  sorting,
  getRowId,
  pagination,
  downloadCSV
}: DataDisplayTableProps<T, V>) {
  const memoizedProps = useDeepCompareMemoObject({
    data,
    propColumns,
    noDataMessage,
    emptyAction,
    propStylingOptions,
    selection,
    sorting,
    getRowId,
    pagination,
  });

  const stylingOptions = useMemo(() => ({
    alternateLightRows: true,
    ...memoizedProps.propStylingOptions,
  }), [memoizedProps.propStylingOptions]);

  const selectionColumn = useMemo(() => {
    if (!memoizedProps.selection) return undefined;

    if (memoizedProps.selection.enableMultiRowSelection === false) {
      return radioButtonSelectionColumn as ColumnDef<WithSubData<T>, V>;
    }

    return createCheckBoxSelectionColumn(
      memoizedProps.selection.onHeaderCheckboxChange || (() => console.log(1))
    ) as ColumnDef<WithSubData<T>, V>;
  }, [memoizedProps.selection]);

  const columns = useMemo(() => {
    const cols: ColumnDef<WithSubData<T>, V>[] = [...memoizedProps.propColumns];
    if (selectionColumn) {
      cols.unshift(selectionColumn);
    }
    return cols;
  }, [selectionColumn, memoizedProps.propColumns]);

  const handleRowSelectionChange: OnChangeFn<RowSelectionState> = useCallback((updaterOrValue) => {
    if (memoizedProps.selection && memoizedProps.selection.onSelectionChange) {
      const newSelection = typeof updaterOrValue === 'function'
        ? updaterOrValue(memoizedProps.selection.selected)
        : updaterOrValue;
      memoizedProps.selection.onSelectionChange(newSelection);
    }
  }, [memoizedProps.selection]);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback((updaterOrValue) => {
    if (memoizedProps.sorting) {
      const newSorting = typeof updaterOrValue === 'function'
        ? updaterOrValue(memoizedProps.sorting.state)
        : updaterOrValue;
      memoizedProps.sorting.onSortingChanged(newSorting);
    }
  }, [memoizedProps.sorting]);

  const [expanded, setExpanded] = React.useState({});


  const table = useReactTable({
    data: memoizedProps.data,
    columns,
    state: {
      expanded,
      rowSelection: memoizedProps.selection?.selected || {},
      sorting: memoizedProps.sorting?.state || [],
    },
    enableRowSelection: !!memoizedProps.selection,
    onRowSelectionChange: handleRowSelectionChange,
    onExpandedChange: setExpanded,
    onSortingChange: handleSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row: WithSubData<T>) => row.subData ?? [],
    enableMultiRowSelection: memoizedProps.selection?.enableMultiRowSelection !== false,
    manualSorting: memoizedProps.sorting?.isManual,
    getRowId: memoizedProps.getRowId,
  });
  const rows = table.getRowModel().rows;
  const selectedRows = useMemo(() => {
    return rows.filter(row => selection?.selected[row.id]);
  }, [rows, selection?.selected]);

  const flattenedData = useMemo(() => {
    return downloadCSV ? flattenData(selectedRows) : [];
  }, [selectedRows, downloadCSV]);

  const renderCell = useCallback((cell: CellDef<WithSubData<T>, unknown>) => {
    const { column } = cell;
    const columnDefMeta = column.columnDef.meta;
    const { alignment,  numeric } = (() => {
      if (isColumnMeta(columnDefMeta)) {
        return {
          alignment: columnDefMeta.alignment || Alignment.left,
          numeric: columnDefMeta.numeric || false,
        };
      }
      return { alignment: Alignment.left, numeric: false };
    })();

    return (
      <TD key={cell.id}>
        <Cell $alignment={alignment} $numeric={numeric}>
          {flexRender(column.columnDef.cell, cell.getContext())}
        </Cell>
      </TD>
    );
  }, []);

  const getRowKey = useCallback((row: Row<WithSubData<T>>) => {
    const originalData = row.original;
    // Create a string that combines the row id and a hash of the row's values
    const dataHash = Object.values(originalData).join('|');
    return `${row.id}-${dataHash}`;
  }, []);

  const renderRow = useCallback(({ row, isLightRow }: { row: Row<WithSubData<T>>; isLightRow: boolean }) => {
    const rowColor = row.depth > 0
      ? stylingOptions.subRowColor
      : isLightRow
        ? stylingOptions.alternateRowColor
        : stylingOptions.rowColor;

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
      if ((event.target as HTMLElement).tagName.toLowerCase() !== 'input') {
        row.getToggleExpandedHandler()();
      }
    };

    return (
      <TR
        key={getRowKey(row)}
        $customBackgroundColor={rowColor}
        $isSubRow={row.depth > 0}
        $isLightRow={isLightRow}
        onClick={handleRowClick}
      >
        {row.getVisibleCells().map(renderCell)}
      </TR>
    );
  }, [renderCell, stylingOptions, getRowKey]);

  const renderHeader = useCallback((header: Header<WithSubData<T>, unknown>) => {
    const column = header.column;
    const columnDefMeta = column.columnDef.meta;
    const { alignment, sortable } = (() => {
      if (isColumnMeta(columnDefMeta)) {
        const alignment = columnDefMeta.alignment || 'left';
        const sortable = column.getCanSort();
        return { alignment, sortable };
      }
      return { alignment: Alignment.left, sortable: false };
    })();

    const iconName = sortable
      ? (column.getIsSorted() || column.getNextSortingOrder()) === 'desc'
          ? 'arrow-up'
          : 'arrow-down'
      : '';

    return (
      <TH
        key={header.id}
        $alignment={alignment}
        $isSortable={sortable}
        onClick={sortable ? column.getToggleSortingHandler() : undefined}
      >
        {!(columnDefMeta != null && 'headerNoPill' in columnDefMeta && columnDefMeta.headerNoPill) ? (
          <div style={{ display: 'flex', justifyContent: alignment === 'left' ? 'flex-start' : alignment === 'right' ? 'flex-end' : 'center' }}>
            <PillButton
              pillBtnType="muted"
              onClick={sortable ? column.getToggleSortingHandler() : undefined}
              description={column.columnDef.header as string}
              isFocused={!!column.getIsSorted()}
              iconName={iconName}
              style={{ marginRight: 0 }}
            />
          </div>
        ) : (
          flexRender(column.columnDef.header, header.getContext())
        )}
      </TH>
    );
  }, []);

  return (
    <Container>
      <TableContainer>
        <div className='flex justify-end mb-2'>
          {downloadCSV && selectedRows.length > 0 && (
            <CSVLink data={flattenedData} filename='active_trades.csv'>
              <BaseButton type='primary' size='small' label='Download CSV'>Download CSV</BaseButton>
            </CSVLink>
          )}
        </div>
        <Table>
          <THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(renderHeader)}
              </tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.length === 0 ? (
              <tr>
                <EmptyCell>
                  <Empty
                    message={memoizedProps.noDataMessage}
                    action={memoizedProps.emptyAction}
                  />
                </EmptyCell>
              </tr>
            ) : (
              table.getRowModel().rows.map((row, index) => renderRow({
                row,
                isLightRow: stylingOptions.alternateLightRows ? index % 2 === 0 : false,
              }))
            )}
          </TBody>
        </Table>
      </TableContainer>
      {memoizedProps.pagination && (
        <PaginationControls
          currentPage={memoizedProps.pagination.queryParams.pageNumber}
          pageSize={memoizedProps.pagination.queryParams.pageSize}
          totalItems={memoizedProps.pagination.totalItems}
          update={(page: number, pageSize: number) => {
            memoizedProps.pagination?.updateParams({ pageNumber: page, pageSize });
          }}
        />
      )}
    </Container>
  );
}


const createDataSignature = (data: WithSubData<unknown>[]): string => {
  return data.map(item => `${item.id}:${item.last_updated_date}`).join('|');
};

const arePropsEqual = (prevProps: DataDisplayTableProps<unknown, unknown>, nextProps: DataDisplayTableProps<unknown, unknown>): boolean => {
  // Compare data signatures
  const prevDataSignature = createDataSignature(prevProps.data);
  const nextDataSignature = createDataSignature(nextProps.data);

  if (prevDataSignature !== nextDataSignature) {
    console.log('DataDisplayTable: data or status changed', prevDataSignature, nextDataSignature);
    return false;
  }

  // Compare columns (shallow comparison of column definitions)
  if (prevProps.columns.length !== nextProps.columns.length ||
      !prevProps.columns.every((col, index) => col.id === nextProps.columns[index].id)) {
    console.log('DataDisplayTable: columns changed');
    return false;
  }

  // Compare selection (shallow comparison)
  if (prevProps.selection?.selected !== nextProps.selection?.selected) {
    console.log('DataDisplayTable: selection changed');
    return false;
  }

  // Compare sorting (shallow comparison)
  if (prevProps.sorting?.state !== nextProps.sorting?.state) {
    console.log('DataDisplayTable: sorting changed');
    return false;
  }

  // Compare pagination (shallow comparison of relevant fields)
  if (prevProps.pagination?.queryParams.pageNumber !== nextProps.pagination?.queryParams.pageNumber ||
      prevProps.pagination?.queryParams.pageSize !== nextProps.pagination?.queryParams.pageSize ||
      prevProps.pagination?.totalItems !== nextProps.pagination?.totalItems) {
    console.log('DataDisplayTable: pagination changed');
    return false;
  }

  // If none of the above checks triggered a re-render, consider the props equal
  return true;
};

export const DataDisplayTable = React.memo(_DataDisplayTable, arePropsEqual);

export type TableEmptyAction = EmptyAction;
export type TableColumnAlignment = Alignment;
export type TableColumnMeta = ColumnMeta;
