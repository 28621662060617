import { PortfolioLayout } from '../Portfolio/PortfolioLayout';
import { useQuery } from '@apollo/client';
import {
  GetPortfolioSummary,
  GetPortfolioSummaryVariables,
} from '../__generated__/GetPortfolioSummary';
import {
  GET_PORTFOLIO_SUMMARY_QUERY,
  kpiConfigs,
} from 'features/pages/portfolio/PortfolioSummary';
import { AssetClass } from '__generated__/globalTypes';
import { GET_LISTINGS } from 'query/getListings';
import { GetListings } from 'query/__generated__/GetListings';
import Dinero from 'dinero.js';
import { isUnsecEnabled } from 'features/flags';
import { FC } from 'react';

const usePortfolioSummary = (assetClass?: AssetClass) => {
  const { data, loading } = useQuery<
    GetPortfolioSummary,
    GetPortfolioSummaryVariables
  >(GET_PORTFOLIO_SUMMARY_QUERY, {
    variables: {
      hasAssetClass: !!assetClass,
      assetClass,
    },
    skip: !isUnsecEnabled() && assetClass === AssetClass.UNSECPERSONAL,
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });
  return { data, loading };
};

// Define the type for KPI configurations
type KpiConfig = {
  description: string;
  selector: string;
  display: (value: number) => string;
};

// Define the type for KPIs with value
type KpiWithValue = KpiConfig & {
  value: string;
};

const mapKpis = (data: GetPortfolioSummary | undefined): KpiWithValue[] => {
  return kpiConfigs.map((kpi) => {
    if (!data) {
      return {
        ...kpi,
        value: '-',
      };
    }

    const performanceData =
      data.user.company.assetClassPerformanceData ||
      data.user.company.performanceData;
    const value = performanceData ? performanceData[kpi.selector] ?? 0 : 0;
    return {
      ...kpi,
      value: kpi.description === 'UPB' ? formatUpb(value) : kpi.display(value),
    };
  });
};

// Rounds down to nearest dollar, as requested by Ralf K.
const formatUpb = (value: number) => {
  const truncatedValue = Math.floor(value);
  return Dinero({
    amount: truncatedValue,
    currency: 'USD',
  }).toFormat('$0,0');
};

const KpiCardContainer = ({ kpis }: { kpis: KpiWithValue[] }) => (
  <div className="flex flex-row gap-4">
    <div className="rounded-lg border border-[#260626] p-2 min-w-48 h-16 text-center flex flex-col justify-center items-center gap-1 bg-[#e028dd] bg-gradient-to-r from-[#1555ec] to-[#ff23e1]">
      <div className='text-xl font-mono text-gray-100'>{kpis[0].value}</div>
      <div>
        {kpis[0].description === '#' ? '# LOANS' : kpis[0].description}
      </div>
    </div>
    {kpis.slice(1).map((kpi) => (
      <div key={kpi.description} className="bg-gray-800 rounded-lg border border-gray-500 p-2 min-w-32 max-w-36 h-16 text-center flex flex-col justify-center items-center gap-1">
        <div className='text-xl font-mono text-gray-100'>{kpi.value}</div>
        <div>
          {kpi.description === '#' ? '# LOANS' : kpi.description}
        </div>
      </div>
    ))}
  </div>
);

const PortfolioSkeleton: FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col gap-5 overflow-x-auto">
        {/* Total in your Portfolio */}
        <div className="h-8 w-60 bg-gray-700 rounded animate-pulse opacity-50 mb-2"></div> {/* Skeleton for "Total in your Portfolio" */}
        <div className="h-6 w-20 bg-gray-600 rounded animate-pulse opacity-50 mb-4"></div> {/* Skeleton for number of pools */}

        <div className="flex flex-row gap-4">
          <div className="rounded-lg border border-[#260626] p-2 min-w-48 h-16 flex flex-col justify-center items-center gap-1 bg-gradient-to-r from-[#1555ec] to-[#ff23e1] animate-pulse opacity-50">
            <div className="h-6 w-24 bg-gray-600 rounded"></div>
            <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="rounded-lg border border-gray-500 p-2 min-w-32 max-w-36 h-16 flex flex-col justify-center items-center gap-1 bg-gray-700 animate-pulse opacity-50"
            >
              <div className="h-6 w-24 bg-gray-600 rounded"></div>
              <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
            </div>
          ))}
        </div>

        {/* Auto */}
        <div className="h-8 w-40 bg-gray-700 rounded animate-pulse opacity-50 mb-2"></div> {/* Skeleton for "Auto" */}
        <div className="h-6 w-20 bg-gray-600 rounded animate-pulse opacity-50 mb-4"></div> {/* Skeleton for number of pools */}

        <div className="flex flex-row gap-4">
          <div className="rounded-lg border border-[#260626] p-2 min-w-48 h-16 flex flex-col justify-center items-center gap-1 bg-gradient-to-r from-[#1555ec] to-[#ff23e1] animate-pulse opacity-50">
            <div className="h-6 w-24 bg-gray-600 rounded"></div>
            <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="rounded-lg border border-gray-500 p-2 min-w-32 max-w-36 h-16 flex flex-col justify-center items-center gap-1 bg-gray-700 animate-pulse opacity-50"
            >
              <div className="h-6 w-24 bg-gray-600 rounded"></div>
              <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
            </div>
          ))}
        </div>

        {/* Home */}
        <div className="h-8 w-40 bg-gray-700 rounded animate-pulse opacity-50 mb-2"></div> {/* Skeleton for "Home" */}
        <div className="h-6 w-20 bg-gray-600 rounded animate-pulse opacity-50 mb-4"></div> {/* Skeleton for number of pools */}

        <div className="flex flex-row gap-4">
          <div className="rounded-lg border border-[#260626] p-2 min-w-48 h-16 flex flex-col justify-center items-center gap-1 bg-gradient-to-r from-[#1555ec] to-[#ff23e1] animate-pulse opacity-50">
            <div className="h-6 w-24 bg-gray-600 rounded"></div>
            <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
          </div>
          {[...Array(7)].map((_, idx) => (
            <div
              key={idx}
              className="rounded-lg border border-gray-500 p-2 min-w-32 max-w-36 h-16 flex flex-col justify-center items-center gap-1 bg-gray-700 animate-pulse opacity-50"
            >
              <div className="h-6 w-24 bg-gray-600 rounded"></div>
              <div className="h-4 w-16 bg-gray-500 mt-2 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const PortfolioDashboard = () => {
  const { data, loading } = usePortfolioSummary();
  const { data: autoData, loading: autoLoading } = usePortfolioSummary(
    AssetClass.AUTO
  );
  const { data: homeData, loading: homeLoading } = usePortfolioSummary(
    AssetClass.HOME
  );
  const { data: unsecData, loading: unsecLoading } = usePortfolioSummary(
    AssetClass.UNSECPERSONAL
  );
  const { data: listingsData } = useQuery<GetListings>(GET_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });

  const kpis = mapKpis(data);
  const autoKpis = mapKpis(autoData);
  const homeKpis = mapKpis(homeData);

  const autoPools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.AUTO
    ).length || 0;
  const homePools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.HOME
    ).length || 0;
  const unsecPools =
    listingsData?.user.company.listings.filter(
      (listing) => listing.asset_class === AssetClass.UNSECPERSONAL
    ).length || 0;
  const totalPools = autoPools + homePools;

  return (
    <PortfolioLayout hideSummery={true}>
      {loading || autoLoading || homeLoading || (isUnsecEnabled() && unsecLoading) ? (
        <PortfolioSkeleton />
      ) : (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col gap-5 overflow-x-auto">
            <div>
              <h2 className="text-2xl font-bold text-white">Total in your Portfolio</h2>
              <div className="text-lg text-gray-400">{totalPools} pools</div>
            </div>

            <KpiCardContainer kpis={kpis} />

            <div>
              <h1 className="text-3xl font-bold text-white">Auto</h1>
              <h2 className="text-2xl font-bold text-gray-400">Total by asset class</h2>
              <div className="text-lg text-gray-400">{autoPools} pools</div>
            </div>

            <KpiCardContainer kpis={autoKpis} />

            <div>
              <h1 className="text-3xl font-bold text-white">Home</h1>
              <h2 className="text-2xl font-bold text-gray-400">Total by asset class</h2>
              <div className="text-lg text-gray-400">{homePools} pools</div>
            </div>

            <KpiCardContainer kpis={homeKpis} />

            {isUnsecEnabled() && (
              <div>
                <h1 className="text-3xl font-bold text-white">Unsecured Personal</h1>
                <h2 className="text-2xl font-bold text-gray-400">Total by asset class</h2>
                <div className="text-lg text-gray-400">{unsecPools} pools</div>
                <KpiCardContainer kpis={mapKpis(unsecData)} />
              </div>
            )}
          </div>
        </div>
      )}
    </PortfolioLayout>
  );
};

export default PortfolioDashboard;

