import React, { useState, useEffect } from 'react';

interface IconProps {
  name?: string;
  fill?: string;
  className?: string;
}

interface Module {
  ReactComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const SvgIcon: React.FC<IconProps> = ({ name, className, fill, ...rest }) => {
  const [iconModule, setIconModule] = useState<Module | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;

    if (!name) return;

    import(`../assets/svgs/${name}.svg`)
      .then((module) => {
        if (isMounted) {
          setIconModule(module);
        }
      })
      .catch((error) => {
        console.error(`${error}: Icon with name: ${name} not found!`);
      });

    return () => {
      isMounted = false;
    };
  }, [name]);

  if (!iconModule) return null;

  const Component = iconModule.ReactComponent;

  return <Component className={className} fill={fill} {...rest} />;
};
