import { gql, useQuery } from '@apollo/client';
import { FC, useState } from 'react';
import { formatCurrency, formatPercent } from 'configs/columns';
import { MarketplaceBlotterQuery } from './__generated__/MarketplaceBlotterQuery';
import { BaseButton } from 'common-ui';
import RequestAccess from 'features/OnMarket/RequestAccess';
import { RequestAccessFragments } from 'features/OnMarket/RequestAccess.fragments';
import { MarketplaceBlotterSkeleton } from './MarketplaceBlotterSkeleton';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'ui-kit';

const MARKETPLACE_BLOTTER_QUERY = gql`
  query MarketplaceBlotterQuery {
    listings (sort: { sort_field: created_date_seconds, sort_direction: desc }, pagination: { page_size: 3, offset: 0 }){
      id
      provided_name
      asset_class
      wa_coupon
      t_current_balance_cents
      ...on PublicListing {
        ...RequestAccessPublicListing
      }
    }
  }
  ${RequestAccessFragments.PublicListing}
`;

export const MarketplaceBlotter: FC = () => {
  const navigate = useNavigate();
  const { data, loading, error: queryError } = useQuery<MarketplaceBlotterQuery>(MARKETPLACE_BLOTTER_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<null | MarketplaceBlotterQuery['listings'][0]>(null);

  if (loading) {
    return <MarketplaceBlotterSkeleton />;
  }

  if (queryError) {
    return <div>Error loading listings</div>;
  }

  const listings = data?.listings.slice(0,3) || [];

  const handleLearnMoreClick = () => {
    navigate('/marketplace/active_trades');
  };

  const handleListingClick = (listing: MarketplaceBlotterQuery['listings'][0]) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
  };

  return (
    <>
      <h2 className='font-heebo font-medium text-lg text-white mb-4'>Marketplace Blotter</h2>
      <div className="flex overflow-x-auto">
        {listings.map((listing) => (
          <div
            key={listing.id}
            className="max-w-[220px] flex-1 text-white rounded border-r border-slate-500 p-3 last:border-r-0 flex-shrink-0 cursor-pointer bg-background-canvas hover:bg-slate-900 transition-all duration-150 transform will-change-transform"
            onClick={() => handleListingClick(listing)}
          >
            <div className="text-purple-300 font-medium max-w-48 overflow-ellipsis truncate">{listing.provided_name}</div>
            <div>{listing.asset_class}</div>
            <div className="font-bold flex justify-between max-w-48">
              <span className="whitespace-nowrap text-left">GWAC</span>
              <span className="whitespace-nowrap text-right">{formatPercent(listing.wa_coupon ?? 0, 3)}</span>
            </div>
            <div className="text-green-500 flex justify-between max-w-48">
              <span className="whitespace-nowrap text-left">UPB</span>
              <span className="whitespace-nowrap text-right">{formatCurrency(listing.t_current_balance_cents)}</span>
            </div>
          </div>
        ))}
      </div>
      {listings.length > 3 && (
        <div className="flex justify-end mt-1">
          <BaseButton
            size='small'
            type="secondary"
            onClick={handleLearnMoreClick}
            label="Learn more"
          >
          Learn More
          </BaseButton>
        </div>
      )}
      {isModalOpen && selectedListing && (
        <Modal
          headerText=""
          setIsOpen={setIsModalOpen}
          type="request-access"
          subText=""
          caption=""
          width="100%"
          className="p-6 bg-background-canvas rounded-lg border border-pink-500 shadow-lg text-white max-w-[985px] w-full overflow-y-auto max-h-[540px] font-heebo"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">{selectedListing.provided_name}</h2>
            <button className="text-gray-500 hover:text-gray-300" onClick={() => setIsModalOpen(false)}>✕</button>
          </div>
          <RequestAccess listingId={selectedListing.id} listingDetails={selectedListing} />
        </Modal>
      )}
    </>
  );
};