import config from 'config';
import { isBoolean } from 'functions/typeUtils';

// TODO https://linear.app/orsnn/issue/ORS-1504/implement-feature-flags-with-dynamodb
const isUnsecEnabled = () => {
  return 'features' in config
    && config.features != null 
    && 'isUnsecEnabled' in config.features 
    && isBoolean(config.features.isUnsecEnabled)
    && config.features?.isUnsecEnabled;
}

export {
  isUnsecEnabled,
}
