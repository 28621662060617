import NotificationsDropdown from 'common-ui/Dropdown/Notifications/NotificationsDropdown';
import { useNotifications } from 'common-ui/hooks/useNotifications';
import {FC} from 'react';

export const NotificationsSection: FC = () => {
  const { notifications, loading } = useNotifications();

  return (
    <>
      <h2 className='font-heebo font-medium text-lg text-white mb-4'>Notification Center</h2>
      <NotificationsDropdown notifications={notifications} loading={loading} className='max-h-[100%] !mt-0 shadow-none pb-[40px] bg-notification-gradient' />
    </>
  );
};
