import { gql } from '@apollo/client';

const GET_COLLATERAL_FILE_UPLOAD_URL_MUTATION = gql`
  mutation getCollateralFileUploadURL($input: CollateralFileUploadURLInput!) {
    getCollateralFileUploadURL(input: $input) {
      URL
    }
  }
`;

export default GET_COLLATERAL_FILE_UPLOAD_URL_MUTATION;
