import { gql, useMutation, useQuery } from '@apollo/client';
import { ToggleButton } from 'common-ui';
import toast from 'react-hot-toast';
import { Spinner } from 'baseui/spinner';
import { UpdateNotificationPreferencesVariables } from './__generated__/UpdateNotificationPreferences';
import { GetNotificationPreferences } from './__generated__/GetNotificationPreferences';

const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences {
    notificationPreferences {
      userPreferences {
        emailOptIn
      }
    }
  }
`;

const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation UpdateNotificationPreferences($emailOptIn: Boolean!, $textOptIn: Boolean!, $phone: String!) {
    putNotificationPreferences(
      requestData: { emailOptIn: $emailOptIn, textOptIn: $textOptIn, phone: $phone }
    ) {
      success
    }
  }
`;

const EmailOptInPrefs = () => {
  const { data, loading } = useQuery<GetNotificationPreferences>(GET_NOTIFICATION_PREFERENCES);

  const [updateNotificationPreferences] = useMutation<
    UpdateNotificationPreferencesVariables,
    UpdateNotificationPreferencesVariables
  >(UPDATE_NOTIFICATION_PREFERENCES);

  const handleToggle = async (checked: boolean) => {
    await updateNotificationPreferences({
      variables: { emailOptIn: checked, textOptIn: false, phone: '' },
    });

    if (checked) {
      toast.success('You have opted in to receive email notifications.');
    } else {
      toast.success('You have opted out of email notifications.');
    }
  };

  const emailOptIn = data?.notificationPreferences?.userPreferences?.emailOptIn || false;

  return (
    <div className="p-4">
      <table className="w-full text-left">
        <tbody>
          <tr>
            <td className="text-gray-500 text-xs font-medium uppercase pr-4 w-52">
              Email Notifications Opt-In
            </td>
            <td>
              {loading ? (
                <div className="flex justify-center items-center">
                  <Spinner size={34} />
                </div>
              ) : (
                <div className="flex flex-col items-end">
                  <ToggleButton
                    initialState={emailOptIn}
                    checkedLabel="On"
                    uncheckedLabel="Off"
                    onChange={handleToggle}
                  />
                  {emailOptIn && (
                    <p className="mt-2 text-xs text-gray-500 max-w-md">
                      You have opted in to receive email notifications.
                    </p>
                  )}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmailOptInPrefs;
