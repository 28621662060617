import {
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React, { useState, useEffect } from 'react';

export function ModalNotStyled(props: {
  trigger?: React.ReactElement;
  handleClose?: () => void;
  initialOpen?: boolean;
  children: (args: { closeModal: () => void }) => React.ReactElement;
  className?: string;
}) {
  const [open, setOpen] = useState(props.initialOpen || false);
  const { context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { handleClose } = props;

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'dialog' });

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  const Trigger = props?.trigger && React.cloneElement(props.trigger, getReferenceProps({
    ref: refs.setReference,
  }));

  useEffect(() => {
    if (!open && handleClose) {
      handleClose();
    }
  }, [open, handleClose]);

  return (
    <>
      {Trigger}
      {open ? (
        <FloatingPortal>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={() => setOpen(false)} />
          <div
            ref={refs.setFloating}
            className={`fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center w-full ${props.className}`}
            {...getFloatingProps()}
          >
            {props.children({ closeModal: () => setOpen(false) })}
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
}
