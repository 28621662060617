import { gql } from '@apollo/client';

// Mutation to save seller and buyer contract reference texts
export const SAVE_CONTRACT_REFERENCE_TEXT = gql`
  mutation SaveContractReferenceText(
    $dealId: ID!
    $contractId: ID!
    $contractVersion: String!
    $sellerReferenceText: String!
    $buyerReferenceText: String!
  ) {
    saveContractReferenceText(
      dealId: $dealId
      contractId: $contractId
      contractVersion: $contractVersion
      sellerReferenceText: $sellerReferenceText
      buyerReferenceText: $buyerReferenceText
    ) {
      contractId
      contractVersion
      data {
        buyerSigned
        sellerSigned
        sellerReferenceText
        buyerReferenceText
        readyToSignBuyer
        readyToSignSeller
      }
    }
  }
`;

// Mutation to get the pre-signed URL for downloading a contract file
export const GET_CONTRACT_FILE_GET_URL = gql`
  mutation GetContractFileGetUrl($input: GetContractFileGetUrlInput!) {
    getContractFileGetUrl(input: $input)
  }
`;

// Mutation to save a signature for a contract
export const SAVE_CONTRACT_SIGNATURE = gql`
  mutation SaveContractSignature(
    $dealId: ID!
    $contractId: ID!
    $contractVersion: String!
    $consentVerbiage: String!
    $intentVerbiage: String!
  ) {
    saveContractSignature(
      dealId: $dealId
      contractId: $contractId
      contractVersion: $contractVersion
      consentVerbiage: $consentVerbiage
      intentVerbiage: $intentVerbiage
    )
  }
`;

// Mutation to mark a document as ready to be signed
export const MARK_DOCUMENT_READY_TO_SIGN = gql`
  mutation MarkDocumentReadyToSign(
    $dealId: ID!
    $contractId: ID!
    $contractVersion: String!
    $isReady: Boolean!
  ) {
    markDocumentReadyToSign(
      dealId: $dealId
      contractId: $contractId
      contractVersion: $contractVersion
      isReady: $isReady
    )
  }
`;

// Mutation to delete a contract
export const DELETE_CONTRACT = gql`
  mutation DeleteContract($dealId: ID!, $contractId: ID!, $contractVersion: String!) {
    deleteContract(dealId: $dealId, contractId: $contractId, contractVersion: $contractVersion)
  }
`;
