import { DropdownPillComboboxSingleAdd } from 'common-ui';
import { Metrics } from 'features/pages/portfolio/Dashboard/PoolManager/PoolHeader';
import { KPI } from 'features/pages/portfolio/Dashboard/PoolManager/types';
import { isMetricMatchingFilter } from 'features/pages/portfolio/Dashboard/PoolManager/utils/helpers';
import { useMemo } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export interface DiligencePopulationMetricsProps {
  allMetrics: KPI[];
  selectedMetrics: string[];
  onAddMetric: (selectedMetric: string) => void;
  onRemoveMetric: (selectedMetric: string) => void;
}

interface Props {
  metrics: DiligencePopulationMetricsProps;
}

const DiligencePopulationMetrics = ({
  metrics: { allMetrics, selectedMetrics, onAddMetric, onRemoveMetric },
}: Props) => {
  const handleClick = (selectedMetric: KPI) => () => {
    onRemoveMetric(selectedMetric.selector);
  };

  const handleAddMetric = (selectedMetric: KPI) => {
    onAddMetric(selectedMetric.selector);
  };

  const availableMetrics = useMemo(
    () =>
      allMetrics.filter((metric) => !selectedMetrics.includes(metric.selector)),
    [selectedMetrics, allMetrics]
  );

  const selectedKPIs = useMemo(
    () =>
      allMetrics.filter((metric) => selectedMetrics.includes(metric.selector)),
    [selectedMetrics, allMetrics]
  );

  return (
    <>
      <Metrics metrics={selectedKPIs} handleClick={handleClick} />
      <DropdownPillComboboxSingleAdd
        options={availableMetrics}
        getOptionLabel={(option: KPI) => option.description}
        getOptionValue={(option: KPI) => option.value}
        onValueSelected={handleAddMetric}
        buttonLabel="Add a metric to diligence population"
        comboLabel="Search for a metric"
        inputPlaceholder="Enter a metric name..."
        manuLabel="All metrics (Ascending)"
        filterOption={(option: FilterOptionOption<KPI>, searchInput: string) =>
          isMetricMatchingFilter(option, searchInput)
        }
      />
    </>
  );
};

export default DiligencePopulationMetrics;
