import { ForwardedRef, forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { isBoolean } from 'functions/typeUtils';

export type IndeterminateCheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  indeterminate?: boolean;
  className?: string;
};

export const IndeterminateCheckbox = forwardRef((props: IndeterminateCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { indeterminate, className, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergeRefs([inputRef, ref]);

  useEffect(() => {
    if (inputRef.current && isBoolean(indeterminate)) {
      inputRef.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [inputRef, indeterminate, rest.checked]);

  return (
    <input
      ref={mergedRef}
      type="checkbox"
      className={`
        appearance-none h-[18px] w-[18px] border-2 border-slate-200 rounded-[3px] grid place-content-center cursor-pointer
        relative
        before:absolute before:top-1/2 before:left-1/2 before:bg-transparent
        before:-translate-x-1/2 before:-translate-y-1/2
        checked:before:content-['✔'] checked:before:text-slate-200 checked:before:text-[11px] checked:before:font-normal
        indeterminate:before:content-[''] indeterminate:before:w-[10px] indeterminate:before:h-[10px] 
        indeterminate:before:bg-slate-200 indeterminate:before:clip-path-[inset(37%_12%_37%_12%_round_10%)]
        ${className}
      `}
      {...rest}
    />
  );
});
