import { BaseButton } from 'common-ui';
import { BidButtonsGroup } from './BiddingAndPricingSection.styles';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { useMutation } from '@apollo/client';
import {
  SubmitBid,
  SubmitBidVariables,
} from 'mutation/__generated__/SubmitBid';
import { SUBMIT_BID } from 'mutation/submitBid';
import { BidConfirmationDialog } from './BidConfirmationDialog';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { BidSucceededDialog } from './BidSucceededDialog';
import { DialogActions } from 'common-ui/Dialog.styles';
import { useState } from 'react';
import { GET_DEAL_TIMELINE } from 'features/deals/DealStages/fragments';
import { GetTimelineVariables } from 'features/deals/DealStages/__generated__/GetTimeline';

export const BidActionButtons = () => {
  const { selectedCarve, dealId, bidDetails } = useBiddingPricingContext();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const getDealTimelineVariables: GetTimelineVariables = {
    id: dealId,
  };

  const [submitBid] = useMutation<
    SubmitBid,
    SubmitBidVariables
  >(SUBMIT_BID, {
    refetchQueries: [
      {
        query: GET_DEAL_TIMELINE,
        variables: getDealTimelineVariables,
      }, 
    ],
  });

  const bidConfirmationDialog = useDialog();
  const bidSucceededDialog = useDialog();
  const errorDialog = useDialog();

  if (!dealId) {
    return <p>Invalid Deal ID</p>;
  }
  const onSubmitBid = () => {
    bidConfirmationDialog.openDialog();
  };

  const onBidConfirmed = () => {
    bidConfirmationDialog.closeDialog();
    const bidBasisPoints = selectedCarve?.stipulations?.bid_basis_points;
    if (bidDetails && selectedCarve && selectedCarve.stipulations && bidBasisPoints && selectedCarve.id) {
      submitBid({
        variables: {
          input: {
            deal_id: dealId,
            bid_basis_points: bidBasisPoints,
            carve_id: selectedCarve.id,
            document_ids: bidDetails.stipulations.document_ids,
          },
        },
      })
        .then(() => {
          bidSucceededDialog.openDialog();
        })
        .catch((err) => {
          setErrorMessage(err.message);
          errorDialog.openDialog();
        });
    }
  };

  const submitEnabled = bidDetails && bidDetails.carve.stipulations?.bid_basis_points != null;

  return (
    <>
      <BidButtonsGroup>
        <BaseButton
          disabled={!submitEnabled}
          onClick={onSubmitBid}
          label={'submit_bid'}
        >
          Submit Bid
        </BaseButton>
      </BidButtonsGroup>

      {bidDetails && selectedCarve && (
        <Dialog dialog={bidConfirmationDialog}>
          <BidConfirmationDialog
            bidDetails={bidDetails}
            selectedCarve={selectedCarve}
            onCancel={() => bidConfirmationDialog.closeDialog()}
            onConfirm={() => onBidConfirmed()}
          />
        </Dialog>
      )}

      <Dialog dialog={bidSucceededDialog}>
        <BidSucceededDialog
          onCancel={() => bidSucceededDialog.closeDialog()}
          onConfirm={() => bidSucceededDialog.closeDialog()}
        />
      </Dialog>

      <Dialog dialog={errorDialog}>
        <div>ERROR: {errorMessage}</div>
        <DialogActions>
          <BaseButton
            size="medium"
            label="cancel"
            onClick={() => errorDialog.closeDialog()}
          >
            OK
          </BaseButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
