import { gql } from '@apollo/client';
import { MessageBadge, LabelAndValue } from 'common-ui';
import { DEAL_URL, OVERVIEW_URL } from 'app-level/navigation/paths';
import { match } from 'functions/FunctionalMatch';
import { DateTime } from 'luxon';
import { Party } from 'models/DealStatus';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import { styled, theme } from 'style/ORSNNTheme';
import { css } from 'styled-components';
import { DealBlocker, DealStatus } from '__generated__/globalTypes';
import { FIELD_CONFIG, formatCurrency } from '../../../configs/columns';
import CardLink from './CardLink';
import { DealCardCounterparty } from './__generated__/DealCardCounterparty';
import { DealCardDeal, DealCardDeal_state } from './__generated__/DealCardDeal';

type StatusSectionProps = {
  color: string;
};

const StatusSection = styled.span<StatusSectionProps>`
  padding: 1px 8px;
  border-radius: 0.25rem;
  background-color: ${(props) => props.color};
`;

const Tooltip = styled(ReactTooltip)`
  max-width: 20rem;
`;

type NameProps = {
  $highlighted?: boolean;
};

const Name = styled.span<NameProps>`
  ${($props) =>
    $props.$highlighted
      ? css`
          color: ${($props) => $props.theme.color.brandMainPink};
        `
      : css`
          margin-right: 1rem;
        `}
`;

const StatusColors = {
  UserAction: theme.color.warningDefault,
  CounterpartyAction: theme.color.gray900,
  Complete: theme.color.successDefault,
  Rejected: theme.color.gray900,
};

const DealCardFragments = {
  deal: gql`
    fragment DealCardDeal on Deal {
      id
      createdDate: created_date
      state {
        status
        blocker
        label
        tooltip
        loanDataVisible
      }
      listing {
        id
        name
        assetClass: asset_class
        upb: t_current_balance_cents
        wac: wa_coupon
        providedName: provided_name
      }
      hasNewMessages: has_new_messages
      hasNewDealStatus: has_new_deal_status
    }
  `,
  counterparty: gql`
    fragment DealCardCounterparty on Company {
      type
      ... on PrivateCompany {
        id
        name
      }
    }
  `,
};

type Props = {
  deal: DealCardDeal;
  party: Party;
  counterparty: DealCardCounterparty;
};

const DealCard = ({ deal, party, counterparty }: Props): JSX.Element => {
  const { hasNewMessages } = deal;
  const hasProvidedName =
    'providedName' in deal.listing &&
    deal.listing.providedName != null &&
    deal.listing.providedName !== '';

  const statusColor = match<DealCardDeal_state, string>(deal.state)
    .on(
      (state) => state?.status === DealStatus.COMPLETE,
      () => StatusColors.Complete
    )
    .on(
      (state) => state?.status === DealStatus.REJECTED_INQUIRY,
      () => StatusColors.Rejected
    )
    .on(
      (state) =>
        state?.blocker === (party as string) ||
        state?.blocker === DealBlocker.BOTH,
      () => StatusColors.UserAction
    )
    .otherwise(() => StatusColors.CounterpartyAction);

  return (
    <CardLink
      data-tooltip-id={deal.id}
      to={`/inprogress/${deal.listing.assetClass.toLowerCase()}/${
        deal.id
      }${DEAL_URL}${OVERVIEW_URL}`}
    >
      {hasNewMessages && <MessageBadge />}
      <StatusSection color={statusColor} data-tooltip-id={deal.id}>
        {deal.state.label}
      </StatusSection>
      <Tooltip id={deal.id}>{deal.state.tooltip}</Tooltip>
      <span>
        {FIELD_CONFIG['asset_class'].display(deal.listing.assetClass)}
      </span>
      <Name $highlighted={true}>
        {(hasProvidedName ? '(' : '') +
          deal.listing.name +
          (hasProvidedName ? ')' : '')}
      </Name>
      <LabelAndValue
        label={`COUNTERPARTY`}
        value={'name' in counterparty ? counterparty.name : counterparty.type}
      />
      <LabelAndValue
        label={`OPENED`}
        value={DateTime.fromISO(deal.createdDate)
          .toFormat('M/dd/yyyy')
          .padEnd(10)}
      />
      <LabelAndValue label={`C BAL`} value={formatCurrency(deal.listing.upb)} />
      <LabelAndValue
        label={`GWAC`}
        value={FIELD_CONFIG['interest_rate'].display(deal.listing.wac)}
      />
    </CardLink>
  );
};

export default DealCard;
export { DealCardFragments };
