import React from 'react';
import { SvgIcon } from 'common-ui';
import classNames from 'classnames';

import './base-button.scss';

const TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extra-large',
};

export type ButtonProps = {
  /* aria-label */
  label: string;
  /* Button content */
  children?: React.ReactNode;
  /* Type of button */
  type?: (typeof TYPES)[keyof typeof TYPES];
  /* Size of button */
  size?: (typeof SIZES)[keyof typeof SIZES];
  /* Icon to display inside button */
  icon?: string;
  disabled?: boolean;
  /* True if button is selected in button group */
  isSelected?: boolean;
  /* Colour fill */
  iconFill?: string;
  /* Determines placement of icon */
  isIconRight?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const BaseButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = TYPES.primary,
      size = SIZES.large,
      icon,
      disabled,
      children,
      label,
      isSelected,
      iconFill,
      isIconRight,
      onClick,
      className,
    }: ButtonProps,
    ref
  ) => {
    const classes = classNames(
      'c-button',
      `c-button--${type}`,
      `c-button--${size}`,
      {
        'c-button--disabled': disabled,
        'c-button--icon': icon,
        'c-button--selected': isSelected,
        'c-button__icon__right': isIconRight,
      },
      className
    );

    return (
      <button
        ref={ref}
        onClick={onClick}
        type="button"
        aria-label={label}
        className={classes}
        disabled={!!disabled}
      >
        {icon ? (
          <>
            <SvgIcon name={icon} fill={iconFill} />
            <span className="c-button__label">{children}</span>
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);

BaseButton.displayName = 'BaseButton';
