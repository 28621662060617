import { isUnsecEnabled } from 'features/flags';
import AssetClassConfig, { AssetClassId } from 'models/AssetClass';

export const SIDE_NAV_CONFIG: { routeSegment: AssetClassId, iconName: string, disabled?: boolean, text: string }[] = [
  {
    routeSegment: 'home',
    iconName: 'residential',
    text: AssetClassConfig.HOME.label,
  },
  {
    routeSegment: 'auto',
    iconName: 'auto',
    text: AssetClassConfig.AUTO.label,
  },
  {
    routeSegment: 'rv',
    iconName: 'rv',
    text: 'RV',
    disabled: true,
  },
  {
    routeSegment: 'unsecuredpersonal',
    iconName: 'unsecured',
    text: AssetClassConfig.UNSECPERSONAL.label,
    disabled: !isUnsecEnabled(),
  },
  {
    routeSegment: 'cre',
    iconName: 'real-estate',
    text: AssetClassConfig.CRE.label,
  },
  {
    routeSegment: 'aircraft',
    iconName: 'aviation',
    text: 'Aircraft',
    disabled: true,
  },
  {
    routeSegment: 'motorcycle',
    iconName: 'motorcycle',
    text: 'Motorcycle',
    disabled: true,
  },
  {
    routeSegment: 'warehouse',
    iconName: 'warehouse',
    text: 'Warehouse',
    disabled: true,
  },
  {
    routeSegment: 'marine',
    iconName: 'marine',
    text: 'Marine',
    disabled: true,
  },
  {
    routeSegment: 'revolving',
    iconName: 'revolving',
    text: 'Revolving',
    disabled: true,
  },
];
