import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import handlePng from '../Icons/assets/pngs/knob.png';
import { typographyHeebo10, typographyHeebo12 } from 'common-ui/typography';
import { useDebouncedCallback } from 'use-debounce';

const Slider = styled.div`
  width: 100%;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Track = styled.div`
  margin-top: 24px;
  height: 20px;
  position: relative;
`;

const FullRange = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background: #3b4351;
  top: 50%;
  transform: translateY(-50%);
`;

const Range = styled.div`
  position: absolute;
  height: 2px;
  background: white;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
`;

const HandleKnob = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url(${handlePng});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  z-index: 2;
  outline: none;
`;

const ValueDisplay = styled.span`
  width: 52px;
  top: -26px;
  text-align: center;
  color: #e85ee5;
  ${typographyHeebo12}
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border: 1px solid #333;
  }
`;

const ValueInput = styled.input`
  bottom: 100%;
  width: 50px;
  height: 20px;
  text-align: center;
  top: -25px;
  color: #e85ee5;
  ${typographyHeebo12}
  background-color: #222;
  border: 1px solid #333;
  &:focus {
    outline: 1px solid #333333;
  }
`;
const MinMaxLabel = styled.div`
  color: #d9dce4;
  ${typographyHeebo10}
  cursor: pointer;
`;

const ToLabel = styled.div`
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  color: #bbc5d7;
  ${typographyHeebo10}
`;

const LeftValue = styled.div`
  transform: translateX(-50%);
`;

const RightValue = styled.div`
  transform: translateX(50%);
`;

interface LabelContainerProps {
  value1: number;
  value2: number;
  min: number;
  max: number;
}

const SliderContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0 15px;
  height: 40px;
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div.attrs<LabelContainerProps>((props) => {
  const left = ((props.value1 - props.min) / (props.max - props.min)) * 100;
  const width = ((props.value2 - props.value1) / (props.max - props.min)) * 100;
  const midpoint = left + width / 2;

  return {
    style: {
      left: `${midpoint}%`,
      transform: 'translateX(-50%)',
      minWidth: `${width}%`,
    },
  };
})<LabelContainerProps>`
  display: flex;
  justify-content: space-between;
  position: absolute;
  color: #e85ee5;
  width: min-content;
`;

interface HandleProps {
  value: number;
  updateValue: (newValue: number) => void;
  min: number;
  max: number;
  trackRef: React.RefObject<HTMLDivElement>;
  integer?: boolean;
  decimal?: boolean;
}

const Handle: React.FC<HandleProps> = ({
  value,
  min,
  max,
  updateValue,
  trackRef,
  integer,
  decimal,
}) => {
  const calculateHandlePosition = (value: number) =>
    `calc(${((value - min) / (max - min)) * 100}% - 9px)`;

  const position = calculateHandlePosition(value);

  const handleMouseMove = (e: globalThis.MouseEvent) => {
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      let newValue = ((e.clientX - rect.left) / rect.width) * (max - min) + min;

      if (integer) {
        newValue = Math.round(newValue);
      } else if (decimal) {
        newValue = Math.round(newValue * 20) / 20; // Round to nearest 0.05 for decimal
      } else {
        newValue = Math.round(newValue * 4) / 4;
      }
      updateValue(newValue);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <HandleKnob
      style={{ left: position }}
      onMouseDown={handleMouseDown}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
          if (integer) {
            updateValue(value + 1);
          } else if (decimal) {
            updateValue(value + 0.05);
          } else {
            updateValue(value + 0.25);
          }
        } else if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
          if (integer) {
            updateValue(value - 1);
          } else if (decimal) {
            updateValue(value - 0.05);
          } else {
            updateValue(value - 0.25);
          }
        }
      }}
    />
  );
};

export interface RangeSliderV2Props {
  min: number;
  max: number;
  defaultValue: [number, number];
  onChange: (values: [number, number]) => void;
  displayFormat?: (value: number) => string;
  inputFormat?: (value: string) => number;
  displayPercentage?: boolean;
  integer?: boolean;
  decimal?: boolean;
}

const RangeSliderV2: React.FC<RangeSliderV2Props> = ({
  min,
  max,
  defaultValue,
  onChange,
  displayFormat,
  inputFormat,
  displayPercentage,
  integer,
  decimal,
}) => {
  const [value1, setValue1] = useState<number>(defaultValue[0]);
  const [value2, setValue2] = useState<number>(defaultValue[1]);
  const trackRef = useRef<HTMLDivElement>(null);
  const format = displayFormat || ((value: number) => value.toString());

  const debouncedOnChange = useDebouncedCallback(onChange, 100);

  const updateValue1 = (proposedValue: number) => {
    let newValue = Math.min(Math.max(proposedValue, min), value2);
    if (integer) {
      newValue = Math.round(newValue);
    } else if (decimal) {
      newValue = Math.round(newValue * 100) / 100;
    }

    setValue1(newValue);
    debouncedOnChange([newValue, value2]);
  };

  const updateValue2 = (proposedValue: number) => {
    let newValue = Math.max(Math.min(proposedValue, max), value1);
    if (decimal) {
      newValue = Math.round(newValue * 100) / 100; // Round to nearest 0.05 for decimal
    } else if (integer) {
      newValue = Math.round(newValue);
    }

    setValue2(newValue);
    debouncedOnChange([value1, newValue]);
  };

  const handleMinClick = () => {
    updateValue1(min);
  };

  const handleMaxClick = () => {
    updateValue2(max);
  };

  const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      let clickPosition =
        ((e.clientX - rect.left) / rect.width) * (max - min) + min;

      if (decimal) {
        clickPosition = Math.round(clickPosition * 100) / 100; // Round to nearest 0.05 for decimal
      } else if (integer) {
        clickPosition = Math.round(clickPosition);
      }

      if (clickPosition < value1) {
        updateValue1(clickPosition);
      } else if (clickPosition > value2) {
        updateValue2(clickPosition);
      } else {
        const distanceToValue1 = Math.abs(value1 - clickPosition);
        const distanceToValue2 = Math.abs(value2 - clickPosition);

        if (distanceToValue1 < distanceToValue2) {
          updateValue1(clickPosition);
        } else {
          updateValue2(clickPosition);
        }
      }
    }
  };

  const rangeStyle = {
    left: `${((value1 - min) / (max - min)) * 100}%`,
    width: `${((value2 - value1) / (max - min)) * 100}%`,
  };

  return (
    <Slider>
      <MinMaxLabel onClick={handleMinClick}>{displayPercentage ? format(min) + '%' : format(min)}</MinMaxLabel>
      <SliderContainer>
        <LabelContainer value1={value1} value2={value2} min={min} max={max}>
          <LeftValue>
            <EditableValueDisplay value={format(value1)} onChange={updateValue1} displayPercentage={displayPercentage} integer={integer} decimal={decimal} inputFormat={inputFormat} />
          </LeftValue>
          <ToLabel>to</ToLabel>
          <RightValue>
            <EditableValueDisplay value={format(value2)} onChange={updateValue2} displayPercentage={displayPercentage} integer={integer} decimal={decimal} inputFormat={inputFormat} />
          </RightValue>
        </LabelContainer>

        <Track ref={trackRef} onClick={handleTrackClick}>
          <FullRange />
          <Handle
            value={value1}
            min={min}
            max={max}
            updateValue={updateValue1}
            trackRef={trackRef}
            integer={integer}
            decimal={decimal}
          />
          <Handle
            value={value2}
            min={min}
            max={max}
            updateValue={updateValue2}
            trackRef={trackRef}
            integer={integer}
            decimal={decimal}
          />
          <Range style={rangeStyle} />
        </Track>
      </SliderContainer>
      <MinMaxLabel onClick={handleMaxClick}>{displayPercentage ? format(max) + '%' : format(max)}</MinMaxLabel>
    </Slider>
  );
};

const EditableValueDisplay: React.FC<{
  value: string;
  onChange: (newValue: number) => void;
  inputFormat?: (value: string) => number;
  displayPercentage?: boolean;
  integer?: boolean;
  decimal?: boolean;
}> = ({ value, onChange, displayPercentage, integer, decimal, inputFormat }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const submitEdit = () => {
    let newValue = inputFormat ? inputFormat(inputValue) : parseFloat(inputValue);
    
    if (decimal) {
      newValue = Math.round(newValue * 100) / 100; // Round to nearest 0.01 for decimal
    } else if (integer) {
      newValue = Math.round(newValue);
    } else {
      newValue = Math.round(newValue * 4) / 4;
    }

    if (isNaN(newValue)) {
      cancelEdit();
    } else {
      onChange(newValue);
      setIsEditing(false);
    }
  };

  const handleValueBlur = () => {
    submitEdit();
  };

  const handleValueKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitEdit();
    } else if (e.key === 'Escape') {
      cancelEdit();
    }
  };

  const beginEdit = () => {
    setInputValue(value.toString());
    setIsEditing(true);
  };

  const cancelEdit = () => {
    setInputValue(value.toString());
    setIsEditing(false);
  };

  return isEditing ? (
    <ValueInput
      type="text"
      ref={inputRef}
      value={inputValue}
      onChange={handleValueChange}
      onBlur={handleValueBlur}
      onKeyDown={handleValueKeyDown}
      onFocus={(e) => e.target.select()}
    />
  ) : (
    <ValueDisplay onClick={() => beginEdit()}>
      {value}
      {displayPercentage ? '%' : null}
    </ValueDisplay>
  );
};

export default RangeSliderV2;
