import  { FC } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { chartColors, chartGradientDataUri, QUERY_KEYS } from '../shared';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { CustomTooltip } from './CustomTooltip';

type MacroChartData = {
    main: {
      labels: string[];
      series: {
        [key: string]: number[];
      };
    };
};

type DataPoint = {
  date: string;
  [key: string]: string | number;
};

type MacroChartProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
};


export const MacroChart: FC<MacroChartProps> = ({ dataUrl, width=620, height=382, className='' }) => {
  const { data, isLoading } = useReactQuery<MacroChartData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.MACRO_CHART, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      const { labels, series } = data.main;
      return labels.map((label, index) => ({
        date: label,
        Inflation: parseFloat((series['Inflation'][index] / 1.0).toFixed(2)),
        Unemployment: series['Unemployment'][index],
        'Consumer Sentiment': series['Consumer Sentiment'][index],
      }));
    },
  });


  if (isLoading || !data) {
    return <div className='mt-16'>
      <ChartSkeletonLoader width={width} height={height} />
    </div>;
  }

  const maxDataValue = Math.max(...data.map(point => Math.max(point['Inflation'] as number, point['Unemployment'] as number)));
  const maxTick = Math.ceil(maxDataValue / 4) * 4;
  const yTicks = Array.from({ length: Math.ceil(maxTick / 4) + 1 }, (_, i) => i * 4);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit relative ${className}`}>
      <h3 className='text-center text-slate-100 font-heebo text-lg'>Macro Indicators</h3>
      <div className="relative" style={{ width, height: height + 20 }}>
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url("${chartGradientDataUri}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
        >
          <XAxis
            dataKey="date"
            stroke="#BBC5D7"
            tickMargin={16}
          >
            <Label
              value="Date"
              offset={16}
              position="bottom"
              style={{ fill: '#BBC5D7', fontSize: 12, opacity: 0.7 }}
            />
          </XAxis>
          <YAxis
            yAxisId="left"
            domain={[0, 'dataMax']}
            stroke="#BBC5D7"
            tickMargin={10}
            ticks={yTicks}
          >
            <Label
              value="Inflation / Unemployment %"
              angle={-90}
              position="insideLeft"
              style={{
                fill: '#BBC5D7',
                fontSize: 12,
                opacity: 0.7,
                textAnchor: 'middle'
              }}
              offset={10}
            />
          </YAxis>
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[40, 120]}
            stroke="#BBC5D7"
            tickMargin={10}
          >
            <Label
              value="Consumer Sentiment"
              angle={-90}
              position="insideLeft"
              style={{
                fill: '#BBC5D7',
                fontSize: 12,
                opacity: 0.7,
                textAnchor: 'middle'
              }}
              offset={64}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="Inflation"
            stroke={chartColors[0]}
            dot={false}
            strokeWidth={2}
            filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="Unemployment"
            stroke={chartColors[1]}
            dot={false}
            strokeWidth={2}
            filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="Consumer Sentiment"
            stroke={chartColors[2]}
            dot={false}
            strokeWidth={2}
            filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
          />
        </LineChart>
      </div>
    </div>
  );
};
