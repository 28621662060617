import { FilterOperator } from '__generated__/globalTypes';
import { BaseFiltersConfig, QueryFilter, RangeFilter, FilterValue, RangeFilterConfigEntry } from './filters.types';

export function filterValuesToSearchQuery<T extends BaseFiltersConfig>(
  filters: FilterValue<T>[],
  filterFieldToAbbr: Record<keyof T, string>
): string {
  return filters
    .map((filter) => {
      const abbr = filterFieldToAbbr[filter.name];
      if ('min' in filter && 'max' in filter) {
        // Range filter
        return `${abbr}|r|${filter.min}|${filter.max}`;
      } else if ('value' in filter) {
        const value = filter.value;
        if (Array.isArray(value)) {
          // Multi-select filter
          const encodedValues = value.map(encodeURIComponent).join('|');
          return `${abbr}|m|${encodedValues}`;
        } else if (typeof value === 'boolean') {
          // Boolean filter
          return `${abbr}|b|${value}`;
        } else if (value != null) {
          // Single value filter
          return `${abbr}|v|${encodeURIComponent(value.toString())}`;
        } else {
          return `${abbr}|v|`;
        }
      } else {
        throw new Error(`Invalid filter structure for filter "${String(filter)}"`);
      }
    })
    .join(';');
}

export function searchQueryToFilterValues<T extends BaseFiltersConfig>(
  searchQuery: string,
  abbrToFilterField: Record<string, keyof T>
): FilterValue<T>[] {
  if (!searchQuery) return [];
  const filtersStr = searchQuery.split(';');
  return filtersStr.map((filterStr) => {
    const parts = filterStr.split('|');
    const [abbr, typeIdentifier, ...valueParts] = parts;
    const name = abbrToFilterField[abbr];

    if (!name) {
      throw new Error(`Unknown filter abbreviation "${abbr}"`);
    }

    switch (typeIdentifier) {
      case 'r': {
        // Range filter
        if (valueParts.length !== 2) {
          throw new Error(`Invalid range filter format for "${abbr}"`);
        }
        const [minStr, maxStr] = valueParts;
        const min = Number(minStr);
        const max = Number(maxStr);
        if (isNaN(min) || isNaN(max)) {
          throw new Error(`Invalid number in range filter for "${abbr}"`);
        }
        return {
          name,
          min,
          max,
        };
      }
      case 'v': {
        // Single value filter
        if (valueParts.length !== 1) {
          throw new Error(`Invalid value filter format for "${abbr}"`);
        }
        const value = decodeURIComponent(valueParts[0]);
        return {
          name,
          value,
        };
      }
      case 'm': {
        // Multi-select filter
        if (valueParts.length === 1 && valueParts[0] === '') {
          // Empty multi-select
          return {
            name,
            value: [],
          };
        }
        const values = valueParts.map(decodeURIComponent);
        return {
          name,
          value: values,
        };
      }
      case 'b': {
        // Boolean filter
        if (valueParts.length !== 1) {
          throw new Error(`Invalid boolean filter format for "${abbr}"`);
        }
        const valueStr = valueParts[0];
        if (valueStr !== 'true' && valueStr !== 'false') {
          throw new Error(`Invalid boolean value "${valueStr}" for filter "${abbr}"`);
        }
        return {
          name,
          value: valueStr === 'true',
        };
      }
      default:
        throw new Error(`Unknown filter type identifier "${typeIdentifier}" for filter "${abbr}"`);
    }
  });
}

export function filtersToQueryFilters<T extends BaseFiltersConfig>(
  valueFilters: FilterValue<T>[],
  filtersConfig: T,
): QueryFilter<T>[] {
  return valueFilters
    .map((filter) => {
      const config = filtersConfig[filter.name];
      if (config.type === 'range' && 'min' in filter) {
        return convertRangeFilter(config, filter);
      } else if ('value' in filter && Array.isArray(filter.value)) {
        return filter.value.length > 0
          ? {
              field_name: filter.name,
              operator: FilterOperator.IS,
              operandList: filter.value,
            }
          : [];
      } else if ('value' in filter) {
        return filter.value !== null
          ? {
              field_name: filter.name,
              operator: FilterOperator.IS,
              operand: filter.value.toString(),
            }
          : [];
      } else {
        throw new Error('Invalid filter');
      }
    })
    .flat();
}

function convertRangeFilter<T extends BaseFiltersConfig>(
  configEntry: RangeFilterConfigEntry,
  filter: RangeFilter<T>
) {
  const prepare = configEntry.prepare ? configEntry.prepare : (value: number) => value;
  return [
    {
      field_name: filter.name,
      operator: FilterOperator.LESS_THAN_OR_EQUALS,
      operand: prepare(filter.max).toString(),
    },
    {
      field_name: filter.name,
      operator: FilterOperator.GREATER_THAN_OR_EQUALS,
      operand: prepare(filter.min).toString(),
    },
  ];
}

