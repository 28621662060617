import { FC } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { chartColors, chartGradientDataUri, QUERY_KEYS } from '../shared';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { CustomTooltip } from './CustomTooltip';

type MortgageCreditCurveData = {
  labels: string[];
  series: {
    [key: string]: number[];
  };
};

type DataPoint = {
  date: string;
  Last: number;
  '1m Ago': number;
  '6m Ago': number;
};

type MortgageCreditCurveProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const MortgageCreditCurveChart: FC<MortgageCreditCurveProps> = ({
  dataUrl,
  width = 620,
  height = 382,
  className = '',
}) => {
  const { data, isLoading } = useReactQuery<MortgageCreditCurveData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.MORTGAGE_CREDIT_CURVE, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      const { labels, series } = data;
      return labels.map((label, index) => ({
        date: label,
        Last: parseFloat(series['Last'][index].toFixed(2)),
        '1m Ago': parseFloat(series['1m Ago'][index].toFixed(2)),
        '6m Ago': parseFloat(series['6m Ago'][index].toFixed(2)),
      }));
    },
  });

  if (isLoading || !data) {
    return <div className='mt-16'>
      <ChartSkeletonLoader width={width} height={height} />
    </div>;
  }

  const seriesNames: (keyof DataPoint)[] = ['Last', '1m Ago', '6m Ago'];

  const allValues = data.flatMap((point) => seriesNames.map((name) => point[name] as number));
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  const axisTop = Math.ceil((maxValue) / 0.2) * 0.2;
  const axisBottom = Math.floor((minValue) / 0.2) * 0.2;

  // Calculate the number of ticks based on the chart height
  const numTicks = Math.max(2, Math.floor(height / 50)); // Adjust the division factor as needed
  const tickInterval = (axisTop - axisBottom) / numTicks;
  const yTicks = Array.from({ length: numTicks + 1 }, (_, i) => axisBottom + i * tickInterval);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit relative ${className}`}>
      <h3 className="text-center text-slate-100 font-heebo text-lg">Mortgage Credit Curve</h3>
      <div className="relative" style={{ width, height: height + 20 }}>
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url("${chartGradientDataUri}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <LineChart width={width} height={height} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
          <XAxis dataKey="date" stroke="#BBC5D7" tickMargin={16}>
            <Label value="Credit Score Range" offset={16} position="bottom" style={{ fill: '#BBC5D7', fontSize: 12, opacity: 0.7 }} />
          </XAxis>
          <YAxis
            domain={[axisBottom, axisTop]}
            stroke="#BBC5D7"
            tickMargin={10}
            ticks={yTicks}
            tickFormatter={(tick) => tick.toFixed(1)}
          >
            <Label
              value="Interest Rate (%)"
              angle={-90}
              position="insideLeft"
              style={{
                fill: '#BBC5D7',
                fontSize: 12,
                opacity: 0.7,
                textAnchor: 'middle',
              }}
              offset={10}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" />
          {seriesNames.map((seriesName, index) => (
            <Line
              key={seriesName}
              type="linear"
              dataKey={seriesName}
              stroke={chartColors[index % chartColors.length]}
              dot={true}
              strokeWidth={2}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};
