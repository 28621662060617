import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'common-ui';
import EventActionCards from 'features/deals/DealStages/EventActionCards';
import { useQueryWithPollingWhileVisible } from 'functions/useQueryWithPollingWhileVisible';
import styled from 'styled-components';
import { DealStatus } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from './fragments';
import {
  GetTimeline,
  GetTimelineVariables,
  GetTimeline_deal_cards,
} from './__generated__/GetTimeline';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { diff } from 'deep-diff';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

function getCardId(card: { title: string }, index: number): string {
  return `${card.title.replaceAll(' ', '_')}_${index}`;
}

function cleanCards(cards: GetTimeline_deal_cards[]): GetTimeline_deal_cards[] {
  return cards.map((card) => {
    if (card.__typename === 'DealDocumentsCard') {
      return {
        ...card,
        documents: card.documents.map((doc) => ({
          ...doc,
          documentUrl: doc.documentUrl,
        })),
      };
    }
    return card;
  });
}

const POLLING_INTERVAL = 5000;

const DealStages = (): JSX.Element => {
  const { dealId, isDocumentSelectedForView } = useDealRoomContext();
  const [newData, setNewData] = useState<GetTimeline | null>(null);
  const [previousCards, setPreviousCards] = useState<GetTimeline_deal_cards[]>([]);

  const { loading, data, error, startPolling, stopPolling } = useQueryWithPollingWhileVisible<
    GetTimeline,
    GetTimelineVariables
  >(GET_DEAL_TIMELINE, {
    variables: { id: dealId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!newData && data) {
      setNewData(data);
    }
  }, [data, newData]);

  useEffect(() => {
    if (data && data.deal && newData && newData.deal) {
      const cleanedNewData = { ...newData, deal: { ...newData.deal, cards: cleanCards(newData.deal.cards) } };
      const cleanedData = { ...data, deal: { ...data.deal, cards: cleanCards(data.deal.cards) } };

      const differences = diff(cleanedNewData, cleanedData);

      if (differences) {
        setNewData(data);
      }
    }
  }, [data, newData]);

  useEffect(() => {
    if (isDocumentSelectedForView) {
      stopPolling(); // Stop polling when a document IN DOCUMENTS PREVIW  is selected
    } else {
      startPolling(POLLING_INTERVAL); // Start polling if no document IN DOCUMENTS PREVIW is selected
    }

    if (DealStatus.COMPLETE === newData?.deal?.state.status) {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [newData, startPolling, stopPolling, isDocumentSelectedForView]);

  const cards = useMemo(() => {
    return newData?.deal?.cards ? cleanCards(newData.deal.cards) : [];
  }, [newData?.deal?.cards]);

  useEffect(() => {
    const differences = diff(previousCards, cards);
    if (differences) {
      setPreviousCards(cards);
    }
  }, [cards, previousCards]);

  return (
    <>
      {error && <div>{error.message}</div>}
      <Spinner loading={loading} />
      {cards.length > 0 && (
        <Layout>
          <EventActionCards dealId={dealId} cards={previousCards} />
        </Layout>
      )}
    </>
  );
};

export default DealStages;
export { getCardId };
