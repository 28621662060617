import { FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from 'style/ORSNNTheme';

const StyledOverlay = styled(FloatingOverlay)`
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
`;

export function ModalNotStyled(props: {
  trigger?: React.ReactElement;
  handleClose?: () => void;
  initialOpen?: boolean;
  children: (args: { closeModal: () => void }) => React.ReactElement;
}) {
  const [open, setOpen] = useState(props.initialOpen || false);
  const { context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const { handleClose } = props;

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'dialog' });

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  const Trigger = props?.trigger && React.cloneElement(props.trigger, getReferenceProps({
    ref: refs.setReference,
  }));

  useEffect(() => {
    // Trigger the event when modal closes (open changes to false)
    if (!open && handleClose) {
      handleClose();
    }
  }, [open, handleClose]);

  return (
    <>
      {Trigger}
      {open ? (
        <FloatingPortal>
          <StyledOverlay lockScroll={true}>
            <div ref={refs.setFloating} {...getFloatingProps()}>
              {props.children({ closeModal: () => setOpen(false) })}
            </div>
          </StyledOverlay>
        </FloatingPortal>
      ) : null}
    </>
  );
}