import React from 'react';

interface FileUploadProgressBarProps {
  fileName: string;
  progress: number; // progress should be a number between 0 and 100
}

const FileUploadProgressBar: React.FC<FileUploadProgressBarProps> = ({ fileName, progress }) => {
  return (
    <div className="flex items-center justify-between w-full text-slate-200">
      <div className="text-slate-400">{fileName}</div>
      <div className="w-[158px] ml-4">
        <div className="h-2 w-full bg-slate-800 rounded-full">
          <div
            className="h-full bg-slate-400 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <div className="ml-4 text-slate-400">{progress}%</div>
    </div>
  );
};

export default FileUploadProgressBar;
