import { FC, useState } from 'react';
import { FileType } from '__generated__/globalTypes';
import FileUpload from 'features/pages/portfolio/DocumentLibrary/FileUpload/FileUpload';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import toast from 'react-hot-toast';
import { useQuery } from '@apollo/client';
import { FILE_TYPES } from 'features/pages/portfolio/DocumentLibrary/DocumentLibrary';
import { GetDocumentLibrary } from 'query/__generated__/GetDocumentLibrary';
import { GET_DOCUMENT_LIBRARY } from 'query/documentLibrary';
import DocumentTemplates from 'features/pages/portfolio/DocumentLibrary/DocumentTemplates';
import { TemplateDocument } from 'features/pages/portfolio/DocumentLibrary/__generated__/TemplateDocument';
import { BaseButton } from 'common-ui/Buttons/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import _ from 'lodash';

type Option = {
  value: FileType;
  label: string;
};

const isMultiValue = (value: SingleValue<Option> | MultiValue<Option>): value is MultiValue<Option> => {
  return Array.isArray(value);
}

export const DocumentLibraryUpload: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<FileType | null>(null);
  const navigate = useNavigate();

  const { data, refetch } = useQuery<GetDocumentLibrary>(
    GET_DOCUMENT_LIBRARY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const handleCategoryChange = (selectedOption: SingleValue<Option> | MultiValue<Option> | null) => {
    if (!selectedOption || isMultiValue(selectedOption)) {
      setSelectedCategory(null);
      return;
    }
    setSelectedCategory(selectedOption?.value || null);
  };

  const categoryOptions = FILE_TYPES.map((fileType) => ({
    value: fileType.type,
    label: fileType.name,
  }));

  const handleFileSuccess = (file?: File) => {
    toast.success(
      <div className='flex flex-col'>
        <p>Upload was successful</p>
        <p>Your document '{file?.name}' was successfully uploaded under <b>{categoryOptions.find(opt => opt.value === selectedCategory)?.label}</b>.</p>
      </div>,
      {
        duration: 4000,
        position: 'bottom-right',
      }
    );
    refetch();
  };

  const companyId = data?.user?.company?.id || '';
  const documents = _.uniqBy([
    ...data?.user?.company?.transactionDocs || [],
    ...data?.user?.company?.lendingDocs || [],
    ...data?.user?.company?.dqDocs || [],
    ...data?.user?.company?.sampleCollateralDocs || [],
    ...data?.user?.company?.otherDocs || [],
    ...data?.user?.company?.templates || [],
  ], 'id');

  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-4'>
        <h2 className='font-heebo font-medium text-lg text-white'>Document Library Upload</h2>
        <BaseButton label='Tape upload' size='small' type="secondary" className='w-fit h-fit' onClick={() => {
          navigate('/portfolio/document-library');
        }}>View Document Library</BaseButton>
      </div>

      <div className='mb-4'>
        <Dropdown
          options={categoryOptions}
          onChange={handleCategoryChange}
          placeholder='Select Category'
        />
      </div>
      <FileUpload
        parentId={companyId}
        fileType={selectedCategory as FileType}
        allowedFileTypes={[]}
        disabled={!selectedCategory}
        onFileSuccess={handleFileSuccess}
        width='auto'
        showButtons={false}
        timeToHideProgress={2}
      />
      <DocumentTemplates
        docs={documents as TemplateDocument[] || []}
        label='Recent Documents'
        showDelete={true}
        dealId={companyId}
        refetchQueries={[{ query: GET_DOCUMENT_LIBRARY }]}
        onHomePage={true}
      />
    </div>
  );
};

export default DocumentLibraryUpload;
