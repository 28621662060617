import React, { useEffect } from 'react';
interface PassedProps {
  setIsOpen: (isOpen: boolean) => void;
  headerText: string;
  subText: string;
  caption: string;
  children: React.ReactNode;
  type?: string;
  className?: string;
  width?: string;
}

export const Modal = ({
  headerText,
  subText,
  caption,
  children,
  setIsOpen,
  type,
  className,
  width,
}: PassedProps) => {

  useEffect(() => {
    const closeModal = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal);
  }, [setIsOpen]);

  return (
    <>
      <div className='fixed inset-0 bg-black bg-opacity-50 z-[1]' onClick={() => setIsOpen(false)} />
      <div
        className={`
          z-[1] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
        text-white p-6 rounded-lg shadow-lg flex
          ${type === 'document-library' ? 'w-[700px]' : 'min-w-[380px]'}
          ${className}
        `}
        style={{ width: width || 'auto' }}
      >
        <div className="text-left">
          <div className="text-xl font-semibold mb-2">{headerText}</div>
          <div className="text-sm text-gray-400 mb-1 uppercase">{subText}</div>
          <div className="text-xs text-gray-500 mb-4">{caption}</div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};
